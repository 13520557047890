import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import DromoUploader from "../App";
import ErrorBoundary from "./ErrorBoundary";
import {
  IUploaderInternal,
  MockParentConnectionProvider,
} from "../helpers/MockParentConnection";
import {
  IDeveloperField,
  IDeveloperSettings,
  IPositionSpec,
  IRowHook,
  IUser,
} from "../interfaces";
import connectionMethods from "../thunks/connection_methods";
import {
  dumpStateForRehydration,
  IRehydrateState,
} from "../thunks/rehydration";
import { DataContext } from "./DataContext";
import { wrapAsync } from "../util/wrapAsync";

type IUploaderConfig = {
  licenseKey: string;
  fields: IDeveloperField[];
  settings: IDeveloperSettings;
  user: IUser;
};

type ISavedSchemaConfig = {
  licenseKey: string;
  schemaName: string;
};

type IWrapperInternalState = {
  rehydrateState: IRehydrateState | null;
};

type IConfigInternal = Pick<
  IUploaderInternal,
  | "columnHooks"
  | "rowHooks"
  | "bulkRowHooks"
  | "stepHooks"
  | "rowDeleteHooks"
  | "beforeFinishCallback"
  | "resultsCallback"
  | "cancelCallback"
  | "fileParsers"
>;

// this is the type that is exposed to use as config.
// you either need to provide standard config (key, feilds, settings, user)
// or a saved schema config (key, schemaName). Additionally, you can optionally
// provide hooks and callbacks. The rowHooks config is simplified to only require
// an array of hook functions (not necessary to wrap them in objects.)
export type IWrapperConfig = (IUploaderConfig | ISavedSchemaConfig) &
  Partial<Omit<IConfigInternal, "rowHooks">> & { rowHooks?: IRowHook[] };

type IPartialConfig = { isOpen: false } & (
  | Partial<IUploaderConfig>
  | Partial<ISavedSchemaConfig>
);
type IFullUploaderConfig = { isOpen: true } & IUploaderConfig;
type IFullSavedSchemaConfig = { isOpen: true } & ISavedSchemaConfig;

type IWrapperState = IUploaderInternal &
  IWrapperInternalState &
  (IPartialConfig | IFullUploaderConfig | IFullSavedSchemaConfig);
type IWrapperProps = Partial<IWrapperConfig>;

const INITIAL_PROGRESS_TRACKER = {
  message: "",
  count: 0,
  total: 0,
};

const initInternal = {
  columnHooks: [],
  rowHooks: [],
  bulkRowHooks: [],
  stepHooks: [],
  rowDeleteHooks: [],
  progressTracker: INITIAL_PROGRESS_TRACKER,
  fileParsers: [],
};

const mapDispatchToProps = {
  ...connectionMethods,
  dumpStateForRehydration,
};

const connector = connect(null, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
type IWrapperFullProps = PropsFromRedux & IWrapperProps;

// We allow a RegExp object, which is not serializable by default, so we manually convert here
const makeFieldSerializable = (field: IDeveloperField) => {
  const newField = { ...field };
  if (newField.validators) {
    newField.validators = newField.validators.map((validator) => {
      if (
        (validator.validate === "regex_match" ||
          validator.validate === "regex_exclude") &&
        validator.regex instanceof RegExp
      ) {
        const regex = validator.regex as unknown as RegExp;
        return {
          ...validator,
          regex: regex.source,
          regexOptions: {
            ignoreCase: regex.flags.includes("i"),
            dotAll: regex.flags.includes("s"),
            multiline: regex.flags.includes("m"),
            unicode: regex.flags.includes("u"),
          },
        };
      }
      return validator;
    });
  }
  return newField;
};

class TestWrapper extends React.Component<IWrapperFullProps, IWrapperState> {
  static contextType = DataContext;

  constructor(props: IWrapperFullProps) {
    super(props);
    this.state = {
      ...initInternal,
      isOpen: false,
      close: this.close,
      rehydrateState: null,
      addField: this.addField,
      updateInfoMessages: this.updateInfoMessages,
      setUser: this.setUser,
      setHeaderRowOverride: this.setHeaderRowOverride,
      removeField: this.removeField,
      setDevelopmentMode: this.setDevelopmentMode,
      addRows: this.addRows,
      removeRows: this.removeRows,
      setConfirmationMessage: this.setConfirmationMessage,
    };
  }

  configure(config: Partial<IWrapperConfig>): void {
    const { rowHooks, ...rest } = config;
    const newConfig = rest as Partial<IWrapperState>;

    if (rowHooks) {
      newConfig.rowHooks = rowHooks.map((hook) => {
        return { callback: hook };
      });
    }

    this.setState(newConfig as IWrapperState);
  }

  open = (): void => {
    this.setState({ isOpen: true }, this.initUploader);
  };

  close = (): void => {
    this.setState({ isOpen: false });
  };

  initUploader = (): void => {
    if ("licenseKey" in this.state && "schemaName" in this.state) {
      this.props.initFromSavedSchema(
        this.state.licenseKey!,
        this.state.schemaName!,
        ""
      );
    } else if (
      "fields" in this.state &&
      "settings" in this.state &&
      "user" in this.state
    ) {
      this.props.init(
        this.state.licenseKey!,
        this.state.fields!.map(makeFieldSerializable),
        this.state.settings!,
        this.state.user!
      );
    }

    if (this.state.rehydrateState) {
      this.props.rehydrate(this.state.rehydrateState);
    }

    this.props.setNumRegisteredColHooks(this.state.columnHooks.length);
    this.props.setNumRegisteredRowHooks(
      this.state.rowHooks.length + this.state.bulkRowHooks.length
    );
    this.props.setNumRegisteredRowDeleteHooks(this.state.rowDeleteHooks.length);
    this.props.setCustomParserFileExtensions(
      this.state.fileParsers.map((fp) => fp.extensions).flat()
    );
  };

  addField = async (
    field: IDeveloperField,
    position?: IPositionSpec
  ): Promise<void> => {
    this.props.addField(makeFieldSerializable(field), position);
  };

  rehydrate = async (rehydrateState: IRehydrateState): Promise<void> => {
    this.setState({ rehydrateState });
  };

  setUser = async (user: IUser) => {
    this.props.setUser(user);
  };

  setHeaderRowOverride = async (headerRow: number | null) => {
    this.props.setHeaderRowOverride(headerRow);
  };

  removeField = async (field: string) => {
    this.props.removeField(field);
  };

  addRows = wrapAsync(this.props.addRows);
  removeRows = wrapAsync(this.props.removeRows);
  updateInfoMessages = wrapAsync(this.props.updateInfoMessages);
  setConfirmationMessage = wrapAsync(this.props.setConfirmationMessage);

  dumpStateForRehydration = wrapAsync(this.props.dumpStateForRehydration);
  setDevelopmentMode = wrapAsync(this.props.setDevelopmentMode);

  render(): JSX.Element {
    return (
      <>
        {this.state.isOpen && (
          <MockParentConnectionProvider parent={this.state}>
            <ErrorBoundary>
              <DromoUploader />
            </ErrorBoundary>
          </MockParentConnectionProvider>
        )}
      </>
    );
  }
}

export default connector(TestWrapper);

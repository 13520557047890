import { CheckboxField } from "./checkbox";
import { EmailField } from "./email";
import { NumberField } from "./number";
import { SelectField, SelectOpts } from "./select";
import { StringField } from "./string";
import { DomainField } from "./domain";
import { AbstractField, BaseTypeOpts } from "./abstract";
import { DateField, DateTimeField, TimeField } from "./datetime";
import { USZipCodeField } from "./us_zip_code";
import { CountryField } from "./country";
import { UUIDField } from "./uuid";
import { SSNField } from "./ssn";
import { PhoneNumberField } from "./phone_number";
import { UrlField } from "./url";
import { USStateTerritoryField } from "./us_state_territory";

import {
  IDeveloperField,
  IDeveloperFieldType,
  ISelectField,
} from "../interfaces";

import { isArray } from "lodash";

export type IField =
  | CheckboxField
  | DateField
  | DateTimeField
  | EmailField
  | NumberField
  | SelectField
  | PhoneNumberField
  | SSNField
  | DomainField
  | StringField
  | UrlField
  | USZipCodeField
  | CountryField
  | UUIDField
  | USStateTerritoryField
  | TimeField;

export type IAbstractField = AbstractField<BaseTypeOpts, unknown>;

const typeToConstructor = {
  checkbox: CheckboxField,
  date: DateField,
  datetime: DateTimeField,
  email: EmailField,
  number: NumberField,
  select: SelectField,
  string: StringField,
  "phone-number": PhoneNumberField,
  ssn: SSNField,
  domain: DomainField,
  uuid: UUIDField,
  time: TimeField,
  url: UrlField,
  "us-zip-code": USZipCodeField,
  "us-state-territory": USStateTerritoryField,
  country: CountryField,
};

export function fieldFromDeveloperField(
  developerField: IDeveloperField,
  selectOptionOverrides: Map<number, Map<string, string>> | undefined
): IField {
  let fieldType: IDeveloperFieldType;
  let typeOpts: string | Record<string, any>;

  if (isArray(developerField.type)) {
    fieldType = developerField.type[0];
    const opts = developerField.type[1];

    switch (typeof opts) {
      case "undefined":
        typeOpts = {};
        break;
      case "object":
        // copy so it's not read-only
        typeOpts = { ...opts };
        break;
      case "string":
        // some types can take a simple preset string - convert to proper options
        typeOpts = { preset: opts };
        break;
    }
  } else {
    fieldType = (developerField.type as IDeveloperFieldType) || "string";
    typeOpts = {};
  }

  if (developerField.invalidValueMessage !== undefined) {
    typeOpts.invalidValueMessage = developerField.invalidValueMessage;
  }

  if (fieldType === "select") {
    // select fields are special
    typeOpts.selectOptions = (developerField as ISelectField).selectOptions;
    typeOpts.selectOptionOverrideMap = selectOptionOverrides ?? new Map();
    return new SelectField(typeOpts as SelectOpts);
  }

  const constructor = typeToConstructor[fieldType];
  return new constructor(typeOpts);
}

export function checkRequireMapping(developerField: IDeveloperField): boolean {
  if (developerField.requireMapping !== undefined) {
    return developerField.requireMapping;
  } else {
    return developerField.validators
      ? developerField.validators.some(
          (validator) => validator.validate === "required"
        )
      : false;
  }
}

export function getFieldType(field: IDeveloperField): IDeveloperFieldType {
  if (field.type === undefined) {
    return "string";
  } else if (Array.isArray(field.type)) {
    return field.type[0];
  } else {
    return field.type;
  }
}

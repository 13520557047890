import "handsontable/dist/handsontable.full.css";
import Papa from "papaparse";
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

import { EStepHook } from "../../interfaces";
import { useAppSelector, useSettings, useAppDispatch } from "../../store/hooks";
import {
  clearSelectedHandsonCell,
  hideProcessingModal,
  showProcessingModal,
} from "../../store/reducers/commonComponents";
import { setData } from "../../store/reducers/coredata";
import { startFromStep } from "../../store/reducers/steps";
import { setRehydrationComplete } from "../../store/reducers/modals";
import { selectMatchableFieldSpecs } from "../../store/reducers/fields";
import {
  parseSelectedSheet,
  processSelectedFile,
} from "../../thunks/file_processing";

import { handleStepHook } from "../../thunks/parent_connection_handlers";
import { initColumnMappings } from "../../thunks/initial_data";
import { useStepNavigation } from "../../thunks/step_navigation";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { useDataContext } from "../DataContext";
import { useTranslation } from "react-i18next";

import { ReactComponent as ManualInputIcon } from "../../assets/manual-input.svg";
import { ReactComponent as TemplateDownloadIcon } from "../../assets/template-download.svg";
import { AlertModal, ErrorModal } from "../AlertModal";
import { SelectSheetModal } from "./SelectSheetModal";
import HelpText from "../HelpText";
import FileSelect from "./FileSelect";

import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import StepContainer from "../commonComponents/StepContainer";
import { runSavedSchemaStepHooks } from "../../thunks/hooks";

const DataUploadModal = () => {
  const { t } = useTranslation();
  const connection = useParentConnectionContext();
  const { setFullDataAddMeta } = useDataContext();
  const { goToNextStep } = useStepNavigation();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSelectSheetModal, setShowSelectSheetModal] = useState(false);
  const [showMaxRecordsAlert, setShowMaxRecordsAlert] = useState(false);
  const dispatch = useAppDispatch();
  const { uploadType, rehydrate, matchableFields, initialized } =
    useAppSelector((state) => ({
      uploadType: state.coredata.data.uploadType,
      rehydrate:
        state.modals.rehydrateStage === "SHEET_SELECTION" &&
        !state.modals.rehydrationComplete,
      matchableFields: selectMatchableFieldSpecs(state.fields),
      initialized: state.coredata.initialized,
      stepHooks: state.settings.savedSchemaHooks.stepHooks,
    }));

  const {
    title,
    maxRecords,
    importIdentifier,
    manualInputDisabled,
    manualInputOnly,
    templateDownloadFilename,
    uploadStep: stepSettings,
  } = useSettings();

  const handleManualInput = useCallback(() => {
    dispatch(
      setData({
        previewData: [],
        rawPreviewData: [],
        uploadType: "MANUAL_INPUT",
      })
    );

    dispatch(initColumnMappings());
    dispatch(startFromStep("REVIEW"));
  }, [dispatch]);

  useEffect(() => {
    // Wait to advance to review until we've finished initializing settings/data
    if (manualInputOnly && initialized) {
      handleManualInput();
    }
  }, [manualInputOnly, handleManualInput, initialized]);

  useEffect(() => {
    dispatch(clearSelectedHandsonCell());

    if (rehydrate) {
      setShowSelectSheetModal(true);
      dispatch(setRehydrationComplete());
    }
  }, [dispatch, rehydrate]);

  const onTemplateDownloadClick = useCallback(() => {
    if (templateDownloadFilename === null) return;
    const fields = matchableFields.map((field) => field.label);

    const csv = Papa.unparse({
      fields,
      data: [],
    });

    const elm = document.createElement("a");
    elm.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    let filename = templateDownloadFilename;
    if (filename.split(".").pop() !== "csv") {
      filename += ".csv";
    }
    elm.setAttribute("download", filename);
    elm.click();
  }, [matchableFields, templateDownloadFilename]);

  const proceedToNextStep = useCallback(async () => {
    await dispatch(runSavedSchemaStepHooks(EStepHook.UPLOAD_STEP));
    await dispatch(handleStepHook(connection, EStepHook.UPLOAD_STEP));
    goToNextStep();
  }, [connection, dispatch, goToNextStep]);

  const onSelectSheet = useCallback(async () => {
    dispatch(showProcessingModal());
    setShowSelectSheetModal(false);

    const { success, data, requiresMaxRecordsDisclaimer } = await dispatch(
      parseSelectedSheet()
    );

    if (success) setFullDataAddMeta(data);

    if (!success) {
      setShowErrorModal(true);
    } else if (requiresMaxRecordsDisclaimer) {
      setShowMaxRecordsAlert(true);
    } else {
      await proceedToNextStep();
    }

    dispatch(hideProcessingModal());
  }, [dispatch, proceedToNextStep, setFullDataAddMeta]);

  const processSelectedLocalFile = useCallback(async () => {
    dispatch(showProcessingModal());

    const { success, requiresSheetSelection } = await dispatch(
      processSelectedFile()
    );

    if (!success) {
      setShowErrorModal(true);
    } else if (requiresSheetSelection) {
      setShowSelectSheetModal(true);
    } else {
      await onSelectSheet();
    }

    dispatch(hideProcessingModal());
  }, [dispatch, onSelectSheet]);

  // handle initialFile
  useEffect(() => {
    if (initialized && uploadType === "INITIAL_FILE") {
      processSelectedLocalFile();
    }
  }, [initialized, uploadType, processSelectedLocalFile]);

  const onCloseMaxRecordsAlert = useCallback(async () => {
    setShowMaxRecordsAlert(false);
    proceedToNextStep();
  }, [proceedToNextStep]);

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />

      <SelectSheetModal show={showSelectSheetModal} onHide={onSelectSheet} />

      <AlertModal
        show={showMaxRecordsAlert}
        setShow={onCloseMaxRecordsAlert}
        onPrimaryButtonClick={onCloseMaxRecordsAlert}
        primaryButtonText="Ok"
        message={t("validations.maxRecordsExceeded", { maxRecords })}
        showSecondaryButton={false}
        data-cy="max-records-disclaimer"
        secondaryButtonText=""
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
      />

      <StepContainer data-cy="DataUploadModal" step="upload">
        <header className="mb-4">
          <Text type="h1" className="mb-2" data-cy="data-upload-title">
            {title || `${t("modalHeader.titleStarter")} ${importIdentifier}`}
          </Text>
          {stepSettings.helpText && (
            <HelpText content={stepSettings.helpText} />
          )}
        </header>

        <div>
          {!manualInputOnly && (
            <FileSelect
              onFileSelected={debounce((file) => {
                dispatch(setData({ file: file as File, uploadType: "FILE" }));
                processSelectedLocalFile();
              })}
              shouldShowTemplateDownloadLink={templateDownloadFilename !== null}
              onTemplateDownloadClick={onTemplateDownloadClick}
            />
          )}

          {(!manualInputDisabled || templateDownloadFilename) &&
            !manualInputOnly && (
              <div className="flex items-center justify-center my-8 mx-auto max-w-sm">
                <div className="flex-1 h-px bg-ice-300" />
                <Text type="body" className="px-4 text-ice-500">
                  {t("dataUploadModal.separator")}
                </Text>
                <div className="flex-1 h-px bg-ice-300" />
              </div>
            )}
        </div>

        <div className="flex flex-row justify-center items-center gap-4 !mx-8 !mb-2 sm:!mx-16 sm:!mb-5 md:!mx-32 md:!mb-10">
          {!manualInputDisabled && (
            <Button
              onClick={handleManualInput}
              theme="secondary"
              className="gap-4 bg-gray-50"
              data-cy="manual-entry-button"
            >
              <ManualInputIcon className="h-6 w-6" />
              <span>{t("dataUploadModal.manualEntryButton.enterData")}</span>
            </Button>
          )}
          {templateDownloadFilename && (
            <Button
              onClick={onTemplateDownloadClick}
              theme="secondary"
              className="gap-4 bg-gray-50"
            >
              <TemplateDownloadIcon className="h-6 w-6" />
              <span>{t("dataUploadModal.downloadTemplateButton")}</span>
            </Button>
          )}
        </div>
      </StepContainer>
    </>
  );
};

export default DataUploadModal;

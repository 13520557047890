import { Search, X } from "react-bootstrap-icons";
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import { Button } from "../commonComponents/Button";
import { getInputClasses } from "../commonComponents/Input";
import Text from "../commonComponents/Text";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: (query: string) => void;
  onClose: () => void;
  rowsMatched?: number;
  cellsMatched?: number;
}

const SearchInput = ({
  onSubmit,
  onClose,
  rowsMatched,
  cellsMatched,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      onSubmit(value);
    }, 500),
    [onSubmit]
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setQuery(event.target.value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "13") {
      event.stopPropagation();
    }
  };

  const handleClearInput = () => {
    onSubmit("");
    setQuery("");
    onClose();
  };

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  const getResultsText = () => {
    if (cellsMatched === undefined || rowsMatched === undefined) return "";

    if (cellsMatched === 0 && query.length > 0) {
      return t("dataReviewModal.noCellsFound");
    } else if (cellsMatched === 1) {
      return t("dataReviewModal.oneCellFoundOneRow");
    } else if (cellsMatched > 1 && rowsMatched === 1) {
      return t("dataReviewModal.manyCellsFoundOneRow", {
        num_cells: cellsMatched,
      });
    } else if (cellsMatched > 1 && rowsMatched > 1) {
      return t("dataReviewModal.manyCellsFoundManyRows", {
        num_cells: cellsMatched,
        num_rows: rowsMatched,
      });
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex gap-2">
      <div className="relative">
        <Search className="absolute top-2.5 left-2 w-5 h-5 text-ice-500 z-10" />
        <input
          ref={inputRef}
          name="search"
          id="search"
          className={getInputClasses(
            "border rounded-md max-w-[240px] !pl-8 pr-8 h-[36px]"
          )}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          autoFocus
        />
        <Text className="absolute top-[36px] right-0 text-sm z-10">
          {getResultsText()}
        </Text>
        {query.length > 0 && (
          <Button
            type="button"
            theme="ghost"
            onClick={handleClearInput}
            className="absolute right-1 top-2 p-0 h-6 w-6"
          >
            <X size={24} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchInput;

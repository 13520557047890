import React, { useEffect, useState, useCallback } from "react";

import "./App.css";

import { MIN_WINDOW_WIDTH_PIXELS } from "./constants/constants";

import DataUploadModal from "./components/DataUpload";
import DataMetadataModal from "./components/DataMetadata";
import ColumnMatchModal from "./components/ColumnMatch";
import DataReviewModal from "./components/DataReview";
import SchemaFirstMatch from "./components/SchemaFirstMatch";
import { EnhanceData } from "./components/EnhanceData";
import { FatalErrorModal, TranslatedAlertModal } from "./components/AlertModal";
import ProcessingModal from "./components/commonComponents/ProcessingModal";

import { useParentConnectionContext } from "./components/ParentConnectionContext";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import { selectFatalError } from "./store/reducers/errors";
import { handleCancelModal } from "./thunks/parent_connection_handlers";

const DromoUploader: React.FC = () => {
  const connection = useParentConnectionContext();
  const dispatch = useAppDispatch();

  const isInitialized = useAppSelector((state) => state.coredata.initialized);
  const [windowSizeError, setWindowSizeError] = useState(false);

  const fontOverrides = useAppSelector((state) => ({
    customFontURL: state.settings.styleOverrides.global.customFontURL,
    customFontFamily: state.settings.styleOverrides.global.customFontFamily,
  }));

  const fatalError = useAppSelector((state) => selectFatalError(state.errors));

  const currentStep = useAppSelector((state) => state.steps.currentStep);

  const matchToSchema = useAppSelector(
    (state) => state.settings.matchingStep.matchToSchema
  );

  useEffect(() => {
    if (isInitialized) {
      if (
        fontOverrides.customFontURL !== null &&
        fontOverrides.customFontFamily !== null
      ) {
        const fontElement = document.getElementById("custom-font");
        if (!fontElement) {
          const font = document.createElement("link");
          font.setAttribute("href", fontOverrides.customFontURL);
          font.setAttribute("rel", "stylesheet");
          font.id = "custom-font";
          document.head.appendChild(font);
          document.body.style.fontFamily = fontOverrides.customFontFamily;
        }
      }
    }
  }, [isInitialized, fontOverrides]);

  const onResize = useCallback(() => {
    setWindowSizeError(window.innerWidth < MIN_WINDOW_WIDTH_PIXELS);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  if (windowSizeError) {
    return (
      <TranslatedAlertModal
        show
        i18nMessage="alert.deviceChange"
        i18nPrimaryButtonText="common.close"
        i18nSecondaryButtonText=""
        showSecondaryButton={false}
        errorModal={true}
        onPrimaryButtonClick={() => dispatch(handleCancelModal(connection))}
        data-cy="window-size-modal"
      />
    );
  }

  if (fatalError) {
    return <FatalErrorModal eventId={fatalError.extra?.sentryEventId} />;
  }

  let modal: React.ReactNode;
  switch (currentStep) {
    case "UPLOAD":
      modal = <DataUploadModal />;
      break;
    case "HEADER_SELECT":
      modal = <DataMetadataModal />;
      break;
    case "COLUMN_MATCH":
      if (matchToSchema) {
        modal = <SchemaFirstMatch />;
        break;
      }
      modal = <ColumnMatchModal />;
      break;
    case "SELECT_MATCH":
      modal = <EnhanceData />;
      break;
    case "REVIEW": {
      modal = <DataReviewModal />;
      break;
    }
  }

  return (
    <>
      <ProcessingModal />
      {modal}
    </>
  );
};

export default DromoUploader;

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export class StringField extends AbstractField<BaseTypeOpts, string> {
  type = "string" as const;

  transform(value: string): ITransformResult<string> {
    return this.transformSuccess(value);
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}

import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";

import { TranslatedAlertModal } from "../AlertModal";
import ModalHeader, { IModalHeaderProps } from "../ModalHeader";
import {
  useStyles,
  useAppDispatch,
  useAppSelector,
  useSettings,
} from "../../store/hooks";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { handleCancelModal } from "../../thunks/parent_connection_handlers";
import { setExitRequested } from "../../store/reducers/modals";
import DromoLogo from "../DromoLogo";
import Modal from "./Modal";

interface StepContainerProps {
  "data-cy"?: string;
  children: React.ReactNode;
  onBack?: IModalHeaderProps["onBack"];
  step: IModalHeaderProps["step"];
  contentClassName?: string;
}

function Container({
  children,
  ...props
}: {
  children: React.ReactNode;
  "data-cy"?: string;
}) {
  const { embedInline } = useSettings();

  const overrideStyles = useStyles((styles) => ({
    backgroundColor: styles.global.backgroundColor,
    borderStyle: styles.global.borderStyle,
    borderWidth: styles.global.borderWidth,
    borderColor: styles.global.borderColor,
    ...(!embedInline ? { borderRadius: styles.global.borderRadius } : {}),
  }));

  if (embedInline) {
    return (
      <div
        className="w-full min-h-screen h-auto bg-ice-50 overflow-hidden"
        data-cy={props["data-cy"]}
        style={overrideStyles}
      >
        {children}
      </div>
    );
  }

  return (
    <Modal
      show
      data-cy={props["data-cy"]}
      style={overrideStyles}
      className="max-w-[1440px] w-[90%] bg-ice-50"
      containerClassName="z-0"
    >
      {children}
    </Modal>
  );
}

function StepContainer({
  step,
  onBack,
  "data-cy": dataCy,
  children,
  contentClassName,
}: StepContainerProps) {
  const dispatch = useAppDispatch();
  const backendCapabilities = useAppSelector(
    (state) => state.settings.backendCapabilities
  );
  const connection = useParentConnectionContext();
  const { embedInline } = useSettings();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (embedInline) return;

      if (
        e.target instanceof HTMLElement &&
        e.target.dataset?.hotInput !== undefined
      ) {
        return;
      }

      if (e.key === "Escape") {
        dispatch(setExitRequested(true));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, embedInline]);

  const showExitModal = useAppSelector((state) => state.modals.exitRequested);

  return (
    <div>
      <TranslatedAlertModal
        show={showExitModal}
        setShow={(show: boolean) => dispatch(setExitRequested(show))}
        i18nMessage="common.exitAlert"
        i18nPrimaryButtonText="common.yes"
        i18nSecondaryButtonText="common.no"
        onPrimaryButtonClick={() => dispatch(handleCancelModal(connection))}
        onSecondaryButtonClick={() => dispatch(setExitRequested(false))}
        data-cy="exit-importer-alert"
        showSecondaryButton
      />

      <Container data-cy={dataCy}>
        <ModalHeader step={step} onBack={onBack} />
        <section
          className={twMerge(
            "!px-6 !py-3 sm:!px-12 sm:!py-6 md:!px-24 md:!py-12",
            contentClassName
          )}
        >
          {children}
        </section>

        {backendCapabilities?.has_dromo_branding === true && (
          <div className="bg-gray-50 py-6 border-t border-gray-200">
            <div className="text-center text-sm text-gray-600 font-semibold flex items-center gap-2 justify-center">
              Powered by
              <DromoLogo />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

StepContainer.Header = ModalHeader;

export default StepContainer;

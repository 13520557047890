import { IDeveloperField, IValidatorField } from "../interfaces";
import { RootState } from "../store/reducers";
import { selectUniqueValsInColumn } from "../store/reducers/coredata";
import { selectMappedSpecs } from "../store/selectors";
import { IColumnMappingCacheSerialized } from "./ColumnMappingCacheHelper";

const isRequiredByValidator = (
  validator: IValidatorField,
  mappedKeys: Set<string>
) =>
  (validator.validate === "require_with" &&
    validator.fields.some((fieldKey) => mappedKeys.has(fieldKey))) ||
  (validator.validate === "require_with_all" &&
    validator.fields.every((fieldKey) => mappedKeys.has(fieldKey)));

export function getRequiredFieldKeys(
  fields: IDeveloperField[],
  mappedKeys: Set<string>
): Set<string> {
  const requiredFieldKeys = new Set<string>();
  fields.forEach((field) => {
    if (
      field.requireMapping ||
      (field.requireMapping === undefined &&
        field.validators?.some((v) => v.validate === "required")) ||
      field.validators?.some((v) => isRequiredByValidator(v, mappedKeys))
    ) {
      requiredFieldKeys.add(field.key);
    }
  });
  return requiredFieldKeys;
}

export const selectRequiredFieldKeys = (state: RootState): Set<string> => {
  const mappedFieldKeys = [...state.fields.columnMapping.values()].map(
    ({ key }) => key
  );

  const allMappedKeys = new Set([
    ...state.fields.addedEmptyFields,
    ...mappedFieldKeys,
  ]);

  return getRequiredFieldKeys(
    [...state.fields.fieldSpecs.values()],
    allMappedKeys
  );
};

/**
 * Returns an array of field keys that are required, but have not been mapped
 * or added as en empty column
 */
export const selectMissingRequiredFieldKeys = (state: RootState): string[] => {
  const mappedFieldKeys = [...state.fields.columnMapping.values()].map(
    ({ key }) => key
  );

  const allMappedKeys = new Set([
    ...state.fields.addedEmptyFields,
    ...mappedFieldKeys,
  ]);

  return [
    ...getRequiredFieldKeys(
      [...state.fields.fieldSpecs.values()],
      allMappedKeys
    ),
  ].filter((key) => !allMappedKeys.has(key));
};

/**
 * Returns a string[] of headers that have duplicate field mappings.
 *
 * @param columMapping the current columnMapping that we want to validate
 * @param headers the headers in the current CSV file
 * @returns array of the all the CSV headers that have been mapped to the same
 * field
 */
export function selectDuplicateColumnMappings(state: RootState): string[] {
  // set of field.key -> colIndex[]
  const seenFields = new Map<string, number[]>();
  const { headers } = state.coredata;
  const { fieldSpecs, columnMapping, ignoredColumns, addedEmptyFields } =
    state.fields;

  columnMapping.forEach(({ key }, colIndex: number) => {
    if (!ignoredColumns.has(colIndex) && !fieldSpecs.get(key)!.manyToOne) {
      if (seenFields.has(key)) {
        seenFields.get(key)!.push(colIndex);
      } else {
        seenFields.set(key, [colIndex]);
      }
    }
  });

  addedEmptyFields.forEach((key: string) => {
    if (seenFields.has(key)) {
      seenFields.get(key)?.push(-1);
    } else {
      seenFields.set(key, [-1]);
    }
  });

  let duplicateFields: string[] = [];
  seenFields.forEach((dupHeaders: number[]) => {
    if (dupHeaders.length > 1) {
      if (headers) {
        duplicateFields = duplicateFields.concat(
          dupHeaders.map((x) => headers[x])
        );
      } else {
        duplicateFields = duplicateFields.concat(
          dupHeaders.map((x) => `Column ${x + 1}`)
        );
      }
    }
  });

  return duplicateFields;
}

/**
 * Does the same validation checks as ColumnMatchModal
 */
export function selectHasMappingErrors(state: RootState): boolean {
  return (
    selectMissingRequiredFieldKeys(state).length > 0 ||
    selectDuplicateColumnMappings(state).length > 0
  );
}

/**
 * Compares two sets to confirm if they have the same items inside
 * @param as the first set
 * @param bs the second set
 * @returns true if they have the same items, false otherwise
 */
function _eqSet(as: Set<any>, bs: Set<any>): boolean {
  if (as.size !== bs.size) return false;
  for (const a of Array.from(as)) if (!bs.has(a)) return false;
  return true;
}

/**
 * Checks if the all the select fields in the current CSV uploads have the same
 * unique values as the cache
 */
export function hasSameSelectFieldUniqueValues(
  state: RootState,
  cachedMapping: IColumnMappingCacheSerialized
): boolean {
  const { headerToSelectFieldUniqueValues } = cachedMapping;
  if (headerToSelectFieldUniqueValues === undefined) return false;

  const { data, headers } = state.coredata;
  const fieldSpecs = selectMappedSpecs(state);
  if (!headers || !data.valCountsInColumn) return false;

  let cachedUniqueValues = new Map<string, string[]>();
  try {
    cachedUniqueValues = new Map<string, string[]>(
      Object.entries(headerToSelectFieldUniqueValues)
    );
  } catch (err) {
    console.error(err);
    return false;
  }

  let areSame = true;
  selectUniqueValsInColumn(data).forEach(
    (curUniqueValues: Set<string>, colIndex: number) => {
      const field = fieldSpecs.get(colIndex);
      if (field?.type !== "select") return;

      const curCachedUniqueValues = cachedUniqueValues.get(headers[colIndex]);
      if (curCachedUniqueValues === undefined) {
        areSame = false;
      } else {
        if (!_eqSet(curUniqueValues, new Set(curCachedUniqueValues))) {
          areSame = false;
        }
      }
    }
  );
  return areSame;
}

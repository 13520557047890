/* eslint-disable @typescript-eslint/ban-types */

import { getFieldType } from "../fields";
import { IDeveloperSelectOption } from "../interfaces";
import { RootState } from "../store/reducers";
import { selectUniqueValsInColumn } from "../store/reducers/coredata";
import { selectFieldSpecAtColIndex } from "../store/reducers/fields";

export interface IHeaderToColumnMappingData {
  isIgnored: boolean;
  key: string | null;
  // this was added later, so may not be present on cached mappings
  // returned from the backend
  isCustom?: boolean;
}

// DANGER: updating these primary keys will change the hash values
// on the backend
export interface IColumnMappingCache {
  headers: string[];
  headerToColumnMapping: Map<string, IHeaderToColumnMappingData>;
  headerToSelectFieldMapping: Map<string, Map<string, string>>;
  headerToSelectFieldUniqueValues: Map<string, Set<string>>;
}

export interface IColumnMappingCacheSerialized {
  headers: string[];
  headerToColumnMapping: Record<string, IHeaderToColumnMappingData>;
  headerToSelectFieldMapping: Record<string, Record<string, string>>;
  headerToSelectFieldUniqueValues: Record<string, Array<string>>;
}

export function selectColumnMappingCache(
  state: RootState
): IColumnMappingCache | null {
  const headers = state.coredata.headers;
  if (!headers) return null;

  // we want the state from before review so we don't include virtual fields
  // and other changes introduced by hooks
  const fieldsState = state.fields.snapshots.get("PREREVIEW");
  if (!fieldsState) return null;

  const headerToColumnMapping = new Map<string, IHeaderToColumnMappingData>();
  const keyToHeader = new Map<string, string>();
  headers.forEach((header, colIndex) => {
    const isIgnored = fieldsState.ignoredColumns.has(colIndex);
    const mapping = fieldsState.columnMapping.get(colIndex);

    if (!(mapping || isIgnored)) return;

    headerToColumnMapping.set(header, {
      key: mapping?.key || null,
      isIgnored,
      isCustom: mapping?.matchType === "CUSTOM",
    });

    if (mapping) {
      keyToHeader.set(mapping.key, header);
    }
  });

  // Goes through the selectFieldMapping in the coreData redux state and
  // creates a map of the following:
  //    header -> ( (userValue -> developerKey), (userValue -> developerKey), ...),
  //    header -> ( (userValue -> developerKey), (userValue -> developerKey), ...),
  const headerToSelectFieldMapping = new Map<string, Map<string, string>>();
  fieldsState.selectFieldMapping.forEach(
    (
      selectFieldMapping: Map<string, IDeveloperSelectOption>,
      colIndex: number
    ) => {
      const selectFieldMappingKeyOnly = new Map<string, string>();
      selectFieldMapping.forEach(
        (selectOption: IDeveloperSelectOption, userValue: string) => {
          selectFieldMappingKeyOnly.set(userValue, selectOption.value);
        }
      );
      headerToSelectFieldMapping.set(
        headers[colIndex],
        selectFieldMappingKeyOnly
      );
    }
  );

  // Goes through the unique values per col and creates a map of the following:
  //    header -> set<unique val 1, uniqueval 2, ...>
  //    header -> set<unique val 1, uniqueval 2, ...>
  const headerToSelectFieldUniqueValues = new Map<string, Set<string>>();
  const uniqueValuesInColumn = selectUniqueValsInColumn(state.coredata.data);
  uniqueValuesInColumn.forEach((uniqueVals: Set<string>, colIndex: number) => {
    const maybeFieldSpec = selectFieldSpecAtColIndex(state.fields, colIndex);
    if (maybeFieldSpec && getFieldType(maybeFieldSpec) === "select") {
      headerToSelectFieldUniqueValues.set(
        keyToHeader.get(maybeFieldSpec.key)!,
        uniqueVals
      );
    }
  });

  return {
    headers,
    headerToColumnMapping,
    headerToSelectFieldMapping,
    headerToSelectFieldUniqueValues,
  };
}

// Helper function to deal with the fact that ES6 Maps can't be passed as HTTP
// objects
export function convertColumnMappingDataToAxiosFriendly(
  data: IColumnMappingCache
): IColumnMappingCacheSerialized {
  return JSON.parse(
    JSON.stringify(data, (_key, value) => {
      if (value instanceof Map) {
        return Object.fromEntries(value);
      } else if (value instanceof Set) {
        return Array.from(value);
      } else {
        return value;
      }
    })
  );
}

export function mapToEntriesArray<K extends number | string, V>(
  map: Map<K, V>
): Array<[K, V]> {
  return Array.from(map.entries());
}

import React, { useMemo } from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

import { initLocalization } from "./helpers/i18n";
import { Provider } from "react-redux";
import { configureAppStore } from "./store/configureStore";

import { ParentConnectionProvider } from "./components/ParentConnectionContext";
import { DataProvider, useDataContext } from "./components/DataContext";

import "./index.css";
import App from "./App";
import axios from "axios";
import UploaderTestWrapper from "./components/UploaderTestWrapper";
import ErrorBoundary from "./components/ErrorBoundary";
import directMountConfig from "./directMountConfig";
import reportWebVitals from "./reportWebVitals";
import ResizeObserverPolyfill from "resize-observer-polyfill";

// Polyfill crap for Safari 12 :barf:
// @ts-expect-error This doesn't export types
export * from "core-js/stable/array/at";
if (typeof ResizeObserver === "undefined") {
  window.ResizeObserver = ResizeObserverPolyfill;
}
// End polyfill crap

declare global {
  interface Window {
    dromo: {
      current: any;
    };
  }
}

initLocalization("en");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2e5797e1fcc543058c7adc565edab36b@o491402.ingest.sentry.io/5556947",
    autoSessionTracking: true,
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    ignoreErrors: ["[Dromo-External-Error]"],
    release: process.env.REACT_APP_GIT_SHA,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    beforeSend(event, hint) {
      const error = hint.originalException;

      if ((error as Error | undefined) && axios.isAxiosError(error)) {
        if (error.config) {
          event.fingerprint = [
            error.config.url ?? "",
            error.config.method ?? "",
          ];
        }
        if (error.response) {
          event.contexts = {
            ...event.contexts,
            apiResponse: {
              status: error.response.status.toString(),
              body: error.response.data,
            },
          };
        }
      }

      return event;
    },
  });
}

const ReduxProviderWithDataContext: React.FC = ({ children }) => {
  const dataContext = useDataContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => configureAppStore(dataContext), []);

  return <Provider store={store}>{children}</Provider>;
};

if (process.env.REACT_APP_DIRECT_MOUNT === "1") {
  window.dromo = React.createRef();

  ReactDOM.render(
    <DataProvider>
      <ReduxProviderWithDataContext>
        <UploaderTestWrapper ref={window.dromo} />

        <button
          data-cy="dromo-open"
          onClick={() => {
            window.dromo.current.open();
          }}
          className="px-4 py-2 bg-blue text-white rounded hover:bg-blue-600"
        >
          Open
        </button>
      </ReduxProviderWithDataContext>
    </DataProvider>,
    document.getElementById("root")
  );

  window.dromo.current.configure(directMountConfig);
} else {
  ReactDOM.render(
    <DataProvider>
      <ReduxProviderWithDataContext>
        <ParentConnectionProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ParentConnectionProvider>
      </ReduxProviderWithDataContext>
    </DataProvider>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { MenuListProps, components } from "react-select";

export const MenuList = ({
  innerProps,
  children,
  ...props
}: MenuListProps<any>) => {
  return (
    <components.MenuList {...props} innerProps={innerProps}>
      <div data-cy="select-menu-list">{children}</div>
    </components.MenuList>
  );
};

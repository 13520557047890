import { HotTable } from "@handsontable/react";
import {
  HiddenColumns,
  HiddenRows,
  NestedHeaders,
  registerPlugin,
} from "handsontable/plugins";
import React, { useEffect, useRef, useState } from "react";
import { HANDSONTABLE_LICENSE_KEY } from "../../constants/constants";
import { useAppSelector } from "../../store/hooks";
import { FullDataWithMeta } from "../../thunks/data_actions";
import useWindowSize from "../../util/useWindowSize";
import { twMerge } from "tailwind-merge";
import { transpose } from "../../helpers/TableHelpers";
import { selectVisibleMappedSpecs } from "../../store/selectors";
import { IDeveloperField } from "../../interfaces";
import { useTranslation } from "react-i18next";

const plugins = [HiddenColumns, HiddenRows, NestedHeaders];

for (const plugin of plugins) {
  registerPlugin(plugin);
}

const PADDING_OFFSET = 48 + 24; // 48 for the paddings around the table, 24 for the padding the table should gain on the right side

function PreviewTable({
  beforeValues,
  afterValues,
  columnsChanged,
  rowsChanged,
  hideBefore,
  showAllColumns,
  rowsRemoved,
}: {
  beforeValues: FullDataWithMeta;
  afterValues: FullDataWithMeta;
  columnsChanged: Set<number>;
  rowsChanged: Set<number>;
  hideBefore: boolean;
  showAllColumns: boolean;
  rowsRemoved: Set<number>;
}) {
  const windowSize = useWindowSize();
  const tableRef = useRef<any | null>(null);
  const columnMapping = useAppSelector(selectVisibleMappedSpecs);
  const [transformedValues, setTransformedValues] = React.useState<unknown[][]>(
    []
  );
  const [colsWithChanges, setColsWithChanges] = React.useState<Set<number>>(
    new Set()
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!hideBefore) {
      const transposedBeforeValues = transpose(beforeValues);
      const afterTransposed = transpose(afterValues);

      const collatedValues: unknown[][] = [];
      const newColsWithChanges: Set<number> = new Set();

      for (let i = 0; i < transposedBeforeValues.length; i++) {
        collatedValues.push(transposedBeforeValues[i]);
        if (columnsChanged.has(i)) {
          newColsWithChanges.add(collatedValues.length - 1);
          collatedValues.push(afterTransposed[i]);
        }
      }

      const finalData = transpose(collatedValues);

      setTransformedValues(finalData);
      setColsWithChanges(newColsWithChanges);
    } else {
      setTransformedValues(afterValues);
    }
  }, [afterValues, beforeValues, hideBefore, rowsRemoved, columnsChanged]);

  const indexes = Array.from(
    Array(Math.max(...columnMapping.keys(), beforeValues[0].length)).keys()
  );

  const rowsAreRemoved = rowsRemoved.size > 0;

  const hiddenColumns = indexes.reduce((prev, index) => {
    if (
      !columnMapping.has(index) ||
      (!showAllColumns && !columnsChanged.has(index) && !rowsAreRemoved)
    ) {
      if (hideBefore) {
        return [...prev, index];
      } else {
        // If hideBefore === false, then the changed columns indexes will be repeated [col1, col1, col2, col2, col3, col3]
        const changesBeforeIndex = Array.from(columnsChanged).filter(
          (changedIndex) => changedIndex <= index
        ).length;

        const normalizedIndex = index + changesBeforeIndex;

        return [...prev, normalizedIndex];
      }
    }

    return prev;
  }, [] as number[]);

  const mappedColumns = [...columnMapping].reduce((array, [colIndex, spec]) => {
    array[colIndex] = spec;
    return array;
  }, [] as IDeveloperField[]);

  const hiddenRows = transformedValues.reduce((prev: number[], _, index) => {
    if (!rowsChanged.has(index) && !rowsRemoved.has(index)) {
      return [...prev, index];
    }

    return prev;
  }, []);

  const [width, setWidth] = useState(0);

  const afterGetColumnHeaderRenderers = () => {
    const newWidth = transformedValues[0]?.reduce((prev: number, _, index) => {
      const colWidth = tableRef.current?.hotInstance?.getColWidth(index);

      if (typeof colWidth === "number") {
        return prev + colWidth;
      }

      return prev;
    }, 0);

    setWidth(newWidth);
  };

  const headers = Array.from(Array(6), () => "");
  colsWithChanges.forEach((changedColIdx) => {
    headers[changedColIdx] = t("common.before");
    headers[changedColIdx + 1] = t("common.after");
  });
  return (
    <div
      className="h-[max-content] mx-auto"
      style={{
        width: `${Math.min(
          width + 16,
          windowSize.width * 0.9 - PADDING_OFFSET
        )}px`,
      }}
      data-cy="preview-table"
    >
      <HotTable
        ref={tableRef}
        data={transformedValues}
        afterGetColumnHeaderRenderers={afterGetColumnHeaderRenderers}
        columns={() => ({
          readOnly: true,
        })}
        hiddenColumns={{
          columns: hiddenColumns,
        }}
        hiddenRows={{
          rows: hiddenRows,
        }}
        nestedHeaders={[
          mappedColumns.map((output, index) => ({
            label: output?.label || "",
            colspan: !hideBefore && columnsChanged.has(index) ? 2 : 1,
          })),
          ...(!hideBefore ? [headers] : []),
        ]}
        cells={(row, col) => {
          const cellClasses = twMerge(
            "!text-gray-700",
            columnsChanged.has(col - 1) && "!bg-gray-200/50",
            rowsRemoved.has(row) &&
              "!bg-red-50 relative after:content-[''] after:absolute after:w-full after:h-1 after:-translate-y-1/2 after:bg-red-300/40 after:top-1/2 after:left-0"
          );
          return {
            className: cellClasses,
          };
        }}
        height="57vh"
        width="100%"
        licenseKey={HANDSONTABLE_LICENSE_KEY}
      />
    </div>
  );
}

export default PreviewTable;

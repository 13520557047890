import {
  ZodType,
  output,
  input,
  ZodTypeDef,
  ParseInput,
  ParseReturnType,
  INVALID,
  ZodParsedType,
  ZodIssueCode,
  addIssueToContext,
  RawCreateParams,
  ZodRawShape,
  ZodObject,
  ZodFirstPartyTypeKind,
} from "zod";

interface DFDUTypeDef<SchemaType extends ZodObject<ZodRawShape>>
  extends ZodTypeDef {
  typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion;
  schemas: Record<string, SchemaType>;
  options: SchemaType[];
}

/**
 * This is based on Zod's native DiscriminatedUnion type.
 * ref: https://zod.dev/?id=discriminated-unions
 * ref: https://github.com/colinhacks/zod/blob/502384e56fe2b1f8173735df6c3b0d41bce04edc/src/types.ts#L2902
 *
 * It is able to properly discriminate between different field types, handling both forms of specifying
 * a field type, e.g. `{ type: "number" }` or `{ type: ["number", numberOpts] }`
 */
export class DeveloperFieldDiscriminatedUnion<
  SchemaType extends ZodObject<ZodRawShape>
> extends ZodType<
  output<SchemaType>,
  DFDUTypeDef<SchemaType>,
  input<SchemaType>
> {
  _parse(input: ParseInput): ParseReturnType<this["_output"]> {
    const { ctx } = this._processInputParams(input);

    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType,
      });
      return INVALID;
    }

    const typeValue = ctx.data.type;
    let typeKey: string;
    if (Array.isArray(typeValue)) {
      typeKey = typeValue[0];
    } else if (typeValue === undefined) {
      typeKey = "string";
    } else {
      typeKey = typeValue;
    }

    const schema = this._def.schemas[String(typeKey)];

    if (schema === undefined) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Object.keys(this._def.schemas),
        path: ["type"],
      });
      return INVALID;
    }

    if (ctx.common.async) {
      return schema._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx,
      }) as any;
    } else {
      return schema._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx,
      }) as any;
    }
  }

  static create<SchemaType extends ZodObject<ZodRawShape>>(
    schemas: Record<string, SchemaType>,
    params?: RawCreateParams
  ) {
    return new DeveloperFieldDiscriminatedUnion<SchemaType>({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      schemas,
      options: [...new Set(Object.values(schemas))],
      ...params,
    });
  }
}

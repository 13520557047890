import type { DeepPartial } from "@reduxjs/toolkit";

const isAllEmpty = (obj: any): boolean => {
  return Object.keys(obj).every((key) => {
    if (typeof obj[key] === "object") {
      return isAllEmpty(obj[key]);
    } else if (
      obj[key] === null ||
      obj[key] === undefined ||
      (typeof obj[key] === "string" && obj[key].length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  });
};

export const removeEmptyKeys = <T extends Record<string, any>>(
  obj: T
): DeepPartial<T> | undefined => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === "object" && value !== null) {
      const nestedValue = removeEmptyKeys(value);
      if (nestedValue !== undefined) {
        newObj[key] = nestedValue;
      }
    } else if (
      value !== null &&
      !(typeof value === "string" && value.length === 0)
    ) {
      newObj[key] = value;
    }
  });

  if (isAllEmpty(newObj)) {
    return undefined;
  }

  return newObj;
};

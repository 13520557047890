import { getTableDataFromInitialData } from "../helpers/CoreDataHelpers";
import { AppThunk } from "../store/configureStore";
import { setData, setRawDataRowLength } from "../store/reducers/coredata";
import {
  getOriginalFields,
  setColumnMapping,
  ColumnMapping,
} from "../store/reducers/fields";

import { selectKeyToIndexMap } from "../store/selectors";
import { trimDataForMaxRecords } from "./initialization";
import { cleanRawData } from "../helpers/TableHelpers";
import { initializeCoredataData } from "./file_processing";

export const initColumnMappings = (
  data?: Record<string, unknown>[]
): AppThunk<ColumnMapping> => {
  return (dispatch, getState) => {
    const state = getState();
    const fields = getOriginalFields(state.fields);

    const row = (data ?? [])[0] ?? {};

    const columnMappings: ColumnMapping = new Map();
    let colIdx = 0;

    fields.forEach((field) => {
      if (field.manyToOne) {
        const maybeVals = row[field.key];

        if (Array.isArray(maybeVals) && maybeVals.length > 0) {
          maybeVals.forEach(() => {
            columnMappings.set(colIdx, { key: field.key, matchType: "EXACT" });
            colIdx += 1;
          });
          return;
        }
      }

      columnMappings.set(colIdx, { key: field.key, matchType: "EXACT" });
      colIdx += 1;
    });

    dispatch(setRawDataRowLength(colIdx + 1));
    dispatch(setColumnMapping(columnMappings));
    return columnMappings;
  };
};

export type InitialData = string[][] | Record<string, string | string[]>[];
export const setInitialData = (initialData: InitialData | null): AppThunk => {
  return (dispatch) => {
    if (initialData && initialData.length > 0) {
      const data = dispatch(trimDataForMaxRecords(initialData));

      // if this is an array it is an unmapped data set
      if (Array.isArray(data[0])) {
        dispatch(setInitialUnmappedData(data as string[][]));
      } else {
        dispatch(setInitialMappedData(data as Record<string, string>[]));
      }
    }
  };
};

const setInitialMappedData = (
  initialData: Record<string, string>[]
): AppThunk => {
  return (dispatch, getState, { setFullDataAddMeta }) => {
    dispatch(initColumnMappings(initialData ?? {}));
    const keyToIndexMap = selectKeyToIndexMap(getState());
    const initialTableData = getTableDataFromInitialData(
      initialData,
      keyToIndexMap
    );

    const rowLength = initialTableData[0]?.length ?? 0;
    dispatch(setRawDataRowLength(rowLength));

    dispatch(
      setData({
        file: null,
        uploadType: "INITIAL_DATA_MAPPED",
        rawPreviewData: initialTableData,
      })
    );

    dispatch(initializeCoredataData(initialTableData));

    setFullDataAddMeta(initialTableData);
  };
};

export const setInitialUnmappedData = (initialData: string[][]): AppThunk => {
  return (dispatch, _getState, { setFullDataAddMeta }) => {
    cleanRawData(initialData, false);

    const rowLength = initialData[0]?.length ?? 0;
    dispatch(setRawDataRowLength(rowLength));

    dispatch(initializeCoredataData(initialData));

    dispatch(
      setData({
        file: null,
        uploadType: "INITIAL_DATA_UNMAPPED",
        rawPreviewData: initialData,
      })
    );

    setFullDataAddMeta(initialData);
  };
};

import React, { useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useAppDispatch } from "../store/hooks";
import { addError } from "../store/reducers/errors";
import { FatalErrorModal } from "./AlertModal";

const ErrorBoundary: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const fallback = useCallback<Sentry.FallbackRender>(
    ({ eventId, error }) => {
      dispatch(
        addError({
          type: "dromo",
          code: "E_UNCAUGHT_EXCEPTION",
          messageKey: "alert.genericError",
          extra: { message: error.message, sentryEventId: eventId },
        })
      );

      return <FatalErrorModal eventId={eventId} />;
    },
    [dispatch]
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;

import Fuse from "fuse.js";
import { minBy } from "lodash";
import MapWithDefault from "../util/MapWithDefault";
import { FUSE_FUZZY_MATCH_THRESHOLD } from "../constants/constants";
import { IDeveloperField } from "../interfaces";
import { ColumnMapping } from "../store/reducers/fields";

interface IFuseColumnMatch {
  index: number;
  score: number | undefined;
}

/**
 * Given an array of output fields and an array of headers,
 * returns a column mapping based on fuzzy matching the headers
 * against the output fields label, key, alternates, and description.
 * Columns are only matched if there was a good enough fuzzy match.
 */
export const fuzzyMatchHeaders = (
  fields: IDeveloperField[],
  headers: string[],
  threshold: number = FUSE_FUZZY_MATCH_THRESHOLD
): ColumnMapping => {
  const columnMapping: ColumnMapping = new Map();
  const fuse = new Fuse(fields, {
    threshold,
    includeScore: true,
    keys: ["label", "key", "alternateMatches", "description"],
  });

  const fieldIndexToMatchedColumns = new MapWithDefault<
    number,
    IFuseColumnMatch[]
  >(() => []);

  headers.forEach((fileHeader, index) => {
    const result = fuse.search(fileHeader);

    if (result.length > 0) {
      const fieldIndex = result[0].refIndex;
      const matchedColumnObject: IFuseColumnMatch = {
        index,
        score: result[0].score,
      };

      fieldIndexToMatchedColumns.get(fieldIndex).push(matchedColumnObject);
    }
  });

  fieldIndexToMatchedColumns.forEach((matchedColumns, fieldIndex) => {
    // Fuse scores: 0 = perfect match, 1 = no match
    const bestMatchedColumn = minBy(matchedColumns, (match) => match.score)!;

    columnMapping.set(bestMatchedColumn.index, {
      key: fields[fieldIndex].key,
      matchType:
        typeof bestMatchedColumn.score === "number" &&
        bestMatchedColumn.score < 1e-10
          ? "EXACT"
          : "FUZZY",
    });
  });

  return columnMapping;
};

/**
 * Returns a column mapping of suggested matches, where a column
 * is considered matching only if the header exactly matches a field's
 * key, label, or one of its alternate matches.
 */
export const exactMatchHeaders = (
  fields: IDeveloperField[],
  headers: string[],
  caseSensitive = false
): ColumnMapping => {
  const columnMapping: ColumnMapping = new Map();
  const matchedFields = new Set<string>();

  const cmpFn: (a: string, b: string) => boolean = caseSensitive
    ? (a, b) => a === b
    : (a, b) => a.toLowerCase() === b.toLowerCase();

  headers.forEach((header, index) => {
    const matches = fields.filter((field) => {
      return (
        !matchedFields.has(field.key) &&
        (cmpFn(field.key, header) ||
          cmpFn(field.label, header) ||
          (field.alternateMatches &&
            field.alternateMatches.find((alt) => cmpFn(alt, header))))
      );
    });

    // if we have multiple matches, let the user figure out the ambiguity
    if (matches.length !== 1) return;

    matchedFields.add(matches[0].key);
    columnMapping.set(index, {
      key: matches[0].key,
      matchType: "EXACT",
    });
  });

  return columnMapping;
};

/**
 * Attempts to do an exact match, in which case a column mapping is returned.
 * Otherwise, returns null.
 *
 * A match is considered exact if every header exactly matches one field, and vice versa.
 * A header-field match is considered exact if the header matches the field's label, key, or alternateMatch exactly.
 */
export const attemptFullExactMatch = (
  fields: IDeveloperField[],
  headers: string[],
  _uniqueValuesInColumn: Map<number, Set<any>>
): ColumnMapping | null => {
  // if the number of headers and number of fields don't match,
  // we know we don't have an exact match
  if (fields.length !== headers.length) return null;

  const exactMatchesMapping = exactMatchHeaders(fields, headers, true);

  if (exactMatchesMapping.size !== headers.length) return null;

  return exactMatchesMapping;
};

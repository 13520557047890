import React, { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import Spinner from "../commonComponents/Spinner";

import ColumnMatchCard from "./ColumnMatchCard";

import {
  selectDuplicateColumnMappings,
  selectMissingRequiredFieldKeys,
} from "../../helpers/FieldHelpers";
import { useTranslation } from "react-i18next";
import { matchColumns } from "../../thunks/column_matching";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useStepNavigation } from "../../thunks/step_navigation";
import EmptyFieldCard from "./EmptyFieldCard";
import { setRehydrationComplete } from "../../store/reducers/modals";
import {
  selectMatchableFieldSpecs,
  mapColumn,
  addEmptyField,
  removeEmptyField,
} from "../../store/reducers/fields";

import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { AlertModal } from "../AlertModal";
import HelpText from "../HelpText";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import StepContainer from "../commonComponents/StepContainer";
import Toggle from "../commonComponents/Toggle";
import { Alert } from "../commonComponents/Alert";
import { Card } from "../commonComponents/Card";
import { MissingFieldsWarning } from "../commonComponents/MissingFieldsWarning";
import { transpose } from "../../helpers/TableHelpers";
import { setStepInitialized } from "../../store/reducers/steps";

const ColumnMatchModal = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { canGoBack, goBack, goToNextStep } = useStepNavigation();
  const {
    columnMapping,
    headers,
    stepSettings,
    missingRequiredFieldKeys,
    allowCustomFields,
    addedEmptyFieldKeys,
    rehydrateStage,
    rehydrationComplete,
    matchableFields,
    duplicateColumnMappings,
    previewData,
    isInitialized,
  } = useAppSelector((state) => ({
    columnMapping: state.fields.columnMapping,
    headers: state.coredata.headers,
    stepSettings: state.settings.matchingStep,
    allowCustomFields: state.settings.allowCustomFields,
    missingRequiredFieldKeys: selectMissingRequiredFieldKeys(state),
    addedEmptyFieldKeys: state.fields.addedEmptyFields,
    rehydrateStage: state.modals.rehydrateStage,
    rehydrationComplete: state.modals.rehydrationComplete,
    matchableFields: selectMatchableFieldSpecs(state.fields),
    duplicateColumnMappings: selectDuplicateColumnMappings(state),
    previewData: state.coredata.data.previewData,
    isInitialized: state.steps.initializedSteps.has("COLUMN_MATCH"),
  }));
  const [filterMatchedColumns, setFilterMatchedColumns] = useState(false);
  const [areAllColumnsMatched, setAreAllColumnsMatched] = useState(false);
  const [confirmBackModal, setConfirmBackModal] = useState(false);
  const columnPreviewData = useMemo(
    () => transpose(previewData),
    [previewData]
  );

  useEffect(() => {
    const allColumnsAreMatched = columnPreviewData.every((_, index) =>
      columnMapping.has(index)
    );

    if (allColumnsAreMatched) {
      setFilterMatchedColumns(false);
      setAreAllColumnsMatched(true);
    } else {
      setAreAllColumnsMatched(false);
    }
  }, [columnMapping, columnPreviewData]);

  const toggleFilterMatchedColumns = () => {
    setFilterMatchedColumns(!filterMatchedColumns);
  };

  useEffect(() => {
    async function setColumnMappingAndDataPreview() {
      if (!isInitialized) {
        await dispatch(matchColumns());
        dispatch(setStepInitialized("COLUMN_MATCH"));
      }
    }

    setColumnMappingAndDataPreview();
  }, [isInitialized, dispatch]);

  useEffect(() => {
    if (rehydrateStage === "COLUMN_MATCHING" && !rehydrationComplete) {
      dispatch(setRehydrationComplete());
    }
  }, [rehydrateStage, dispatch, rehydrationComplete]);

  const getLabelByKey = (key: string): string => {
    const field = matchableFields.find((f) => f.key === key);
    return field ? field.label : key;
  };

  const handleAddEmptyColumn = (columnKey: string) => {
    dispatch(addEmptyField(columnKey));
  };

  const handleRemoveEmptyColumn = (columnKey: string) => {
    dispatch(removeEmptyField(columnKey));
  };

  // Index is the index of the column in the original CSV
  const cards = columnPreviewData.map(
    (columnData: Array<any>, columnIndex: number) => {
      const isUnmatched = !columnMapping.has(columnIndex);
      const hideCard = filterMatchedColumns ? !isUnmatched : false;

      return hideCard ? null : (
        <ColumnMatchCard
          key={columnIndex}
          columnIndex={columnIndex}
          columnData={columnData}
        />
      );
    }
  );

  const mappedFieldKeys = useMemo(() => {
    return new Set([...columnMapping.values()].map(({ key }) => key));
  }, [columnMapping]);

  const emptyFieldCards = [...addedEmptyFieldKeys].map((key) => {
    const wasMatched = mappedFieldKeys.has(key);

    return (
      <EmptyFieldCard
        key={key}
        name={key}
        label={getLabelByKey(key)}
        onRemoveEmptyColumn={() => handleRemoveEmptyColumn(key)}
        error={wasMatched ? t("columnMatchModal.duplicateFieldsAlert") : ""}
      />
    );
  });

  const getHeaderWithNoDuplicateColumnMappings = (header: string) => {
    const existingHeaders = matchableFields.map((f) => f.label);
    let newHeader = header;
    let i = 1;

    while (existingHeaders.includes(newHeader)) {
      newHeader = `${header} ${i}`;
      i++;
    }

    return newHeader;
  };

  const handleAddAllUnmatchedAsCustomFields = () => {
    columnPreviewData.forEach((_, index) => {
      if (columnMapping.has(index)) return;
      const header = getHeaderWithNoDuplicateColumnMappings(
        headers !== null ? headers[index] : "Column"
      );

      dispatch(mapColumn(index, header, "CUSTOM"));
    });
  };

  const handleConfirmBackCancel = () => {
    setConfirmBackModal(false);
  };

  const handleConfirmBackShow = canGoBack && (() => setConfirmBackModal(true));

  return (
    <>
      <AlertModal
        show={confirmBackModal}
        setShow={setConfirmBackModal}
        message={t("common.clearAllProgressAlert")}
        primaryButtonText={t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={goBack}
        onSecondaryButtonClick={handleConfirmBackCancel}
        showSecondaryButton={true}
        data-cy="column-match-alert"
      />
      <StepContainer
        data-cy="ColumnMatchModal"
        onBack={handleConfirmBackShow}
        step="matchColumns"
      >
        <div className="flex flex-wrap lg:flex-nowrap items-center lg:justify-between">
          <div>
            <Text type="h1" className="mb-2">
              {t("columnMatchModal.title")}
            </Text>
            <Text type="body">{t("columnMatchModal.subtitle")}</Text>
          </div>
          <div className="flex gap-4 items-center self-end ml-auto mt-4 lg:mt-0">
            {filterMatchedColumns && allowCustomFields && (
              <Button
                theme="ghost"
                className="flex gap-2 items-center self-end font-normal p-0"
                onClick={handleAddAllUnmatchedAsCustomFields}
              >
                <PlusIcon />
                <span> {t("columnMatchModal.addAllCustomFieldsButton")}</span>
              </Button>
            )}
            <div
              className={twMerge(
                "flex gap-2 items-center self-end",
                areAllColumnsMatched && "opacity-60"
              )}
            >
              <Toggle
                onChange={toggleFilterMatchedColumns}
                checked={filterMatchedColumns}
                disabled={areAllColumnsMatched}
                label={t("columnMatchModal.showUnmatchedToggle")}
              />
            </div>
          </div>
        </div>

        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}

        <Card className="my-4 mt-6">
          <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
            <div className="grid grid-cols-2 divide-x divide-ice-300">
              <Text type="medium" className="px-4 py-2">
                {t("columnMatchModal.yourColumn")}
              </Text>
              <Text type="medium" className="px-4 py-2">
                {t("columnMatchModal.matchesTo")}
              </Text>
            </div>

            {isInitialized ? (
              <>
                {cards}

                {emptyFieldCards}
              </>
            ) : (
              <div className="flex flex-col items-center py-4 bg-white">
                <Spinner role="status" />
                <Text className="mt-2">
                  {t("columnMatchModal.aiMatchesLoading", "common.loading")}
                </Text>
              </div>
            )}
          </div>
        </Card>

        <div className="space-y-2">
          {duplicateColumnMappings.length > 0 && (
            <Alert type="error">
              {t("columnMatchModal.duplicateFieldsAlert")}{" "}
              {duplicateColumnMappings.join(", ")}
            </Alert>
          )}

          {missingRequiredFieldKeys.length > 0 && isInitialized && (
            <MissingFieldsWarning
              missingRequiredFieldKeys={missingRequiredFieldKeys}
              onAddEmptyColumn={handleAddEmptyColumn}
              getLabelByKey={getLabelByKey}
            />
          )}
        </div>

        <div className="flex justify-end items-center gap-2 w-full mt-6">
          <Button
            onClick={() => goToNextStep()}
            disabled={
              !isInitialized ||
              columnMapping.size === 0 ||
              missingRequiredFieldKeys.length > 0 ||
              duplicateColumnMappings.length > 0
            }
            data-cy="column-match-continue-button"
            autoFocus
          >
            {t("columnMatchModal.continue")}
          </Button>
        </div>
      </StepContainer>
    </>
  );
};

export default ColumnMatchModal;

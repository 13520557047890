import React, { useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import Text from "../commonComponents/Text";
import { twMerge } from "tailwind-merge";
import { truncate } from ".";

type BulkFixCardProps = React.PropsWithChildren<{
  columnName: string;
  instructionText: string;
}>;

const BulkFixCard: React.FC<BulkFixCardProps> = ({
  children,
  columnName,
  instructionText,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div
        className="!px-4 py-2 flex gap-2 items-center text-ice-900"
        role="button"
        tabIndex={0}
        onClick={toggleOpen}
      >
        <button
          className="!p-1 grid place-items-center hover:bg-ice-100 rounded-lg cursor-pointer"
          onClick={toggleOpen}
        >
          <ArrowRight
            className={twMerge("w-6 h-6 transition-all", isOpen && "rotate-90")}
          />
        </button>
        <div className="flex items-baseline gap-2">
          <Text
            type="h1"
            className="text-base whitespace-nowrap"
            data-cy="bulk-fix-col-name"
          >
            {truncate(columnName, 40)}
          </Text>
          <Text type="body" className="text-sm">
            {instructionText}
          </Text>
        </div>
      </div>

      <div
        className={twMerge(
          "grid grid-rows-[0fr] transition-all",
          isOpen && "grid-rows-[1fr]"
        )}
      >
        <div
          className={twMerge(
            "overflow-hidden",
            isOpen && "hover:overflow-visible"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default BulkFixCard;

import { createAsyncThunk } from "@reduxjs/toolkit";
import Fuse from "fuse.js";
import {
  FUSE_EXACT_MATCH_THRESHOLD,
  FUSE_FUZZY_MATCH_THRESHOLD,
  FUSE_LESS_FUZZY_MATCH_THRESHOLD,
  MAX_AI_SELECT_OPTION_UNIQUES,
} from "../constants/constants";
import { getAPIClient } from "../helpers/APIHelpers";
import {
  attemptFullExactMatch,
  exactMatchHeaders,
  fuzzyMatchHeaders,
} from "../helpers/ColumnMatching";
import { getFieldType } from "../fields";
import { SelectField } from "../fields/select";
import {
  ISelectField,
  IDeveloperSelectOption,
  IDeveloperField,
} from "../interfaces";
import { AppThunk } from "../store/configureStore";
import { RootState } from "../store/reducers";
import {
  selectUniqueValsInColumn,
  setAiMatchSelectOptionStatusFulfilled,
} from "../store/reducers/coredata";
import { selectAIMatching } from "../store/reducers/settings";
import { selectMappedSpecs, selectMappedSelectSpecs } from "../store/selectors";
import {
  selectMatchableFieldSpecs,
  selectFieldSpecAtColIndex,
  setColumnMapping,
  setIgnoredColumns,
  clearSelectFieldMapping,
  updateSelectFieldMapping,
  setSelectFieldMapping,
  ISelectFieldOption,
  ColumnMapping,
} from "../store/reducers/fields";
import {
  selectHasMappingErrors,
  hasSameSelectFieldUniqueValues,
} from "../helpers/FieldHelpers";
import { IColumnMappingCacheSerialized } from "../helpers/ColumnMappingCacheHelper";

const matchedFieldsAndHeadersFromMapping = (
  mapping: ColumnMapping
): { matchedFields: Set<string>; matchedHeaderIdxs: Set<number> } => ({
  matchedFields: new Set([...mapping.values()].map(({ key }) => key)),
  matchedHeaderIdxs: new Set([...mapping.keys()]),
});

export const aiMatchColumns = createAsyncThunk(
  "coredata/fetchAIColumnMatchesStatus",
  async (_, { getState }): Promise<ColumnMapping> => {
    const state = getState() as RootState;
    const api = getAPIClient(state);
    const fields = selectMatchableFieldSpecs(state.fields);
    const headers = state.coredata.headers!;

    // first find exact matches, we'll only query for unknowns
    const mapping = exactMatchHeaders(fields, headers);
    const maxPossibleMatches = Math.min(
      headers.filter((h) => h !== "").length,
      fields.length
    );
    if (mapping.size === maxPossibleMatches) {
      return mapping;
    }

    let matchResults = matchedFieldsAndHeadersFromMapping(mapping);

    const unmatchedFields = fields.filter(
      (f) => !matchResults.matchedFields.has(f.key)
    );

    const mapFieldToQuery = (field: IDeveloperField) => ({
      key: field.key,
      terms: [field.key, field.label, ...(field.alternateMatches || [])],
    });

    if (!state.settings.backendCapabilities?.ai_many_to_one_matching) {
      const queries = unmatchedFields.map(mapFieldToQuery);

      const resp = await api.getAIMatches({
        queries,
        matches: headers.filter(
          (header, idx) =>
            !matchResults.matchedHeaderIdxs.has(idx) && header !== ""
        ),
        matchExclusive: true,
      });

      if (resp !== null) {
        const matches = resp.results;

        fields
          .filter((f) => !matchResults.matchedFields.has(f.key))
          .forEach((field) => {
            const match = matches[field.key];
            if (match !== null) {
              mapping.set(headers.indexOf(match[0]), {
                key: field.key,
                matchType: "AI",
              });
            }
          });
      }
    } else {
      const queries = unmatchedFields
        .filter((f) => f.manyToOne)
        .map(mapFieldToQuery);

      const exclusiveQueries = unmatchedFields
        .filter((f) => !f.manyToOne)
        .map(mapFieldToQuery);

      const resp = await api.getAIMatches({
        queries,
        matches: headers.filter(
          (header, idx) =>
            !matchResults.matchedHeaderIdxs.has(idx) && header !== ""
        ),
        exclusive_queries: exclusiveQueries,
      });
      // null response means API timeout or too many terms
      if (resp !== null) {
        const matches = resp.results;

        headers.forEach((header, idx) => {
          const match = matches[header];
          if (match !== null && !matchResults.matchedHeaderIdxs.has(idx)) {
            mapping.set(idx, {
              key: match[0],
              matchType: "AI",
            });
          }
        });
      }
    }

    // Fuzzy match the leftovers, with a less fuzzy threshold
    matchResults = matchedFieldsAndHeadersFromMapping(mapping);
    const leftoverFields = fields.filter(
      (f) => !matchResults.matchedFields.has(f.key)
    );
    const leftoverHeaders = headers.filter(
      (_, i) => !matchResults.matchedHeaderIdxs.has(i)
    );
    fuzzyMatchHeaders(
      leftoverFields,
      leftoverHeaders,
      FUSE_LESS_FUZZY_MATCH_THRESHOLD
    ).forEach((colMap, loIdx) => {
      mapping.set(headers.indexOf(leftoverHeaders[loIdx]), colMap);
    });
    return mapping;
  }
);

export const matchColumns = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { coredata, settings, fields } = state;

    if (!coredata.headers) return;

    let columnMapping;

    if (selectAIMatching(state)) {
      columnMapping = await dispatch(aiMatchColumns()).unwrap();
    } else {
      const matchFn = settings.matchingStep.fuzzyMatchHeaders
        ? fuzzyMatchHeaders
        : exactMatchHeaders;

      columnMapping = matchFn(
        selectMatchableFieldSpecs(fields),
        coredata.headers
      );
    }

    dispatch(setColumnMapping(columnMapping));
  };
};

const isExactMatchOnly = (field: ISelectField): boolean => {
  return (
    Array.isArray(field.type) &&
    "exactMatchOnly" in field.type[1] &&
    !!field.type[1].exactMatchOnly
  );
};

export const matchSelectOptions = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const state = getState();
    const selectFields = selectMappedSelectSpecs(state);

    let allExact = true;
    for (const [colIndex, field] of selectFields) {
      const isExact = dispatch(exactMatchSelectOptions(colIndex));

      allExact = allExact && isExact;

      if (!isExact && !isExactMatchOnly(field)) {
        dispatch(aiOrFuzzyMatchSelectOptions(colIndex));
      } else {
        dispatch(setAiMatchSelectOptionStatusFulfilled(colIndex));
      }
    }

    return allExact;
  };
};

// Returns true if it was a full exact match, i.e. every unique value had an exact match
export const exactMatchSelectOptions = (
  columnIndex: number
): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const { coredata, fields } = getState();
    const field = selectFieldSpecAtColIndex(fields, columnIndex);
    if (!field || getFieldType(field) !== "select")
      throw new Error("Called exactMatchSelectOptions with a non-select field");

    const colValCounts = coredata.data?.valCountsInColumn?.get(columnIndex);

    if (!colValCounts || colValCounts.size === 0) {
      return true;
    }

    // Array of [option, everything that option maps to exactly] tuples
    const exactMatches: [IDeveloperSelectOption, Set<string>][] = (
      field as ISelectField
    ).selectOptions.map((opt) => [
      opt,
      new Set([opt.label, opt.value, ...(opt.alternateMatches ?? [])]),
    ]);

    let numValsMatched = 0;

    for (const value of colValCounts.keys()) {
      const match = exactMatches.find(([_label, matches]) =>
        matches.has(value)
      );

      if (match) {
        dispatch(
          updateSelectFieldMapping(columnIndex, value, {
            ...match[0],
            matchType: "EXACT",
          })
        );

        numValsMatched++;
      }
    }

    return numValsMatched === colValCounts.size;
  };
};

export const aiOrFuzzyMatchSelectOptions = createAsyncThunk(
  "coredata/fetchSelectOptionMatchStatus",
  async (columnIndex: number, { dispatch, getState }): Promise<void> => {
    const state = getState() as RootState;
    const api = getAPIClient(state);
    const valueCounts = state.coredata.data.valCountsInColumn?.get(columnIndex);
    const partialMapping: Map<string, ISelectFieldOption> =
      state.fields.selectFieldMapping.get(columnIndex) ?? new Map();

    if (valueCounts === undefined) {
      return;
    }

    const developerField = selectFieldSpecAtColIndex(
      state.fields,
      columnIndex
    ) as ISelectField | undefined;
    if (!developerField || getFieldType(developerField) !== "select") return;

    const selectOptions = developerField.selectOptions.map((o) =>
      SelectField.normalizeOption(o)
    );

    const matchStringToSelectOption = new Map(
      selectOptions.map((opt) => [
        [opt.label, opt.value, ...(opt.alternateMatches || [])].join(" "),
        opt,
      ])
    );

    // need to remove exact matches
    const fuseResults = new Fuse(selectOptions, {
      includeScore: true,
      threshold: FUSE_FUZZY_MATCH_THRESHOLD,
      keys: ["label", "value", "alternateMatches"],
    });

    // Sort the values by frequency, remove blanks, and remove already matched values
    const values = [...valueCounts.entries()]
      .sort(([_a, aCount], [_b, bCount]) => bCount - aCount)
      .map(([value]) => value)
      .filter((v) => v)
      .filter((v) => !partialMapping.has(v));

    const fuzzyMatchMap = new Map<string, Fuse.FuseResult<ISelectFieldOption>>(
      values.reduce((matches, colValue) => {
        const fuseMatch = fuseResults.search(colValue);
        if (fuseMatch.length > 0) {
          matches.push([colValue, fuseMatch[0]]);
        }
        return matches;
      }, [] as [string, Fuse.FuseResult<ISelectFieldOption>][])
    );

    const topUnmatchedValues = values
      .filter((val) => {
        const maybeMatch = fuzzyMatchMap.get(val);
        // remove values with perfect matches
        return !(maybeMatch && maybeMatch.score! < FUSE_EXACT_MATCH_THRESHOLD);
      })
      // don't overwhelm the API with too many options, we'll fuzzy match the leftovers
      .slice(0, MAX_AI_SELECT_OPTION_UNIQUES);

    const resp =
      selectAIMatching(state) &&
      topUnmatchedValues.length > 0 &&
      matchStringToSelectOption.size > 0
        ? await api.getAIMatches({
            queries: topUnmatchedValues.map((uniqVal) => ({
              key: uniqVal,
              terms: [uniqVal],
            })),
            exclusive_queries: [],
            matches: [...matchStringToSelectOption.keys()],
          })
        : null;

    values.forEach((uniqueColumnValue) => {
      // ignore `null` and `undefined` column values
      if (uniqueColumnValue == null) return;

      const aiResult = resp && resp.results[uniqueColumnValue];
      const fuzzyMatchResult = fuzzyMatchMap.get(uniqueColumnValue);
      const isExactMatch =
        fuzzyMatchResult &&
        fuzzyMatchResult.score! < FUSE_EXACT_MATCH_THRESHOLD;
      let maybeMatch = fuzzyMatchResult?.item;

      if (aiResult) {
        maybeMatch = matchStringToSelectOption.get(aiResult[0]);
      }

      if (maybeMatch) {
        dispatch(
          updateSelectFieldMapping(columnIndex, uniqueColumnValue, {
            ...maybeMatch,
            matchType: isExactMatch ? "EXACT" : "AI",
          })
        );
      }
    });
  }
);

export const fuzzyMatchSelectOptions = (colIndex: number): AppThunk => {
  return (dispatch, getState) => {
    const { coredata, fields } = getState();

    const uniqueValuesInColumn = selectUniqueValsInColumn(coredata.data)?.get(
      colIndex
    );
    if (!uniqueValuesInColumn) return;

    dispatch(clearSelectFieldMapping(colIndex));

    const developerField = selectFieldSpecAtColIndex(fields, colIndex) as
      | ISelectField
      | undefined;
    if (!developerField || developerField.type !== "select") return;

    const selectOptions = developerField.selectOptions.map((o) =>
      SelectField.normalizeOption(o)
    );

    const fuseResults = new Fuse(selectOptions, {
      includeScore: true,
      threshold: FUSE_FUZZY_MATCH_THRESHOLD,
      keys: ["label", "value", "alternateMatches"],
    });

    for (const uniqueColumnValue of uniqueValuesInColumn) {
      // ignore `null` and `undefined` column values
      if (uniqueColumnValue == null) continue;

      const result = fuseResults.search(uniqueColumnValue);
      if (result.length > 0) {
        dispatch(
          updateSelectFieldMapping(colIndex, uniqueColumnValue, result[0].item)
        );
      }
    }
  };
};

export type AUTO_MAP_RESULT =
  | "HEADERS_AND_SELECT_OPTIONS"
  | "HEADERS_ONLY"
  | "NONE";

// Attempts to auto map headers, if applicable.
// Returns a boolean corresponding to whether or not the function was able to
// send the user to the next step (whether matching or review).
export const tryAutoMapHeaders = (): AppThunk<Promise<AUTO_MAP_RESULT>> => {
  return async (dispatch, getState) => {
    let state = getState();
    const api = getAPIClient(state);
    const { coredata, settings } = state;

    if (!settings.autoMapHeaders) return "NONE";
    if (coredata.headers === null) return "NONE";

    let mapping: IColumnMappingCacheSerialized | undefined;
    try {
      mapping = await api.getSuggestedMapping(
        coredata.headers,
        settings.importIdentifier
      );

      // undefined means that this mapping hasn't been done yet
      if (mapping === undefined) {
        return "NONE";
      }
    } catch (err) {
      console.error("Error fetching automatic mapping");
      return "NONE";
    }

    const success = dispatch(setColumnMappingFromCache(mapping));

    state = getState();

    // We only want to skip to the DATA_REVIEW modal if there are no errors
    // in the mapping (i.e. duplicate fields and required fields)
    if (success && !selectHasMappingErrors(state)) {
      return hasSameSelectFieldUniqueValues(state, mapping)
        ? "HEADERS_AND_SELECT_OPTIONS"
        : "HEADERS_ONLY";
    }

    return "NONE";
  };
};

export const tryFullExactMatch = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    const { coredata, fields } = getState();

    const matchableFields = selectMatchableFieldSpecs(fields);

    if (coredata.headers) {
      const columnMapping = attemptFullExactMatch(
        matchableFields,
        coredata.headers,
        selectUniqueValsInColumn(coredata.data)!
      );

      if (columnMapping) {
        dispatch(setColumnMapping(columnMapping));

        const mappedSpecs = selectMappedSpecs(getState());
        const confirmedColumns = new Set<number>();

        for (const [colIndex, field] of mappedSpecs) {
          if (getFieldType(field) === "select") {
            const colValCounts =
              coredata.data?.valCountsInColumn?.get(colIndex);

            if (!colValCounts || colValCounts.size === 0) {
              confirmedColumns.add(colIndex);
              continue;
            }

            const exactMatches = new Set(
              (field as ISelectField).selectOptions.flatMap((opt) => [
                opt.label,
                opt.value,
                ...(opt.alternateMatches ?? []),
              ])
            );

            if (
              [...colValCounts.keys()].every((value) => exactMatches.has(value))
            ) {
              confirmedColumns.add(colIndex);
            }
          } else {
            confirmedColumns.add(colIndex);
          }
        }

        return [...columnMapping.keys()].every((colIndex) =>
          confirmedColumns.has(colIndex)
        );
      }
    }

    return false;
  };
};

export function setColumnMappingFromCache(
  mapping: IColumnMappingCacheSerialized
): AppThunk<boolean> {
  return (dispatch, getState) => {
    let success = true;
    const { coredata, fields } = getState();
    const { headers } = coredata;
    const { headerToColumnMapping, headerToSelectFieldMapping } = mapping;
    const matchableFields = selectMatchableFieldSpecs(fields);
    const cacheMap: ColumnMapping = new Map();
    const ignoredColumns = new Set<number>();
    const retSelectFieldMapping = new Map<
      number,
      Map<string, IDeveloperSelectOption>
    >();

    if (!headers) return false;

    // Going through the header -> { isIgnored, developerColKey } mappings
    for (const [header, colMappingData] of Object.entries(
      headerToColumnMapping
    )) {
      // Get's the col index based off the current file that is uploaded. This
      // allows us to match files where the column order is different
      const colIndex = headers.indexOf(header);

      // Skip over if we can't find the header
      if (colIndex < 0) continue;

      // If the header was ignored, then we can just ignore it again and move on
      if (colMappingData.isIgnored) {
        ignoredColumns.add(colIndex);
        continue;
      }

      // Find the developer defined output field based off of the key.
      const field = matchableFields.find((x) => x.key === colMappingData.key);

      // If the field wasn't found, this means that either the schema has changed
      // and the key no longer exists in the current schema, or, the field was
      // a custom field.
      // Either way, we can't automap it.
      if (!field) {
        success = false;
        continue;
      }

      // If this is a select field we want to find the mapping for that as well
      if (
        field?.type === "select" &&
        headerToSelectFieldMapping[header] !== undefined
      ) {
        const curSelectFieldMapping = new Map<string, IDeveloperSelectOption>();
        // for header -> ( (userValue -> developerKey), (userValue -> developerKey), ...)
        // find the IDeveloperSelectOption in selectOptions
        for (const [userSelectValue, developerKey] of Object.entries(
          headerToSelectFieldMapping[header]
        )) {
          const developerSelectOption = (
            field as ISelectField
          ).selectOptions?.find((x) => x.value === developerKey);
          if (developerSelectOption) {
            curSelectFieldMapping.set(userSelectValue, developerSelectOption);
          }
        }
        retSelectFieldMapping.set(colIndex, curSelectFieldMapping);
      }
      cacheMap.set(colIndex, { key: field.key, matchType: "AUTOMAP" });
    }

    // See if exact matches catch anything that the mapping cache didn't have
    const exactMatchMap = exactMatchHeaders(matchableFields, headers);

    // But we want to keep ignoring explicitly ignored columns
    for (const colIndex of ignoredColumns) {
      exactMatchMap.delete(colIndex);
    }

    const fullMap = combineColumnMappings(cacheMap, exactMatchMap);

    dispatch(setColumnMapping(fullMap));
    dispatch(setSelectFieldMapping(retSelectFieldMapping));
    dispatch(setIgnoredColumns(ignoredColumns));

    return success;
  };
}

// Combines two different column mapping maps, giving preference
// to mappingA. The combined mapping contains all mappings from
// mappingA, plus any mappings in mappingB that don't conflict
// with mappingA.
const combineColumnMappings = (
  mappingA: ColumnMapping,
  mappingB: ColumnMapping
): ColumnMapping => {
  const mappedColumns = new Set(mappingA.keys());
  const mappedFields = new Set([...mappingA.values()].map(({ key }) => key));

  const combinedMapping: ColumnMapping = new Map(mappingA.entries());

  for (const [colIndex, mapping] of mappingB.entries()) {
    if (!mappedColumns.has(colIndex) && !mappedFields.has(mapping.key)) {
      combinedMapping.set(colIndex, mapping);
    }
  }

  return combinedMapping;
};

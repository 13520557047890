import React, { createContext, useContext } from "react";
import { TSelectProps } from "..";

type TSelectContext = Partial<TSelectProps> & {
  onCloseMenu: () => void;
  creatable: boolean;
};

const SelectContext = createContext<null | TSelectContext>(null);

export function useSelectContext() {
  const context = useContext(SelectContext);

  if (context === null) {
    // eslint-disable-next-line no-console
    throw new Error("useSelectContext must be used within a SelectContext");
  }

  return context;
}

export function SelectContextProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: TSelectContext;
}) {
  return (
    <SelectContext.Provider value={value}>{children}</SelectContext.Provider>
  );
}

/* eslint-disable camelcase */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TLocaleShorthand } from "../interfaces";

// Import all files in the locales directory
import bn from "../locales/bn.json";
import cs from "../locales/cs.json";
import da from "../locales/da.json";
import de from "../locales/de.json";
import el from "../locales/el.json";
import en from "../locales/en.json";
import es from "../locales/es.json";
import fi from "../locales/fi.json";
import fr from "../locales/fr.json";
import hi from "../locales/hi.json";
import hu from "../locales/hu.json";
import id from "../locales/id.json";
import it from "../locales/it.json";
import ja from "../locales/ja.json";
import ko from "../locales/ko.json";
import ms from "../locales/ms.json";
import nl from "../locales/nl.json";
import no from "../locales/no.json";
import pl from "../locales/pl.json";
import pt from "../locales/pt.json";
import ro from "../locales/ro.json";
import ru from "../locales/ru.json";
import sw from "../locales/sw.json";
import sv from "../locales/sv.json";
import th from "../locales/th.json";
import tr from "../locales/tr.json";
import uk from "../locales/uk.json";
import vi from "../locales/vi.json";
import zh_CN from "../locales/zh_CN.json";
import zh_TW from "../locales/zh_TW.json";
import is from "../locales/is.json";
import hr from "../locales/hr.json";
import lv from "../locales/lv.json";
import lt from "../locales/lt.json";

const resources = {
  bn: { translation: bn },
  cs: { translation: cs },
  da: { translation: da },
  de: { translation: de },
  el: { translation: el },
  en: { translation: en },
  es: { translation: es },
  fi: { translation: fi },
  fr: { translation: fr },
  hi: { translation: hi },
  hu: { translation: hu },
  id: { translation: id },
  it: { translation: it },
  ja: { translation: ja },
  ko: { translation: ko },
  ms: { translation: ms },
  nl: { translation: nl },
  no: { translation: no },
  pl: { translation: pl },
  pt: { translation: pt },
  ro: { translation: ro },
  ru: { translation: ru },
  sw: { translation: sw },
  sv: { translation: sv },
  th: { translation: th },
  tr: { translation: tr },
  uk: { translation: uk },
  vi: { translation: vi },
  zh_CN: { translation: zh_CN },
  zh_TW: { translation: zh_TW },
  is: { translation: is },
  hr: { translation: hr },
  lv: { translation: lv },
  lt: { translation: lt },
};

export function initLocalization(language: TLocaleShorthand): void {
  i18n
    // passes i18n down to react-i18next
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
    });
}

// {
// "bn":    "Bengali",
// "cs":    "Czech",
// "da":    "Danish",
// "de":    "German",
// "el":    "Greek",
// "en":    "English",
// "es":    "Spanish",
// "fi":    "Finnish",
// "fr":    "French",
// "hi":    "Hindi",
// "hu":    "Hungarian",
// "id":    "Indonesian",
// "it":    "Italian",
// "ja":    "Japanese",
// "ko":    "Korean",
// "ms":    "Malay",
// "nl":    "Dutch",
// "no":    "Norwegian",
// "pl":    "Polish",
// "pt":    "Portuguese",
// "ro":    "Romanian",
// "ru":    "Russian",
// "sw":    "Swahili",
// "sv":    "Swedish",
// "th":    "Thai",
// "tr":    "Turkish",
// "uk":    "Ukrainian",
// "vi":    "Vietnamese",
// "zh_CN": "Chinese (Simplified)",
// "zh_TW": "Chinese (Traditional)",
// "is":    "Icelandic",
// "hr":    "Croatian",
// "lv":    "Latvian",
// "lt":    "Lithuanian"
// }

import { AnyAction } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { rootReducer, RootState } from "./reducers";
import * as Sentry from "@sentry/react";
import type { IDataContext } from "../components/DataContext";

type DataContextExtra = Pick<
  IDataContext,
  "setFullData" | "setFullDataAddMeta"
>;

export function configureAppStore(
  dataContext: IDataContext,
  preloadedState?: RootState
) {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: {
            setFullData: dataContext.setFullData,
            setFullDataAddMeta: dataContext.setFullDataAddMeta,
          },
        },
      }),
    enhancers: [sentryReduxEnhancer],
    devTools:
      process.env.NODE_ENV === "production"
        ? false
        : { serialize: { options: true } },
    preloadedState,
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}

export type AppStore = ReturnType<typeof configureAppStore>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  DataContextExtra,
  AnyAction
>;

export type AppDispatch = AppStore["dispatch"];

import { z } from "zod";

export const BaseValidatorSchema = z.object({
  errorMessage: z.string().optional(),
  level: z.enum(["info", "warning", "error"]).optional(),
});

export const RequiredValidatorSchema = BaseValidatorSchema.extend({
  validate: z.literal("required"),
});
export type IRequiredValidator = z.infer<typeof RequiredValidatorSchema>;

export const UniqueValidatorSchema = BaseValidatorSchema.extend({
  validate: z.enum(["unique", "unique_case_insensitive"]),
});
export type IUniqueValidator = z.infer<typeof UniqueValidatorSchema>;

export const UniqueWithValidatorSchema = BaseValidatorSchema.extend({
  validate: z.literal("unique_with"),
  uniqueKey: z.string(),
});
export type IUniqueWithValidator = z.infer<typeof UniqueWithValidatorSchema>;

// this is separated so we can correctly annotate it for export
export const RegExpSchema = z.instanceof(RegExp);

export const RegexValidatorSchema = BaseValidatorSchema.extend({
  validate: z.enum(["regex_match", "regex_exclude"]),
  regex: z.union([z.string(), RegExpSchema]).refine(
    (val) => {
      try {
        // eslint-disable-next-line no-new
        new RegExp(val);
        return true;
      } catch (e) {
        return false;
      }
    },
    { message: "Invalid RegExp" }
  ),
  regexOptions: z
    .object({
      ignoreCase: z.boolean(),
      dotAll: z.boolean(),
      multiline: z.boolean(),
      unicode: z.boolean(),
    })
    .partial()
    .optional(),
});
export type IRegexValidator = z.infer<typeof RegexValidatorSchema>;

export const RequireWithValidatorSchema = BaseValidatorSchema.extend({
  validate: z.enum([
    "require_with",
    "require_without",
    "require_with_all",
    "require_without_all",
  ]),
  fields: z.array(z.string()),
});
export type IRequireWithValidator = z.infer<typeof RequireWithValidatorSchema>;

export const RequireWithValuesValidatorSchema = BaseValidatorSchema.extend({
  validate: z.enum([
    "require_with_values",
    "require_without_values",
    "require_with_all_values",
    "require_without_all_values",
  ]),
  fieldValues: z.record(z.any()),
});
export type IRequireWithValuesValidator = z.infer<
  typeof RequireWithValuesValidatorSchema
>;

export const LengthValidatorSchema = BaseValidatorSchema.extend({
  validate: z.literal("length"),
  min: z.number().gt(0).optional(),
  max: z.number().gt(0).optional(),
});

export type ILengthValidator = z.infer<typeof LengthValidatorSchema>;

export const AlphabeticalValidatorSchema = BaseValidatorSchema.extend({
  validate: z.literal("alphabetical"),
});

export type IAlphabeticalValidator = z.infer<
  typeof AlphabeticalValidatorSchema
>;

export const ValidatorSchema = z
  .discriminatedUnion("validate", [
    RequiredValidatorSchema,
    UniqueValidatorSchema,
    UniqueWithValidatorSchema,
    RegexValidatorSchema,
    RequireWithValidatorSchema,
    RequireWithValuesValidatorSchema,
    LengthValidatorSchema,
    AlphabeticalValidatorSchema,
  ])
  .refine(
    (val) =>
      !(
        val.validate === "length" &&
        val.min === undefined &&
        val.max === undefined
      ),
    { message: "Length requires min or max" }
  );

export type IValidatorField = z.infer<typeof ValidatorSchema>;

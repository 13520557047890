import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

interface SSNFieldOpts extends BaseTypeOpts {
  outputDash?: boolean;
}

export class SSNField extends AbstractField<BaseTypeOpts, string> {
  static defaultInvalidValueMessage = "Invalid SSN";
  type = "ssn" as const;

  readonly outputDash: boolean;

  constructor(opts: SSNFieldOpts) {
    super(opts);
    this.outputDash = opts.outputDash ?? false;
  }

  transform(value: string): ITransformResult<string> {
    const strippedValue = value.replace(/-/g, "");

    if (strippedValue.length === 9 && /^\d{9}$/.test(strippedValue)) {
      const formattedSSN = `${strippedValue.substring(
        0,
        3
      )}-${strippedValue.substring(3, 5)}-${strippedValue.substring(5)}`;
      return this.transformSuccess(formattedSSN);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    if (this.outputDash) {
      return value;
    }

    return value.replace(/\D/g, "");
  }
}

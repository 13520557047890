import { RootState } from "../../store/reducers";
import { selectVisibleMappedSpecs } from "../../store/selectors";
import { escapeRegExp } from "../../util/regex";

export const alphaColumnIndex = (colIndex: number): string => {
  const startAt = "A".charCodeAt(0);
  const firstLetterIndex = Math.floor(colIndex / 26);
  const firstLetter =
    firstLetterIndex === 0
      ? ""
      : String.fromCharCode(firstLetterIndex - 1 + startAt);

  const secondLetter = String.fromCharCode((colIndex % 26) + startAt);

  return firstLetter + secondLetter;
};

export const selectDuplicateMatchesForCol = (
  state: RootState,
  colIndex: number
): Set<string> => {
  const duplicateColumnNames = new Set<string>();

  const ownKey = state.fields.columnMapping.get(colIndex)?.key;
  if (!ownKey) return duplicateColumnNames;
  if (state.fields.ignoredColumns.has(colIndex)) return duplicateColumnNames;

  const { headers } = state.coredata;
  const manyToOneKeys = new Set(
    [...state.fields.fieldSpecs]
      .filter(([_key, spec]) => spec.manyToOne)
      .map(([key]) => key)
  );

  state.fields.columnMapping.forEach((target, mapIndex) => {
    if (mapIndex === colIndex) return;
    if (manyToOneKeys.has(target.key)) return;

    if (target.key === ownKey) {
      const name = headers ? headers[mapIndex] : alphaColumnIndex(mapIndex);
      duplicateColumnNames.add(name);
    }
  });

  return duplicateColumnNames;
};

export const selectHeaderNameForCol = (
  state: RootState,
  colIndex: number
): string | undefined => {
  const header = (state.coredata.headers || [])[colIndex] as string | undefined;
  if (header === undefined) return;

  const labels = [...selectVisibleMappedSpecs(state).values()].map(
    ({ label }) => label
  );

  const duplicateRegex = new RegExp(`^${escapeRegExp(header)} \\(\\d+\\)$`);
  const duplicates = labels.filter((h) => {
    return h === header || duplicateRegex.test(h);
  });

  switch (duplicates.length) {
    case 0:
      return header;
    case 1:
      return `${header} (1)`;
    default: {
      let count = 1;
      while (duplicates.includes(`${header} (${count})`)) {
        count++;
      }

      return `${header} (${count})`;
    }
  }
};

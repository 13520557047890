import { z } from "zod";
import { NUM_ROWS_FOR_PREVIEW } from "../../constants/constants";

const SetSheetSchema = z.object({
  type: z.literal("setSheet"),
  sheetId: z.string(),
});

export type ISetSheetOperation = z.infer<typeof SetSheetSchema>;

const SetHeaderRowSchema = z.object({
  type: z.literal("setHeaderRow"),
  headerRowIndex: z
    .number()
    .int()
    .nonnegative()
    .max(NUM_ROWS_FOR_PREVIEW) // setHeader logic relies on the header row being contained in previewData
    .nullable(),
});

export type ISetHeaderRowOperation = z.infer<typeof SetHeaderRowSchema>;

const ColumnMatchAction = z.discriminatedUnion("action", [
  z.object({
    action: z.literal("mapToField"),
    columnIndex: z.number().int().nonnegative(),
    fieldKey: z.string(),
  }),
  z.object({
    action: z.literal("addAsCustom"),
    columnIndex: z.number().int().nonnegative(),
    name: z.string(),
  }),
  z.object({
    action: z.literal("addAsEmpty"),
    fieldKey: z.string(),
  }),
]);

const MatchColumnsSchema = z.object({
  type: z.literal("matchColumns"),
  actions: z.array(ColumnMatchAction),
});

export type IMatchColumnsOperation = z.infer<typeof MatchColumnsSchema>;

export const OperationSchema = z.discriminatedUnion("type", [
  SetSheetSchema,
  SetHeaderRowSchema,
  MatchColumnsSchema,
]);

export type IOperation = z.infer<typeof OperationSchema>;
export type IOperationType = IOperation["type"];

import React from "react";
import { twMerge } from "tailwind-merge";
import Text from "./Text";

type ToggleProps = {
  label: string;
  className?: string;
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function Toggle({
  label,
  className,
  disabled,
  ...props
}: ToggleProps) {
  return (
    <label
      className={twMerge(
        "flex items-center cursor-pointer m-0",
        disabled && "opacity-50",
        className
      )}
    >
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only peer"
          disabled={disabled}
          {...props}
        />
        <div
          className={`
            w-7
            h-4
            !border
            border-ice-400
            bg-ice-200
            peer-focus:outline-none
            rounded-full
            peer
            peer-checked:after:translate-x-full
            after:content-['']
            after:absolute
            after:top-[2px]
            after:left-[2px]
            after:bg-ice-50
            after:border-transparent
            after:border
            after:rounded-full
            after:h-3
            after:w-3
            after:transition-all
            peer-checked:bg-blue-600
        `}
        ></div>
      </div>
      <Text type="body" className="ml-1 !text-sm font-medium">
        {label}
      </Text>
    </label>
  );
}

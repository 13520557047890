import * as React from "react";

// Dropzone library
import { FileWithPath, useDropzone } from "react-dropzone";
import { AlertModal } from "../AlertModal";
import { selectAllowedExtensions } from "../../store/reducers/settings";
import { selectMatchableFieldSpecs } from "../../store/reducers/fields";
import { useAppSelector, useStyles } from "../../store/hooks";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";

interface IFileSelectProps {
  onFileSelected: (file: FileWithPath) => void;
  shouldShowTemplateDownloadLink: boolean;
  onTemplateDownloadClick: () => void;
}

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#888888",
};

export function FileSelect(props: IFileSelectProps): React.ReactElement {
  const allowedExtensions = useAppSelector(selectAllowedExtensions);
  const matchableFields = useAppSelector((state) =>
    selectMatchableFieldSpecs(state.fields)
  );
  const dropzoneStyles = useStyles((styles) => styles.dropzone);

  const onDrop = React.useCallback(
    (
      acceptedFiles: FileWithPath[],
      onFileUploaded: (file: FileWithPath) => void
    ) => {
      acceptedFiles.map((file: FileWithPath) => onFileUploaded(file));
    },
    []
  );

  const [showInvalidFileAlert, setShowInvalidFileAlert] = React.useState(false);

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles: FileWithPath[]) => {
      onDrop(acceptedFiles, props.onFileSelected);
    },
    onDropRejected() {
      setShowInvalidFileAlert(true);
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: allowedExtensions,
  });

  const style = React.useMemo(
    () => ({
      ...dropzoneStyles,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [dropzoneStyles, isDragActive, isDragAccept, isDragReject]
  );

  const { t } = useTranslation();

  return (
    <>
      <Transition
        show={isDragActive}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="backdrop-blur-sm fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 z-10"
      />
      <section
        className="w-full flex flex-col items-center gap-2 pb-24 pt-40 bg-[white] border-2 border-dashed border-gray-300 rounded-xl relative z-20 transition-all"
        {...getRootProps({ style })}
      >
        {matchableFields.length > 0 && (
          <div
            className={twMerge(
              "!border border-ice-300  max-w-[75%] mx-auto absolute top-10 mask-image pointer-events-none overflow-clip"
            )}
          >
            <div className="flex !divide-x divide-ice-300">
              {matchableFields.map((field) => (
                <div key={field.key} className="!divide-y divide-ice-300">
                  <div className="bg-ice-50 px-4 py-2 max-w-[20ch] min-w-[10ch]">
                    <p className="!text-sm text-ice-500 font-semibold truncate overflow-ellipsis">
                      {field.label}
                    </p>
                  </div>
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <div key={i} className="bg-white px-4 py-2">
                        <div className="bg-gray-200 rounded-full w-1/2 h-3">
                          &nbsp;
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="z-10 flex flex-col items-center gap-2">
          <Text type="h1" className="text-lg">
            {t("dataUploadModal.fileSelect.uploadTitle")}
          </Text>
          <Text type="body">
            {t("dataUploadModal.fileSelect.uploadDescription", {
              acceptedFileTypes: allowedExtensions.join(", "),
            })}
          </Text>
          <Button onClick={open} className="mt-2">
            {t("dataUploadModal.fileSelect.uploadDataButton")}
            <input {...getInputProps()} data-cy="file-input" />
          </Button>
        </div>

        <AlertModal
          show={showInvalidFileAlert}
          // TODO: Add i18n
          message="File format not supported."
          caption={`Please upload only ${allowedExtensions.join(
            ", "
          )} file(s).`}
          primaryButtonText="Close"
          secondaryButtonText=""
          primaryButtonDescriptionText=""
          secondaryButtonDescriptionText=""
          onPrimaryButtonClick={() => setShowInvalidFileAlert(false)}
          showSecondaryButton={false}
          errorModal={true}
        />
      </section>
    </>
  );
}

export default FileSelect;

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export class CheckboxField extends AbstractField<BaseTypeOpts, boolean> {
  static falsyValues = new Set([
    "",
    "0",
    "off",
    "n",
    "no",
    "false",
    "disabled",
  ]);

  static CHECKED_VALUE = "checked";
  static UNCHECKED_VALUE = "";

  type = "checkbox" as const;

  transform(rawValue: string): ITransformResult<boolean> {
    const value = !CheckboxField.falsyValues.has(rawValue.toLowerCase());

    return this.transformSuccess(value);
  }

  // unlike other types, we want to transform empty values (to false)
  transformChecked(
    rawValue: string | boolean | null
  ): ITransformResult<boolean> {
    if (typeof rawValue === "boolean")
      rawValue = rawValue
        ? CheckboxField.CHECKED_VALUE
        : CheckboxField.UNCHECKED_VALUE;

    if (typeof rawValue !== "string") rawValue = "";

    return this.transform(rawValue);
  }

  getInitialValue(): boolean {
    return false;
  }

  getDisplayValue(value: boolean): string {
    return value ? CheckboxField.CHECKED_VALUE : CheckboxField.UNCHECKED_VALUE;
  }

  getOutputValue(value: boolean): boolean {
    return value;
  }

  // unlike other types, we don't want to pass empty values through.
  // the result should always be a boolean.
  getOutputValueChecked(value: boolean): boolean {
    return value;
  }
}

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export interface UrlFieldOpts extends BaseTypeOpts {
  acceptedProtocols?: string[];
  acceptedDomains?: string[];
}

export class UrlField extends AbstractField<UrlFieldOpts, string> {
  static defaultInvalidValueMessage = "Invalid URL";
  type = "url" as const;

  readonly acceptedProtocols?: string[];
  readonly acceptedDomains?: string[];

  constructor(opts: UrlFieldOpts) {
    super(opts);
    this.acceptedProtocols = opts.acceptedProtocols;
    this.acceptedDomains = opts.acceptedDomains;

    const protocolMessage =
      this.acceptedProtocols && this.acceptedProtocols.length > 0
        ? `accepted protocols are ${this.acceptedProtocols
            .map((p) => `"${p}"`)
            .join(", ")}`
        : "";

    const domainMessage =
      this.acceptedDomains && this.acceptedDomains.length > 0
        ? `accepted domains are ${this.acceptedDomains
            .map((d) => `"${d}"`)
            .join(", ")}`
        : "";

    this.invalidValueMessage =
      "Invalid URL" +
      (protocolMessage || domainMessage ? ": " : "") +
      [protocolMessage, domainMessage].filter((msg) => msg).join(" and ");
  }

  transform(value: string): ITransformResult<string> {
    try {
      const url = new URL(value);

      if (
        this.acceptedProtocols &&
        this.acceptedProtocols.length > 0 &&
        !this.acceptedProtocols.includes(url.protocol.slice(0, -1))
      ) {
        return this.transformFailure();
      }

      if (
        this.acceptedDomains &&
        this.acceptedDomains.length > 0 &&
        !this.acceptedDomains.includes(url.hostname)
      ) {
        return this.transformFailure();
      }

      const returnUrl = url.toString();

      return this.transformSuccess(returnUrl, returnUrl !== value);
    } catch (error) {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}

import React, { HTMLAttributes, ElementType } from "react";
import { twMerge } from "tailwind-merge";

interface CardProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  as?: ElementType;
}

export const Card: React.FC<CardProps> = ({
  className,
  as = "div",
  ...props
}) => {
  const Component = as;

  return (
    <Component
      className={twMerge(
        "!p-2 md:!p-4 rounded-xl border border-gray-300 bg-white shadow-md",
        className
      )}
      {...props}
    />
  );
};

/* eslint-disable no-console */
let benchmarkStart = (_name: string) => {};
let benchmarkEnd = (_name: string) => {};

// Chrome-specific memory stuff
interface PerformanceMemory {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

interface ExtendedPerformance extends Performance {
  memory: PerformanceMemory;
}

if (process.env.REACT_APP_BENCHMARK) {
  benchmarkStart = (name: string) => {
    console.time(name);
  };

  benchmarkEnd = (name: string) => {
    console.timeEnd(name);
    if ("memory" in performance) {
      console.log(name, (performance as ExtendedPerformance).memory);
    }
  };
}

export { benchmarkStart, benchmarkEnd };

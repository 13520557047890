import React from "react";
import Text from "../commonComponents/Text";
import { ReactComponent as CloseIcon } from "../../assets/remove.svg";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const ErrorFilterButton = ({
  currentFilter,
  onClearFilter,
  onFilterAllRows,
}: {
  currentFilter: null | number | "all";
  onClearFilter: () => void;
  onFilterAllRows: () => void;
}) => {
  const { t } = useTranslation();
  const isActive = currentFilter !== null;

  return (
    <button
      key="error-message"
      data-headlessui-state={isActive ? "active" : undefined}
      data-cy={
        isActive ? "clear-filter-button" : "show-rows-with-errors-button"
      }
      className={twMerge(`
        group
        !p-2
        w-full
        flex
        items-center
        !text-sm
        text-red-700
        bg-red-50
        hover:bg-red-100
        hover:bg-opacity-30
        !border
        border-brown-200
        ui-active:border-red-700
        hover:border-red-700
        cursor-pointer
        font-bold
        transition-all
        !rounded-md
        shadow-sm
      `)}
      onClick={isActive ? onClearFilter : onFilterAllRows}
    >
      <Text
        type="inherit"
        as="span"
        className="max-w-[200px] overflow-hidden whitespace-nowrap ui-active:max-w-[88px] transition-all duration-100"
      >
        {isActive ? (
          <span className="flex gap-1 items-center">
            {t("dataReviewModal.errorActionButton.clearFilter")}
            <CloseIcon className="w-0 ui-active:w-4 overflow-hidden h-4 transition-all" />
          </span>
        ) : (
          <span>
            {t("dataReviewModal.errorActionButton.showRowsWithErrors")}
          </span>
        )}
      </Text>
    </button>
  );
};

import * as React from "react";
import { twMerge } from "tailwind-merge";

import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { useStyles } from "../store/hooks";

interface IHelpTextProps {
  content: string;
  className?: string;
}

function HelpText({ content, className }: IHelpTextProps) {
  const overrideStyles = useStyles(({ helpText }) => ({
    color: helpText.textColor,
    backgroundColor: helpText.backgroundColor,
  }));

  return (
    <div
      className={twMerge(
        "w-full text-sm font-medium p-2 rounded-lg flex items-start gap-2 mt-2 bg-blue-50 bg-opacity-30 text-blue-600",
        className,
        "developer-html"
      )}
      data-cy="help-text"
      style={overrideStyles}
    >
      <InfoIcon className="mt-[1px]" />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

export default HelpText;

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

interface USZipCodeFieldOpts extends BaseTypeOpts {
  format?: "5-digit" | "9-digit";
  outputDash?: boolean;
}

export class USZipCodeField extends AbstractField<USZipCodeFieldOpts, string> {
  static defaultInvalidValueMessage = "Invalid US ZIP code";
  type = "us-zip-code" as const;

  readonly format: "5-digit" | "9-digit";
  readonly outputDash: boolean;

  constructor(opts: USZipCodeFieldOpts) {
    super(opts);
    this.format = opts.format || "5-digit";
    this.outputDash = opts.outputDash || false;
  }

  transform(value: string): ITransformResult<string> {
    const cleanedValue = value.replace(/-/g, "");
    let isHighLight = false;

    if (!/^\d+$/.test(cleanedValue)) {
      return this.transformFailure();
    }

    const paddedValue = cleanedValue.padStart(5, "0");
    isHighLight = paddedValue !== cleanedValue;

    if (this.format === "9-digit" && paddedValue.length === 5) {
      return this.transformFailure();
    }

    if (this.format === "9-digit" && paddedValue.length === 9) {
      const formatted = `${paddedValue.substring(0, 5)}-${paddedValue.substring(
        5
      )}`;

      return this.transformSuccess(formatted, isHighLight);
    }

    if (
      paddedValue.length === 5 ||
      (this.format === "5-digit" && paddedValue.length === 9)
    ) {
      const formatted = paddedValue.substring(0, 5);
      isHighLight = isHighLight || formatted !== value;

      return this.transformSuccess(formatted, isHighLight);
    }

    return this.transformFailure();
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return this.outputDash ? value : value.replace(/-/g, "");
  }
}

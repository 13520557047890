import * as React from "react";

import Modal from "./Modal";
import Spinner from "./Spinner";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";
import { ICommonComponentsOpenModal } from "../../store/reducers/commonComponents";
import { useTranslation } from "react-i18next";

export default function ProcessingModal() {
  const isShowing = useSelector(
    (state: RootState) =>
      state.commonComponents.openModal === ICommonComponentsOpenModal.PROCESSING
  );
  const { t } = useTranslation();

  return isShowing ? (
    <Modal show={isShowing} centered className="fileUploadModal">
      <div className="px-6 py-4">
        <div className="flex items-center gap-2">
          <Spinner role="status" />
          <span className="fileUploadSpinnerText">
            {t("common.processing")}
          </span>
        </div>
      </div>
    </Modal>
  ) : null;
}

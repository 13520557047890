import React, { useMemo } from "react";
import BulkFixCard from "./BulkFixCard";
import { useDataContext } from "../DataContext";
import { getDate, DateParseResult } from "../../helpers/date_magic";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { updateDateFixMapping } from "../../store/reducers/fields";
import {
  selectMappedFieldInstances,
  selectMappedSpecs,
} from "../../store/selectors";
import Button from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import Pill from "../commonComponents/Pill";
import type { DateField } from "../../fields/datetime/date";
import { useTranslation } from "react-i18next";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";

interface DateFixCardProps {
  colIndex: number;
  parseResult: DateParseResult;
}

const PREVIEW_DATA_LENGTH = 10;

const DateFixCard: React.FC<DateFixCardProps> = ({ colIndex, parseResult }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fieldLabel, fieldInstance, hasAcceptedResult } = useAppSelector(
    (state) => ({
      fieldLabel: selectMappedSpecs(state).get(colIndex)!.label,
      fieldInstance: selectMappedFieldInstances(state).get(
        colIndex
      )! as DateField,
      hasAcceptedResult: state.fields.dateFixMapping.has(colIndex),
    })
  );

  const handleAccept = () => {
    dispatch(updateDateFixMapping(colIndex, parseResult));
  };

  const handleUndo = () => {
    dispatch(updateDateFixMapping(colIndex, null));
  };

  const { fullData } = useDataContext();
  const previewData = useMemo(() => {
    const previewData: [before: string, after: string][] = [];
    let row = -1;
    while (
      previewData.length < PREVIEW_DATA_LENGTH &&
      ++row < fullData.length - 1
    ) {
      const value = fullData[row][colIndex];
      if (typeof value !== "string" || value === "") continue;

      const dateTime = getDate(value, parseResult);
      if (!dateTime) continue;

      const after = fieldInstance.displayValueFromDateTime(dateTime);
      previewData.push([value, after]);
    }
    return previewData;
  }, [fullData, parseResult, fieldInstance, colIndex]);

  return (
    <BulkFixCard
      columnName={fieldLabel}
      instructionText={t("dataCleaningModal.dateFix.instruction")}
    >
      <table className="border-t border-ice-300 w-full">
        <thead>
          <tr>
            <th colSpan={2} className="flex gap-3 px-4 py-2">
              <Text type="medium" className="text-sm py-2">
                {t("dataCleaningModal.dateFix.prompt")}
              </Text>
              {hasAcceptedResult ? (
                <>
                  <div className="flex flex-col justify-center">
                    <Pill
                      textKey="common.accepted"
                      icon="check"
                      theme="green"
                      alwaysOpen
                    />
                  </div>
                  <Button
                    theme="ghost"
                    onClick={handleUndo}
                    className="gap-1 !p-[6px] !text-sm font-medium max-h-10 whitespace-nowrap"
                  >
                    <RemoveIcon />
                    {t("common.undo")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    theme="primary"
                    small
                    onClick={handleAccept}
                    data-cy="date-fix-accept"
                  >
                    {t("dataCleaningModal.dateFix.action")}
                  </Button>
                </>
              )}
            </th>
          </tr>
          <tr>
            <th className="px-4 w-1/2 border-r border-y border-ice-300">
              <Text type="medium" className="text-sm py-2">
                {t("common.before")}
              </Text>
            </th>
            <th className="px-4 w-1/2 border-y border-ice-300">
              <Text type="medium" className="text-sm py-2">
                {t("common.after")}
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {previewData.map(([before, after], index) => (
            <tr key={index} className="even:bg-white odd:bg-ice-25">
              <td className="px-4 w-1/2 border-r border-ice-300">
                <Text type="medium" className="text-sm font-medium">
                  {before}
                </Text>
              </td>
              <td className="px-4 w-1/2">
                <Text type="medium" className="text-sm font-medium">
                  {after}
                </Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BulkFixCard>
  );
};

export default DateFixCard;

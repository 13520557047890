import { ITableMessageInternal, IDeveloperField } from "../interfaces";

import Papa from "papaparse";
import { IndexEntry } from "../store/selectors";

export function appendTableMessagesForKey(
  tableMessages: Map<string, ITableMessageInternal[]>,
  newMessages: ITableMessageInternal[],
  key: string
): Map<string, ITableMessageInternal[]> {
  if (newMessages.length === 0) return tableMessages;

  let messages = tableMessages.get(key) || [];
  messages = messages.concat(newMessages);
  tableMessages.set(key, messages);
  return tableMessages;
}

export function objectToFile(
  data: any[],
  filename: string,
  fileType: "csv" | "json",
  allowEmpty = false
): File | null {
  if (data == null || (data.length === 0 && !allowEmpty)) {
    return null;
  }

  let blob: Blob;
  let type: string;
  if (fileType === "json") {
    const jsonContent = JSON.stringify(data);
    type = "application/json";
    blob = new Blob([jsonContent], { type });
  } else {
    const csvContent = Papa.unparse(data);
    type = "text/csv;charset=utf-8;";
    blob = new Blob([csvContent], { type });
  }

  return new File([blob], filename, { type });
}

export function getCleanedCellData(rawValue: any): string {
  if (rawValue === null) return "";

  return rawValue.toString().trim();
}

export function getTableDataFromInitialData(
  initialData: { [key: string]: any }[],
  keyToIndexMap: Map<string, IndexEntry>
): string[][] {
  if (initialData.length === 0) return [];

  const tableData: string[][] = [];
  const rowLength =
    1 +
    [...keyToIndexMap.values()].reduce((maxIndex, indexEntry) => {
      const localMax = indexEntry.manyToOne
        ? indexEntry.indexes.at(-1) ?? 0
        : indexEntry.index;

      return localMax > maxIndex ? localMax : maxIndex;
    }, 0);

  initialData.forEach((row) => {
    // the row data needs to be the length of expected outputFields,
    // note that optional fields can be empty (will be empty string)
    const rowData = Array(rowLength).fill("");

    Object.keys(row).forEach((key: string) => {
      // ignore invalid data
      const indexEntry = keyToIndexMap.get(key);
      const rowVals = row[key];
      if (!indexEntry) return;

      if (indexEntry.manyToOne) {
        indexEntry.indexes.forEach(
          (rowIdx, i) => (rowData[rowIdx] = getCleanedCellData(rowVals[i]))
        );
      } else {
        rowData[indexEntry.index] = getCleanedCellData(rowVals);
      }
    });

    if (!rowData.every((val) => val === "")) {
      tableData.push(rowData);
    }
  });

  return tableData;
}

export function getLabelsFromFields(fields: IDeveloperField[]): string[] {
  const manyToOneMap: Map<string, number> = new Map();

  return fields.map((field) => {
    if (field.manyToOne) {
      const manyToOneFieldCount = manyToOneMap.get(field.key) ?? 0;
      manyToOneMap.set(field.key, manyToOneFieldCount + 1);
      return manyToOneFieldCount === 0
        ? field.label
        : `${field.label} (${manyToOneFieldCount + 1})`;
    } else {
      return field.label;
    }
  });
}

import { z } from "zod";
import { SelectOptionSchema } from "./fields";

const TableMessageSchema = z.object({
  message: z.string(),
  level: z.enum(["info", "warning", "error"]),
});

const MessagesForCellSchema = z.object({
  rowIndex: z.number().int().nonnegative(),
  fieldKey: z.string(),
  manyToOneIndex: z.number().int().nonnegative().optional(),
  messages: z.array(TableMessageSchema),
});

export const CellMessagesSchema = z.array(MessagesForCellSchema);

export const PositionSpecSchema = z.union([
  z.object({ before: z.string() }),
  z.object({ after: z.string() }),
  z.undefined(),
]);

const RowCellBasicSchema = z.object({
  value: z.any().optional(),
  info: z.array(TableMessageSchema).optional(),
  selectOptions: z.array(SelectOptionSchema).optional(),
});

const RowCellSchema = RowCellBasicSchema.extend({
  manyToOne: z.array(RowCellBasicSchema).optional(),
});

const RowSchema = z.record(RowCellSchema);

const RowToAddSchema = z.object({
  row: RowSchema,
  index: z.number().int().nonnegative().optional(),
});

export const RowsToAddSchema = z.array(RowToAddSchema);

export const RowsToRemoveSchema = z.array(z.string());

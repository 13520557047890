import DOMPurify from "dompurify";

const removeOuterDiv = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const body = doc.body;

  if (
    body.firstChild &&
    body.firstChild.nodeName === "DIV" &&
    body.childNodes.length === 1
  ) {
    return (body.firstChild as HTMLElement).innerHTML;
  }
  return html;
};

export const sanitizeHTML = (html: string) => {
  const sanitized = DOMPurify.sanitize(html);
  return { __html: removeOuterDiv(sanitized) };
};

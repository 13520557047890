import { BaseDateTimeField } from "./base";
import type { DateTime } from "luxon";

export class DateField extends BaseDateTimeField {
  static defaultDisplayFormat = "D";
  static defaultOutputFormat = "[ISO]";
  static defaultInvalidValueMessage = "Invalid date";
  static isoFormatFn = (value: DateTime) => value.toISODate();

  type = "date" as const;
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../commonComponents/Spinner";
import { twMerge } from "tailwind-merge";

import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { ReactComponent as HideIcon } from "../../assets/hide.svg";
import { selectMissingRequiredFieldKeys } from "../../helpers/FieldHelpers";
import { IDeveloperField, MATCH_TYPES } from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  mapColumn,
  unmapColumn,
  addEmptyField,
  removeEmptyField,
  selectMatchableFieldSpecs,
  selectInvertedColumnMapping,
} from "../../store/reducers/fields";
import { selectAIMatching } from "../../store/reducers/settings";
import { matchColumns } from "../../thunks/column_matching";

import HelpText from "../HelpText";
import { Button } from "../commonComponents/Button";
import { Card } from "../commonComponents/Card";
import { MissingFieldsWarning } from "../commonComponents/MissingFieldsWarning";
import StepContainer from "../commonComponents/StepContainer";
import { getMatchPill } from "../commonComponents/Pill";
import { Select } from "../commonComponents/Select";
import Text from "../commonComponents/Text";
import Toggle from "../commonComponents/Toggle";
import { AlertModal } from "../AlertModal";
import { useStepNavigation } from "../../thunks/step_navigation";
import {
  matchTypeToI18nKey,
  unmatchedI18nKey,
} from "../ColumnMatch/ColumnMatchCard";

const SchemaFirstMatch: React.FC = () => {
  const {
    addedEmptyFieldKeys,
    aiMatchStatus,
    columnMapping,
    headers,
    invertedMapping,
    matchableFields,
    missingRequiredFieldKeys,
    stepSettings,
    useAIColumnMatching,
  } = useAppSelector((state) => ({
    addedEmptyFieldKeys: state.fields.addedEmptyFields,
    aiMatchStatus: state.coredata.aiColMatchStatus,
    columnMapping: state.fields.columnMapping,
    headers: state.coredata.headers,
    invertedMapping: selectInvertedColumnMapping(state.fields),
    matchableFields: selectMatchableFieldSpecs(state.fields),
    missingRequiredFieldKeys: selectMissingRequiredFieldKeys(state),
    stepSettings: state.settings.matchingStep,
    useAIColumnMatching: selectAIMatching(state),
  }));
  const dispatch = useAppDispatch();
  const { canGoBack, goBack, goToNextStep } = useStepNavigation();
  const { t } = useTranslation();

  const areAllColumnMatched =
    invertedMapping.size + addedEmptyFieldKeys.size === matchableFields.length;

  const [filterMatchedFields, setFilterMatchedFields] = useState(false);
  const handleToggleFilterMatchedFields = () => {
    setFilterMatchedFields(!filterMatchedFields);
  };

  const options = (headers || []).map((header, index) => ({
    label: header,
    value: index,
  }));

  useEffect(() => {
    async function match() {
      if (invertedMapping.size === 0 && headers) {
        await dispatch(matchColumns());
      }
    }

    match();
    // We don't want this to run whenever mappedKeys change, only on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (areAllColumnMatched && filterMatchedFields) {
      setFilterMatchedFields(false);
    }
  }, [areAllColumnMatched, filterMatchedFields]);

  const handleRemoveMatch = useCallback(
    (index: number) => {
      dispatch(unmapColumn(index));
    },
    [dispatch]
  );

  const handleMatchColumn = useCallback(
    (field: IDeveloperField) => (match: { value: number }) => {
      const isColumnMatched = invertedMapping.has(field.key);
      if (isColumnMatched) {
        const previousIndex = invertedMapping.get(field.key)!.colIndex;
        handleRemoveMatch(previousIndex);
      }

      dispatch(mapColumn(match.value, field.key, "USER"));
    },
    [dispatch, handleRemoveMatch, invertedMapping]
  );

  const handleAddEmptyField = useCallback(
    (key: string) => {
      dispatch(addEmptyField(key));
    },
    [dispatch]
  );

  const handleRemoveEmptyField = useCallback(
    (key: string) => {
      dispatch(removeEmptyField(key));
    },
    [dispatch]
  );

  const getLabelByKey = (key: string): string => {
    const field = matchableFields.find((f) => f.key === key);
    return field ? field.label : key;
  };

  const cards = useMemo(
    () =>
      matchableFields
        .map((field) => {
          const isAddedEmptyField = addedEmptyFieldKeys.has(field.key);
          const matchedCol = invertedMapping.get(field.key);
          const isMatched = matchedCol !== undefined || isAddedEmptyField;
          const matchTypeKey: string = matchedCol?.matchType
            ? matchTypeToI18nKey[matchedCol.matchType]
            : unmatchedI18nKey;

          if (filterMatchedFields && isMatched) {
            return null;
          }

          const value =
            matchedCol !== undefined
              ? {
                  ...options[matchedCol.colIndex],
                  matchType: matchTypeKey,
                }
              : null;

          const optionsWithMatch = options.map((option) => {
            const mapping = columnMapping.get(option.value);

            return {
              ...option,
              ...(mapping?.key === field.key && {
                matchType: matchTypeKey,
              }),
            };
          });

          return (
            <div
              className="grid grid-cols-2 items-center divide-x divide-ice-300"
              data-cy="column-match-card"
            >
              <div className="!px-4 py-2 flex gap-2 items-center text-ice-900">
                <Text
                  type="h1"
                  className="text-base"
                  data-cy="column-match-card-title"
                >
                  {field.label}
                </Text>
                {field.requireMapping && (
                  <div
                    className={twMerge(
                      "!px-2 !py-[2px] font-semibold text-sm rounded-full",
                      isMatched
                        ? "bg-ice-100 text-ice-500"
                        : "bg-red-50 text-red-700"
                    )}
                  >
                    {t("columnMatchModal.required")}
                  </div>
                )}
              </div>
              <div className="!px-4 py-2 flex gap-4 justify-between">
                {isAddedEmptyField ? (
                  <>
                    <div className="w-full">
                      <div
                        className={twMerge(
                          "!border border-ice-300 rounded-md px-2 py-1.5 bg-white flex gap-2 items-center justify-between w-full text-ice-500 !text-sm"
                        )}
                      >
                        {field.label}
                        {getMatchPill(MATCH_TYPES.USER_MATCHED, true)}
                      </div>
                    </div>
                    <Button
                      theme="ghost"
                      className="gap-2 p-0 !text-sm font-medium max-h-10"
                      onClick={() => handleRemoveEmptyField(field.key)}
                      data-cy={`empty-column-remove-button-${field.key}`}
                    >
                      <RemoveIcon className="h-4 w-4" />
                      {t("common.remove")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Select
                      className="!w-full grow"
                      data-cy="column-match-select"
                      name={`column-match-${field.key}`}
                      onChange={handleMatchColumn(field)}
                      options={optionsWithMatch}
                      value={value}
                    />

                    <Button
                      theme="ghost"
                      onClick={
                        isMatched
                          ? () => handleRemoveMatch(matchedCol!.colIndex)
                          : undefined
                      }
                      className={twMerge(
                        "gap-2 !p-[6px] !text-sm font-medium max-h-10 whitespace-nowrap",
                        !value && "opacity-0 pointer-events-none"
                      )}
                      data-cy="column-match-ignore-button"
                    >
                      <HideIcon />
                      {t("columnMatchModal.ignore")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          );
        })
        .filter(Boolean) as React.ReactNode[],
    [
      addedEmptyFieldKeys,
      columnMapping,
      invertedMapping,
      filterMatchedFields,
      handleMatchColumn,
      handleRemoveEmptyField,
      handleRemoveMatch,
      matchableFields,
      options,
      t,
    ]
  );

  const showSpinner =
    useAIColumnMatching === true &&
    headers !== null &&
    aiMatchStatus !== "fulfilled";

  const [confirmBackModal, setConfirmBackModal] = useState(false);

  const handleConfirmBackCancel = () => {
    setConfirmBackModal(false);
  };

  const handleConfirmBackShow = () => {
    setConfirmBackModal(true);
  };

  return (
    <>
      <AlertModal
        show={confirmBackModal}
        setShow={setConfirmBackModal}
        message={t("common.clearAllProgressAlert")}
        primaryButtonText={t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={goBack}
        onSecondaryButtonClick={handleConfirmBackCancel}
        showSecondaryButton
        data-cy="column-match-alert"
      />
      <StepContainer
        step="matchColumns"
        onBack={canGoBack && handleConfirmBackShow}
      >
        <header className="flex justify-between items-center mb-4">
          <div>
            <Text type="h1" className="mb-2">
              {t("columnMatchModal.title")}
            </Text>
            <Text type="body">{t("columnMatchModal.subtitle")}</Text>
          </div>
          <Toggle
            label="Show unmatched only"
            checked={filterMatchedFields}
            onChange={handleToggleFilterMatchedFields}
            disabled={areAllColumnMatched}
          />
        </header>

        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}

        <Card className="my-4 mt-6">
          {showSpinner ? (
            <Spinner />
          ) : (
            <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
              <div className="grid grid-cols-2 divide-x divide-ice-300">
                <Text type="medium" className="px-4 py-2">
                  {t("columnMatchModal.yourSchemaField")}
                </Text>
                <Text type="medium" className="px-4 py-2">
                  {t("columnMatchModal.matchesTo")}
                </Text>
              </div>

              {cards}
            </div>
          )}
        </Card>

        {missingRequiredFieldKeys.length > 0 && (
          <MissingFieldsWarning
            missingRequiredFieldKeys={missingRequiredFieldKeys}
            onAddEmptyColumn={handleAddEmptyField}
            getLabelByKey={getLabelByKey}
          />
        )}

        <footer className="flex justify-end items-center mt-6 gap-2">
          <Button
            onClick={() => goToNextStep()}
            disabled={missingRequiredFieldKeys.length > 0 || showSpinner}
            data-cy="column-match-continue-button"
            autoFocus
          >
            {t("common.next")}
          </Button>
        </footer>
      </StepContainer>
    </>
  );
};

export default SchemaFirstMatch;

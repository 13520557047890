import React from "react";
import { Transition } from "@headlessui/react";
import Text from "../Text";
import { useSelectContext } from "./context";

export const ErrorMessage = () => {
  const { error, "data-cy": dataCy } = useSelectContext();

  return (
    <Transition
      show={!!error}
      enter="transition duration-200 relative"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
    >
      <Text
        type="body"
        className="mt-1 !text-red text-sm font-medium"
        data-cy={`${dataCy}-error`}
      >
        {error}
      </Text>
    </Transition>
  );
};

// The subset relationship is not proper subset, which means isSubset()
// returns true if set and other contain the same elements.

export function isSubset(set: Set<unknown>, other: Set<unknown>): boolean {
  if (set.size > other.size) return false;

  for (const val of set) {
    if (!other.has(val)) return false;
  }

  return true;
}

import React from "react";
import Button from "../commonComponents/Button";
import { useTranslation } from "react-i18next";

import Text from "../commonComponents/Text";

function ExamplePrompts({
  onClickPrompt,
}: {
  onClickPrompt: (value: string) => void;
}) {
  const { t } = useTranslation();

  const examplePrompts = [
    t("userFunctionModal.prompts.prompt1"),
    t("userFunctionModal.prompts.prompt2"),
    t("userFunctionModal.prompts.prompt3"),
  ] as const;

  return (
    <>
      <Text type="h1" className="text-base text-center mt-16 mb-4">
        {t("userFunctionModal.prompts.title")}
      </Text>

      <div className="flex !pb-12 flex-col items-center gap-2">
        {examplePrompts.map((text) => (
          <Button
            key={text}
            theme="secondary"
            onClick={() => onClickPrompt(text)}
            className="text-sm !px-4 !py-2"
          >
            {text}
          </Button>
        ))}
      </div>
    </>
  );
}

export default ExamplePrompts;

import React from "react";

export function StarsIcon({ color }: { color: string }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="AI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="AI-Generative-Flow"
          transform="translate(-376, -224)"
          fill={color}
        >
          <g id="Group-4" transform="translate(312, 112)">
            <g id="Group" transform="translate(48, 96)">
              <g id="Icons/System/AI#02" transform="translate(16, 16)">
                <g id="Group" transform="translate(3, 3)">
                  <path
                    d="M0.318471242,4.15512071 C-0.106157081,4.08124982 -0.106157081,3.47166473 0.318471242,3.39779384 C1.85682797,3.13016736 3.08037311,1.95843668 3.41428072,0.433088768 L3.43987345,0.316165896 C3.53174731,-0.103506019 4.12929781,-0.106117009 4.22483377,0.312735827 L4.25591965,0.448993723 C4.60218332,1.96714464 5.82606392,3.12897928 7.36014635,3.39586495 C7.78694816,3.47011323 7.78694816,4.08280132 7.36014635,4.1570496 C5.82606392,4.42393527 4.60218332,5.58576991 4.25591965,7.10391803 L4.22483377,7.24018431 C4.12929781,7.65903295 3.53174731,7.65641917 3.43987345,7.23674586 L3.41428072,7.11982438 C3.08037311,5.59449185 1.85682797,4.42274719 0.318471242,4.15512071 Z"
                    id="Vector-2138"
                    fillOpacity="0.8"
                  ></path>
                  <path
                    d="M5.20354967,12.1577492 C4.47343692,12.030736 4.47343692,10.982622 5.20354967,10.8556088 C7.84863071,10.3954288 9.95240655,8.38074525 10.5265308,5.75804214 L10.5705459,5.55700429 C10.7285052,4.83541779 11.7559465,4.83091705 11.9202096,5.55110581 L11.9736455,5.78539603 C12.5690155,8.39571512 14.6734063,10.393388 17.3110933,10.8522821 C18.0449101,10.9799523 18.0449101,12.0334057 17.3110933,12.1610758 C14.6734063,12.6199699 12.5690155,14.6176568 11.9736455,17.2279479 L11.9202096,17.4622102 C11.7559465,18.1824688 10.7285052,18.177996 10.5705459,17.4563396 L10.5265308,17.2553437 C9.95242053,14.6326127 7.84863071,12.6179152 5.20354967,12.1577492 Z"
                    id="Vector-2139"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/exclamation.svg";
import Text from "../commonComponents/Text";
import { twMerge } from "tailwind-merge";

interface IAlertProps {
  type: "error" | "warning" | "success";
  "data-cy"?: string;
  children: React.ReactNode;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

const MAP_TYPE_TO_ICONS = {
  error: ExclamationIcon,
  warning: ExclamationIcon,
  success: CheckIcon,
};

const MAP_TYPE_TO_CLASSES = {
  error: "text-red-700 bg-red-50",
  warning: "text-orange-700 bg-orange-50",
  success: "text-green-700 bg-green-50",
};

export const Alert = ({
  type,
  "data-cy": dataCy,
  className,
  children,
  as,
}: IAlertProps) => {
  const classes = twMerge(
    "!p-2 flex gap-2 items-start rounded-lg min-w-[380px]",
    MAP_TYPE_TO_CLASSES[type],
    className
  );

  return (
    <div className={classes} data-cy={dataCy}>
      <div className="w-4 h-4 mt-[1px]">
        {React.createElement(MAP_TYPE_TO_ICONS[type])}
      </div>
      <Text
        type="inherit"
        className="text-inherit font-semibold text-sm"
        as={as}
      >
        {children}
      </Text>
    </div>
  );
};

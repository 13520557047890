import React from "react";
import { twMerge } from "tailwind-merge";
import { useStyles } from "../../store/hooks";

type TextTypes = "h1" | "body" | "display" | "medium" | "inherit";

interface ITextProps {
  as?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  className?: string;
  type?: TextTypes;
  "data-cy"?: string;
  [key: string]: any;
}

type TypeTagMap = {
  [key in TextTypes]: keyof JSX.IntrinsicElements;
};

const mapTypeToTag: TypeTagMap = {
  h1: "h1",
  body: "p",
  display: "p",
  medium: "p",
  inherit: "p",
};

type TypeClassesMap = {
  [key in TextTypes]: string;
};

const mapTypeToClasses: TypeClassesMap = {
  h1: "text-2xl font-semibold text-ice-900",
  body: "text-ice-500",
  display: "text-elephant text-xl font-bold",
  medium: "!text-sm text-ice-500 font-semibold",
  inherit: "!text-inherit font-inherit",
};

function Text({
  as,
  className,
  children,
  type = "body",
  "data-cy": dataCy,
  ...props
}: ITextProps) {
  const Component = as || mapTypeToTag[type];
  const classes = mapTypeToClasses[type];
  const overrideStyles = useStyles(({ global }) => ({
    color:
      type === "h1" || type === "display"
        ? global.primaryTextColor
        : global.secondaryTextColor,
  }));

  return (
    <Component
      {...props}
      className={twMerge(classes, className)}
      style={overrideStyles}
      data-cy={dataCy}
    >
      {children}
    </Component>
  );
}

export default Text;

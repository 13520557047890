import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "../store/reducers";
import { DEVELOPMENT_MODE_RESULT_ROW_LIMIT } from "../constants/constants";

export const NotProductionBanner = () => {
  const { t } = useTranslation();
  const importerMode = useSelector(
    (state: RootState) => state.settings.importerMode
  );

  if (importerMode === "PRODUCTION" || importerMode === "INIT") return <></>;

  return (
    <div className="rounded-top text-white bg-elephant p-2 text-center w-full">
      <span className="font-bold !font-mono text-sm">
        {t(
          importerMode === "DEMO"
            ? "banner.demoModeTitle"
            : "banner.developmentModeTitle"
        )}
      </span>{" "}
      <span className="!font-mono text-sm">
        {t(
          importerMode === "DEMO"
            ? "banner.demoModeText"
            : "banner.developmentModeText",
          {
            rowLimit: DEVELOPMENT_MODE_RESULT_ROW_LIMIT,
          }
        )}
      </span>
    </div>
  );
};

import React from "react";
import Text from "../Text";
import { getMatchPill } from "../Pill";
import { MATCH_TYPES } from "../../../interfaces";

export const IgnoredLabel = () => (
  <>
    <Text type="body" as="span" className="opacity-50">
      -
    </Text>
    {getMatchPill(MATCH_TYPES.IGNORED)}
  </>
);

import * as React from "react";
import "../App.css";

import Modal from "./commonComponents/Modal";
import Button from "./commonComponents/Button";
import { selectGlobalStyle } from "../store/reducers/settings";
import { useAppSelector } from "../store/hooks";
import { CheckLg } from "react-bootstrap-icons";

interface IUpsellModalProps {
  show: boolean;
  onClose: () => void;
}

const features = new Map([
  ["embeddableImporter", "Embeddable importer"],
  ["aiPowered", "AI-powered one-click uploads & guided error resolution"],
  ["schemaBuilder", "No-code schema and validation builder"],
  ["fieldTypes", "Extensive library of field types and validations"],
  ["whiteLabel", "White labeling and advanced branding options"],
  ["customizableErrors", "Customizable errors, warnings, and suggestions"],
  ["privateMode", "Private mode"],
  ["aiTransformations", "AI-assisted transformations"],
  ["unlimitedProduction", "Unlimited imports and schemas"],
  ["internationalization", "Global language support"],
  ["headless", "Import files via API (Headless) and SFTP"],
  ["onPremise", "On-premise deployments"],
  ["multiRegion", "Multi-region deployments"],
  ["BYOS", "Bring your own storage and custom data retention policies"],
  ["implementationServices", "Implementation services"],
]);

const productSummary = {
  starter: {
    title: "Starter",
    href: "https://dromo.io/pricing?product=starter",
    features: [
      "embeddableImporter",
      "aiPowered",
      "schemaBuilder",
      "fieldTypes",
    ],
  },
  pro: {
    title: "Pro",
    href: "https://dromo.io/pricing?product=pro",
    features: [
      "embeddableImporter",
      "aiPowered",
      "schemaBuilder",
      "fieldTypes",
      "whiteLabel",
      "customizableErrors",
      "privateMode",
      "aiTransformations",
    ],
  },
  enterprise: {
    title: "Enterprise",
    href: "https://dromo.io/pricing?product=enterprise",
    features: [
      "embeddableImporter",
      "aiPowered",
      "schemaBuilder",
      "fieldTypes",
      "whiteLabel",
      "customizableErrors",
      "privateMode",
      "aiTransformations",
      "unlimitedProduction",
      "internationalization",
      "headless",
      "onPremise",
      "multiRegion",
      "BYOS",
      "implementationServices",
    ],
  },
};

const featureList = [...features.keys()];
const comparisonTable = {
  starter: featureList.map((feature) =>
    productSummary.starter.features.includes(feature)
  ),
  pro: featureList.map((feature) =>
    productSummary.pro.features.includes(feature)
  ),
  enterprise: featureList.map((feature) =>
    productSummary.enterprise.features.includes(feature)
  ),
};

export const UpsellModal = (props: IUpsellModalProps): React.ReactElement => {
  const style = useAppSelector(selectGlobalStyle);

  return (
    <Modal
      show={props.show}
      onClose={props.onClose}
      centered
      style={{ ...style }}
      className="alertModal w-full max-w-4xl"
      data-cy="UpsellModal"
    >
      <div className="p-4">
        <h4 className="text-lg font-semibold pb-2 ">
          Start using Dromo in production today
        </h4>
        <div className="productComparisonTableContainer">
          <table className="productComparisonTable table table-sm">
            <thead>
              <tr>
                <th />
                {Object.keys(productSummary).map((product) => (
                  <th
                    key={
                      productSummary[product as keyof typeof productSummary]
                        .title
                    }
                    scope="col"
                    className="px-4"
                  >
                    <a
                      href={
                        productSummary[product as keyof typeof productSummary]
                          .href
                      }
                      target="_blank"
                    >
                      <p className="mx-auto text-blue">
                        {
                          productSummary[product as keyof typeof productSummary]
                            .title
                        }
                      </p>
                    </a>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {featureList.map((feature, featureIdx) => (
                <tr key={`feat${featureIdx}`} className="">
                  <td scope="row">{features.get(feature)}</td>
                  {Object.keys(productSummary).map((product, productIdx) => (
                    <td key={`prod${featureIdx}${productIdx}`}>
                      {comparisonTable[product as keyof typeof comparisonTable][
                        featureIdx
                      ] === true ? (
                        <CheckLg
                          className="mx-auto"
                          style={{
                            color: "rgb(0, 123, 255)",
                            fontSize: "30px",
                          }}
                        ></CheckLg>
                      ) : (
                        "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="alertModalFooter">
        <ul className="alertModalMessageList">
          <li className="alertModalMessageListItemLeft">
            <Button onClick={props.onClose} theme="secondary">
              Later
            </Button>
          </li>
          <li className={"alertModalMessageListItemRight"}>
            <Button
              onClick={() => {
                window.open(
                  "https://dashboard.dromo.io/billing",
                  "_blank",
                  "noopener,noreferrer"
                );
                props.onClose();
              }}
            >
              Buy now
            </Button>
          </li>
        </ul>
      </div>
    </Modal>
  );
};

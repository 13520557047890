import React from "react";

import { ReactComponent as EmptyIcon } from "../../assets/empty.svg";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import Text from "../commonComponents/Text";
import { useTranslation } from "react-i18next";
import { getMatchPill } from "../commonComponents/Pill";
import { Button } from "../commonComponents/Button";
import { twMerge } from "tailwind-merge";
import { Transition } from "@headlessui/react";
import { MATCH_TYPES } from "../../interfaces";

interface EmptyFieldCardProps {
  label: string;
  name: string;
  onRemoveEmptyColumn: () => void;
  error: string;
}

export default function EmptyFieldCard({
  label,
  name,
  onRemoveEmptyColumn,
  error,
}: EmptyFieldCardProps) {
  const { t } = useTranslation();

  return (
    <section className="grid grid-cols-2 justify-center divide-x divide-ice-300">
      <div className="!px-4 py-2 flex gap-2 items-center text-ice-900">
        <div className="group relative !p-1">
          <EmptyIcon />
          <div className="hidden group-hover:grid place-items-center !px-2 !py-1 absolute top-full left-2 whitespace-nowrap bg-ice-900 rounded-lg shadow-md">
            <Text type="body" className="!text-sm !text-white">
              {t("columnMatchModal.emptyColumn")}
            </Text>
          </div>
        </div>
        <Text type="h1" className="text-base">
          {label}
        </Text>
      </div>
      <div className="flex gap-4 !px-4 py-2 items-center justify-center">
        <div className="w-full">
          <div
            className={twMerge(
              "!border border-ice-300 rounded-md px-2 py-1.5 bg-white flex gap-2 items-center justify-between w-full text-ice-500 !text-sm",
              error && "border-red-700"
            )}
          >
            {label}
            {getMatchPill(MATCH_TYPES.USER_MATCHED, true)}
          </div>
          <Transition
            show={!!error}
            enter="transition duration-200 relative"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
          >
            <Text type="body" className="mt-1 !text-red text-sm font-medium">
              {error}
            </Text>
          </Transition>
        </div>
        <Button
          theme="ghost"
          className="gap-2 p-0 !text-sm font-medium max-h-10"
          onClick={onRemoveEmptyColumn}
          data-cy={`empty-column-remove-button-${name}`}
        >
          <RemoveIcon className="h-4 w-4" />
          {t("common.remove")}
        </Button>
      </div>
    </section>
  );
}

import { IProgress } from "../../interfaces/interfaces";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { resetState } from "./coredata";

export type RehydrateStage =
  | "SHEET_SELECTION"
  | "HEADER_SELECTION"
  | "COLUMN_MATCHING"
  | "DATA_REVIEW";

interface IModalReduxState {
  exitRequested: boolean;
  rehydrateStage: RehydrateStage | null;
  rehydrationComplete: boolean;
  progress: IProgress;
}

const initialState: IModalReduxState = {
  exitRequested: false,
  rehydrateStage: null,
  rehydrationComplete: false,
  progress: {
    message: "",
    count: 0,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setExitRequested: (state, action: PayloadAction<boolean>) => {
      state.exitRequested = action.payload;
    },

    setRehydrateStage: (state, action: PayloadAction<RehydrateStage>) => {
      state.rehydrateStage = action.payload;
    },

    setRehydrationComplete: (state) => {
      state.rehydrationComplete = true;
    },

    updateProgress: {
      prepare: (count: number, message?: string) => {
        return { payload: { count, message: message ?? "" } };
      },
      reducer: (
        state,
        action: PayloadAction<{ count: number; message: string }>
      ) => {
        state.progress = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
  },
});

export const {
  setExitRequested,
  setRehydrateStage,
  setRehydrationComplete,
  updateProgress,
} = modalsSlice.actions;
export default modalsSlice.reducer;

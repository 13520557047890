import React from "react";
import { ReactComponent as Plus2Icon } from "../../assets/plus-2.svg";
import { ReactComponent as ExclamationIcon } from "../../assets/exclamation.svg";
import Text from "../commonComponents/Text";
import { Trans } from "react-i18next";

interface IMissingFieldsWarningProps {
  missingRequiredFieldKeys: string[];
  onAddEmptyColumn: (name: string) => void;
  getLabelByKey: (key: string) => string;
}

export function MissingFieldsWarning({
  missingRequiredFieldKeys,
  onAddEmptyColumn,
  getLabelByKey,
}: IMissingFieldsWarningProps) {
  return (
    <div
      className="bg-red-50 p-2 rounded-lg flex flex-col gap-2"
      data-cy="unmatched-required-fields-error"
    >
      <div className="flex items-start gap-2 text-red">
        <ExclamationIcon className="mt-[2px]" />
        <div>
          <Text className="!text-sm !text-red font-bold">
            <Trans
              i18nKey="columnMatchModal.unmatchedRequiredFieldsAlert"
              count={missingRequiredFieldKeys.length}
            />
          </Text>
          <div className="flex gap-1 mt-2 flex-wrap">
            {missingRequiredFieldKeys.map((name, i) => (
              <button
                key={i}
                className="!bg-white px-3 py-2 rounded-full !border border-ice-300 hover:!bg-ice-50 cursor-pointer flex-1"
                onClick={() => onAddEmptyColumn(name)}
                data-cy={`empty-column-add-button-${name}`}
              >
                <Text
                  type="medium"
                  className="text-ice-600 flex gap-2 items-center whitespace-nowrap"
                >
                  {getLabelByKey(name)}
                  <span>
                    <Plus2Icon />
                  </span>
                </Text>
              </button>
            ))}
            <div className="h-0 grow-[9999]"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

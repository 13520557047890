import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

const validStatesAndTerritories = new Set([
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "AS",
  "DC",
  "GU",
  "MP",
  "PR",
  "UM",
  "VI",
]);

export class USStateTerritoryField extends AbstractField<BaseTypeOpts, string> {
  static defaultInvalidValueMessage = "Invalid state/territory code";
  type = "us-state-territory" as const;

  transform(value: string): ITransformResult<string> {
    let code = value.toUpperCase();

    // If the value is a 4 letter code eg US-CA, turn into 2 letters
    if (code.length === 5 && code.startsWith("US-")) {
      const stateCode = code.slice(3);
      code = stateCode;
    }

    if (validStatesAndTerritories.has(code)) {
      return this.transformSuccess(code, code !== value);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}

import React, { createContext, useState, useContext, useCallback } from "react";
import { FullDataWithMeta, addRowMetaToData } from "../thunks/data_actions";

export interface IDataContext {
  fullData: FullDataWithMeta;
  setFullData: (fullData: FullDataWithMeta) => void;
  setFullDataAddMeta: (data: unknown[][]) => void;
}

export const nullDataContext: IDataContext = {
  fullData: [],
  setFullData: () => {},
  setFullDataAddMeta: () => {},
};

export const DataContext = createContext<IDataContext>(nullDataContext);

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const [fullData, setFullData] = useState<FullDataWithMeta>([]);

  const setFullDataAddMeta = useCallback(
    (data: unknown[][]) => setFullData(addRowMetaToData(data)),
    [setFullData]
  );

  return (
    <DataContext.Provider
      value={{
        fullData,
        setFullData,
        setFullDataAddMeta,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);

import React from "react";
import { ReactComponent as ExclamationIcon } from "../assets/exclamation.svg";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as SparklesIcon } from "../assets/sparkles.svg";
import { sanitizeHTML } from "../util/sanitizeHTML";

interface TooltipProps {
  infoMessages: string[];
  transformMessages: string[];
  errorMessages: string[];
  warningMessages: string[];
}

const Tooltip = ({
  infoMessages,
  transformMessages,
  warningMessages,
  errorMessages,
}: TooltipProps) => {
  return (
    <div className="max-w-[340px] divide-y divide-gray-200">
      {errorMessages.map((message, index) => (
        <div className="!p-2 flex gap-2 items-start" key={`error-${index}`}>
          <div className="w-4 h-4 mt-[1px] text-red-700">
            <ExclamationIcon />
          </div>
          <div
            className="text-ice-500 text-sm prose prose-sm prose-slate font-medium prose-headings:first:mt-0 prose-headings:leading-[1] prose-p:leading-[1]"
            dangerouslySetInnerHTML={sanitizeHTML(message)}
          />
        </div>
      ))}

      {warningMessages.map((message, index) => (
        <div className="!p-2 flex gap-2 items-start" key={`warning-${index}`}>
          <div className="w-4 h-4 mt-[1px] text-yellow-500">
            <ExclamationIcon />
          </div>
          <div
            className="text-ice-500 text-sm prose prose-sm prose-slate font-medium prose-headings:first:mt-0 prose-headings:leading-[1] prose-p:leading-[1]"
            dangerouslySetInnerHTML={sanitizeHTML(message)}
          />
        </div>
      ))}

      {infoMessages.map((message, index) => (
        <div className="!p-2 flex gap-2 items-start" key={`info-${index}`}>
          <div className="w-4 h-4 text-blue-600">
            <InfoIcon />
          </div>
          <div
            className="text-ice-500 text-sm prose prose-sm prose-slate font-medium prose-headings:first:mt-0 prose-headings:leading-[1] prose-p:leading-[1]"
            dangerouslySetInnerHTML={sanitizeHTML(message)}
          />
        </div>
      ))}

      {transformMessages.map((message, index) => (
        <div className="!p-2 flex gap-2 items-start" key={`info-${index}`}>
          <div className="w-5 h-5">
            <SparklesIcon className="text-blue-600" />
          </div>
          <div
            className="text-ice-500 text-sm prose prose-sm prose-slate font-medium prose-headings:first:mt-0 prose-headings:leading-[1] prose-p:leading-[1]"
            dangerouslySetInnerHTML={sanitizeHTML(message)}
          />
        </div>
      ))}
    </div>
  );
};

export default Tooltip;

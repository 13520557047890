import React from "react";
import { twMerge } from "tailwind-merge";
import { ControlProps, components } from "react-select";
import { useSelectContext } from "./context";

export const Control = (props: ControlProps) => {
  const { error } = useSelectContext();

  return (
    <components.Control
      {...props}
      className={twMerge(
        props.className,
        "!border !border-ice-300 placeholder-ice-500 text-ice-900 sm:!text-sm !rounded-md",
        error && "!border-red"
      )}
    />
  );
};

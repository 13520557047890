import {
  parsePhoneNumber,
  CountryCode,
  PhoneNumber,
} from "libphonenumber-js/max";

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export interface PhoneNumberFieldOpts extends BaseTypeOpts {
  country?: CountryCode;
  format?: "international" | "national" | "both";
  outputFormatted?: boolean;
}

export class PhoneNumberField extends AbstractField<
  PhoneNumberFieldOpts,
  string
> {
  static defaultInvalidValueMessage = "Invalid phone number";
  type = "phone-number" as const;

  readonly country?: CountryCode;
  readonly format?: "international" | "national" | "both";
  readonly outputFormatted?: boolean;

  constructor(opts: PhoneNumberFieldOpts) {
    super(opts);

    this.country = opts.country;
    this.format = opts.format ?? "international";
    this.outputFormatted = opts.outputFormatted ?? false;
  }

  parseInternational(value: string, validateCountry: boolean): string | null {
    let phoneNumber: PhoneNumber;

    try {
      phoneNumber = parsePhoneNumber(value);
    } catch {
      return null;
    }

    if (!phoneNumber.isValid()) {
      return null;
    }

    if (
      validateCountry &&
      this.country &&
      this.country !== phoneNumber.country
    ) {
      return null;
    }

    return phoneNumber.formatInternational();
  }

  parseNational(value: string): string | null {
    let phoneNumber: PhoneNumber;

    try {
      phoneNumber = parsePhoneNumber(value, {
        extract: true,
        defaultCountry: this.country,
      });
    } catch {
      return null;
    }

    if (!phoneNumber.isValid()) {
      return null;
    }

    if (this.country !== phoneNumber.country) {
      return null;
    }

    if (this.format === "national") {
      return phoneNumber.formatNational();
    } else {
      return phoneNumber.formatInternational();
    }
  }

  parseNumber(value: string): string | null {
    let result: string | null;
    if (this.format === "national") {
      result = this.parseNational(value);
    } else if (this.format === "international") {
      result = this.parseInternational(value, true);
    } else {
      result = this.parseInternational(value, false);
      if (!result) {
        result = this.parseNational(value);
      }
    }

    if (!result && value[0] !== "+") {
      return this.parseNumber("+" + value);
    }

    return result;
  }

  transform(value: string): ITransformResult<string> {
    const lettersRegex = /[a-zA-Z]/;
    const originalDigits = value.replace(/[() +]/g, "");
    const isTransformed =
      lettersRegex.test(originalDigits) ||
      (this.format === "national" && value.includes("+"));

    const result = this.parseNumber(value);
    if (result) {
      return this.transformSuccess(result, isTransformed);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    if (this.outputFormatted) {
      return value;
    }

    return value.replace(/[^0-9+]/g, "");
  }
}

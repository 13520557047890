import { AppThunk } from "../store/configureStore";
import { addError } from "../store/reducers/errors";
import {
  setBackendCapabilities,
  setImporterMode,
} from "../store/reducers/settings";
import { getAPIClient } from "../helpers/APIHelpers";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { VerifyLicenseKeyResponse } from "../interfaces/api";

export const validateLicenseKeyAndHost = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const api = getAPIClient(state);
    const {
      appHost,
      licenseKey,
      savedSchema,
      importerMode: settingsImporterMode,
    } = state.settings;
    let importerMode = settingsImporterMode;

    let response: VerifyLicenseKeyResponse;
    try {
      response = await api.verifyLicenseKey(appHost || "");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && [401, 403].includes(err.response.status)) {
          dispatch(
            addError({
              type: "developer",
              code: "E_INVALID_LICENSE_KEY",
              messageKey: "alert.licenseError",
            })
          );
          return;
        } else {
          Sentry.captureException(err);
          dispatch(
            addError({
              type: "dromo",
              code: "E_BACKEND_CONNECTION_ERROR",
              message: "Unable to connect to Dromo import service",
            })
          );
          return;
        }
      } else {
        Sentry.captureException(err);
        dispatch(
          addError({
            type: "dromo",
            code: "E_BACKEND_CONNECTION_ERROR",
            message: "Unable to connect to Dromo import service",
          })
        );
        return;
      }
    }
    if (licenseKey !== response.license_key) {
      // unclear why this would happen
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_LICENSE_KEY",
          messageKey: "alert.licenseError",
        })
      );
      return;
    }
    if (!response.subscribed && importerMode === "PRODUCTION") {
      dispatch(
        addError({
          type: "developer",
          code: "E_SUBSCRIPTION_ERROR",
          messageKey: "alert.subscriptionError",
        })
      );
      return;
    }

    if (importerMode === "INIT") {
      importerMode = response.subscribed ? "PRODUCTION" : "DEMO";
      dispatch(setImporterMode(importerMode));
    } else if (importerMode === "DEVELOPMENT" && !response.subscribed) {
      importerMode = "DEMO";
      dispatch(setImporterMode(importerMode));
    }

    if (response.allow_new_upload === false && importerMode === "PRODUCTION") {
      // ran out of credits and overage is disabled
      dispatch(
        addError({
          type: "developer",
          code: "E_SUBSCRIPTION_ERROR",
          message:
            "The Dromo account has exceeded its capacity. Please contact your administrator.",
        })
      );
      return;
    }

    if (
      response.capabilities?.allow_js_schema === false &&
      !savedSchema &&
      importerMode !== "DEVELOPMENT"
    ) {
      dispatch(
        addError({
          type: "developer",
          code: "E_SUBSCRIPTION_ERROR",
          message: "The Dromo account not authorized to use dynamic schemas.",
        })
      );
      return;
    }

    if (response.allowed_host === false && importerMode === "PRODUCTION") {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_APP_HOST",
          messageKey: "alert.unallowedHostError",
        })
      );
      return;
    }

    if (response.capabilities != null) {
      if (
        response.capabilities.row_limit === undefined ||
        (response.capabilities.row_limit === null && response.row_limit != null)
      ) {
        response.capabilities.row_limit = response.row_limit;
      }
      dispatch(setBackendCapabilities(response.capabilities));
    }
  };
};

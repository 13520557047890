type RGB = [number, number, number];
type RGBA = [number, number, number, number];

function parseColor(inputColor: string): RGB | RGBA | null {
  let R = 0;
  let G = 0;
  let B = 0;
  let A = 1;
  const isHex = inputColor.startsWith("#");
  const isRGB = inputColor.startsWith("rgb");
  const isRGBA = inputColor.startsWith("rgba");

  if (isHex) {
    [R, G, B] = [1, 3, 5].map((start) =>
      parseInt(inputColor.substring(start, start + 2), 16)
    );
  } else if (isRGB || isRGBA) {
    const rgb = inputColor.match(/\d+(\.\d+)?/g);
    if (rgb) {
      [R, G, B] = rgb.map((val) => parseInt(val, 10));
      if (isRGBA && rgb[3]) {
        A = parseFloat(rgb[3]);
      }
    }
  } else {
    return null;
  }

  return isRGBA ? [R, G, B, A] : [R, G, B];
}

export function shadeColor(inputColor: string, percent: number): string {
  const rgb = parseColor(inputColor);
  if (!rgb) return inputColor;

  const [R, G, B] = rgb.map((channel) => {
    const newChannel = Math.round((channel * (100 + percent)) / 100);
    return newChannel < 255 ? newChannel : 255;
  });

  const [RR, GG, BB] = [R, G, B].map((channel) =>
    channel.toString(16).padStart(2, "0")
  );
  return "#" + RR + GG + BB;
}

export function getColorLuminance(inputColor: string): number {
  const rgb = parseColor(inputColor);
  if (!rgb) return 0;

  const [R, G, B] = rgb;

  // Calculate luminance
  const luminance = (0.299 * R + 0.587 * G + 0.114 * B) / 255;
  return luminance;
}

export default class MapWithDefault<KeyType, ValueType> extends Map<
  KeyType,
  ValueType
> {
  // this is a typescript-eslint bug
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultValueFn: (key?: KeyType) => ValueType;

  constructor(defaultValueFn: (key?: KeyType) => ValueType) {
    super();
    this.defaultValueFn = defaultValueFn;
  }

  get(key: KeyType): ValueType {
    const value = super.get(key);
    if (value !== undefined) return value;

    const defaultValue = this.defaultValueFn(key);
    this.set(key, defaultValue);
    return defaultValue;
  }
}

import { EBackendSyncMode, IWorksheet } from "../interfaces";
import { AppThunk } from "../store/configureStore";
import {
  setOriginalFilename,
  setSheets,
  setSelectedSheet,
  setProcessedDataId,
  setHeaders,
  setData,
  setEncoding,
  setRawDataHeaderRow,
  setRawDataRowLength,
  selectCustomFileParserExtensions,
} from "../store/reducers/coredata";
import { ISettingsReduxState } from "../store/reducers/settings";
import { addError } from "../store/reducers/errors";
import { uploadRawFileHelper, getAPIClient } from "../helpers/APIHelpers";
import {
  localFiletypes,
  NUM_ROWS_FOR_PREVIEW,
  serverFiletypes,
} from "../constants/constants";
import {
  parseCSV as parseFileWithPapaParse,
  parseWorkbook as parseFileWithSheetJS,
  parseWorksheet as parseWorksheetWithSheetJS,
  getSheetNames,
  cleanRawData,
  getColumnValueCounts,
  detectEncoding,
} from "../helpers/TableHelpers";
import { humanFileSize } from "../util/filesize";
import { trimDataForMaxRecords } from "./initialization";
import { FullDataWithMeta } from "./data_actions";
import { InitialData } from "./initial_data";
import { benchmarkStart, benchmarkEnd } from "../util/benchmark";
import { estimateDateFormats } from "../helpers/date_magic";
import { runCustomParsers } from "./hooks";

interface IProcessSelectedFileReturn {
  success: boolean;
  requiresSheetSelection: boolean;
}
type ProcessFileThunk = AppThunk<Promise<IProcessSelectedFileReturn>>;

type IParseSheetReturn =
  | { success: false; data: null }
  | { success: true; data: string[][] };

type ParseSheetThunk = AppThunk<Promise<IParseSheetReturn>>;

export const processSelectedFile = (): ProcessFileThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { settings, coredata } = state;
    const file = coredata.data.file;
    if (file == null) {
      throw new Error("processSelectedFile called with no file");
    }

    const fileName =
      typeof file === "string" ? file.split("/").pop()! : file.name;
    const fileExtension = fileName.split(".").pop()!;

    if (typeof file !== "string" && file.size > settings.maxFileSize) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_TOO_BIG",
          messageKey: "dataUploadModal.fileUpload.error.fileTooBig",
          messageValues: { maxFileSize: humanFileSize(settings.maxFileSize) },
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
      };
    }

    dispatch(setOriginalFilename(fileName));

    switch (
      parser(fileExtension, settings, selectCustomFileParserExtensions(state))
    ) {
      case "CUSTOM":
        return dispatch(processFileWithCustomParser(file));
      case "BACKEND":
        return dispatch(processFileWithBackend(file as File));
      case "SHEETJS":
        return dispatch(processFileWithSheetJS(file));
      case "PAPAPARSE":
        return dispatch(processFileWithPapaParse(file));
      case "JSON":
        return dispatch(processJSONFile(file));
    }
  };
};

type ParseSelectedSheetResponse = IParseSheetReturn & {
  requiresMaxRecordsDisclaimer: boolean;
};

export const parseSelectedSheet = (): AppThunk<
  Promise<ParseSelectedSheetResponse>
> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { coredata, settings } = state;
    const filename = coredata.originalFilename!;
    const fileExtension = filename.split(".").pop()!;

    let result: IParseSheetReturn;

    switch (
      parser(fileExtension, settings, selectCustomFileParserExtensions(state))
    ) {
      case "CUSTOM":
        result = await dispatch(parseSheetWithCustomParser());
        break;
      case "BACKEND":
        result = await dispatch(parseSheetWithBackend());
        break;
      case "SHEETJS":
        result = await dispatch(parseSheetWithSheetJS());
        break;
      case "PAPAPARSE":
        result = await dispatch(parseSheetWithPapaParse());
        break;
      case "JSON":
        result = await dispatch(parseJSONSheet());
        break;
    }

    if (!result.success) {
      return { ...result, requiresMaxRecordsDisclaimer: false };
    }

    const { data } = result;
    cleanRawData(data);
    dispatch(initializeCoredataData(data));
    const success = dispatch(checkDataEmpty(data));
    if (success) dispatch(setRawDataRowLength(data[0].length));
    const rawDataLength = data.length;
    const processedData = dispatch(trimDataForMaxRecords(data));

    if (success) {
      return {
        success: true,
        data: processedData,
        requiresMaxRecordsDisclaimer: rawDataLength !== processedData.length,
      };
    } else {
      return {
        success: false,
        data: null,
        requiresMaxRecordsDisclaimer: false,
      };
    }
  };
};

export const setWorksheetsWithOverride = (
  worksheets: IWorksheet[]
): AppThunk<boolean> => {
  return (dispatch, getState) => {
    dispatch(setSheets(worksheets));

    if (worksheets.length === 1) {
      dispatch(setSelectedSheet(worksheets[0].id));
      return false;
    }

    const { sheetOverride } = getState().settings.uploadStep;
    const matchingOverride = worksheets.find((s) => s.label === sheetOverride);
    if (matchingOverride) {
      dispatch(setSelectedSheet(matchingOverride.id));
      return false;
    }

    return true;
  };
};

export const processFileWithBackend = (file: File): ProcessFileThunk => {
  return async (dispatch) => {
    benchmarkStart("processFileWithBackend");
    const { response } = await dispatch(uploadRawFileHelper(file));

    if (response.upload_status === "FAILED") {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      benchmarkEnd("processFileWithBackend");
      return {
        success: false,
        requiresSheetSelection: false,
      };
    }

    const sheets = response.processed_data;

    if (sheets.length === 0) {
      // This happens if the file format is bad (i.e. openpyxl can't handle OpenXML)
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_NO_SHEETS",
          messageKey: "dataUploadModal.fileUpload.error.noSheets",
        })
      );

      benchmarkEnd("processFileWithBackend");
      return {
        success: false,
        requiresSheetSelection: false,
      };
    }

    const worksheets: IWorksheet[] = sheets.map(({ id, label }) => ({
      id,
      label,
    }));

    benchmarkEnd("processFileWithBackend");
    return {
      success: true,
      requiresSheetSelection: dispatch(setWorksheetsWithOverride(worksheets)),
    };
  };
};

export const parseSheetWithBackend = (): ParseSheetThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("parseSheetWithBackend");
    const state = getState();
    const api = getAPIClient(state);
    const { coredata, settings } = state;
    const sheetId = coredata.selectedSheetId;
    if (!sheetId) throw new Error("No sheet selected to parse");

    dispatch(setProcessedDataId(sheetId));

    const csvUrl = await api.getProcessedDownloadUrl(sheetId);

    try {
      const data = await parseFileWithPapaParse(
        csvUrl,
        coredata.encoding,
        settings.delimiter
      );

      benchmarkEnd("parseSheetWithBackend");
      return { success: true, data };
    } catch (err) {
      // S3 throws an error if the CSV is empty
      if (err === "Requested Range Not Satisfiable") {
        dispatch(
          addError({
            type: "data",
            code: "E_FILE_EMPTY",
            messageKey: "dataUploadModal.fileUpload.error.cannotBeEmpty",
          })
        );
      } else {
        dispatch(
          addError({
            type: "data",
            code: "E_FILE_UNPROCESSABLE",
            messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
          })
        );
      }

      return {
        success: false,
        data: null,
      };
    }
  };
};

export const processFileWithSheetJS = (
  file: File | string
): ProcessFileThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("processFileWithSheetJS");
    const { settings, coredata } = getState();
    let sheets: string[];

    try {
      sheets = await getSheetNames(file);
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
      };
    }

    // We only want to upload file if the backendSync flag is set to true and it hasn't already been uploaded
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
      coredata.uploadId === null
    ) {
      await dispatch(uploadRawFileHelper(file as File));
    }

    if (sheets.length === 0) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_NO_SHEETS",
          messageKey: "dataUploadModal.fileUpload.selectSheet",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
      };
    }

    const worksheets: IWorksheet[] = sheets.map((name) => ({
      id: name,
      label: name,
    }));

    benchmarkEnd("processFileWithSheetJS");
    return {
      success: true,
      requiresSheetSelection: dispatch(setWorksheetsWithOverride(worksheets)),
    };
  };
};

export const parseSheetWithSheetJS = (): ParseSheetThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("parseSheetWithSheetJS");
    const { coredata } = getState();
    const file = coredata.data.file!;

    const sheetId = coredata.selectedSheetId;
    if (!sheetId) throw new Error("No sheet selected to parse");

    const workbook = await parseFileWithSheetJS(file, sheetId);
    try {
      const data = await parseWorksheetWithSheetJS(workbook.Sheets[sheetId]);
      benchmarkEnd("parseSheetWithSheetJS");
      return { success: true, data };
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );
      benchmarkEnd("parseSheetWithSheetJS");
      return {
        success: false,
        data: null,
        requiresMaxRecordsDisclaimer: false,
      };
    }
  };
};

export const processFileWithPapaParse = (
  file: File | string
): ProcessFileThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("processFileWithPapaParse");
    const { settings, coredata } = getState();

    // We only want to upload file if the backendSync flag is set to true and it hasn't already been uploaded
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
      coredata.uploadId === null
    ) {
      await dispatch(uploadRawFileHelper(file as File));
    }

    let encoding: string | null;
    if (process.env.JS_PLATFORM === "headless") {
      const { detectEncoding } = await import("../headless/file_parsing");
      encoding = await detectEncoding(file as string);
    } else {
      encoding = await detectEncoding(file as File);
    }
    if (encoding) dispatch(setEncoding(encoding));

    const fileName =
      typeof file === "string" ? file.split("/").pop()! : file.name;

    // We set sheets here for consistency with other parsing methods
    // We use the filename without the extension to match SheetJS CSV behavior
    const sheetName = fileName.replace(/\.[^.]*$/, "");
    dispatch(setSheets([{ id: sheetName, label: sheetName }]));
    dispatch(setSelectedSheet(sheetName));

    benchmarkEnd("processFileWithPapaParse");
    return {
      success: true,
      requiresSheetSelection: false,
    };
  };
};

export const processFileWithCustomParser = (
  file: File | string
): ProcessFileThunk => {
  return async (dispatch) => {
    benchmarkStart("processFileWithCustomParser");

    const fileName =
      typeof file === "string" ? file.split("/").pop()! : file.name;

    // We set sheets here for consistency with other parsing methods
    // We use the filename without the extension to match SheetJS CSV behavior
    const sheetName = fileName.replace(/\.[^.]*$/, "");
    dispatch(setSheets([{ id: sheetName, label: sheetName }]));
    dispatch(setSelectedSheet(sheetName));

    benchmarkEnd("processFileWithCustomParser");
    return {
      success: true,
      requiresSheetSelection: false,
    };
  };
};

export const parseSheetWithPapaParse = (): ParseSheetThunk => {
  return async (dispatch, getState) => {
    benchmarkStart("parseSheetWithPapaParse");
    const { coredata, settings } = getState();
    const file = coredata.data.file!;

    try {
      let data: string[][];

      if (process.env.JS_PLATFORM === "headless") {
        const { parseFileWithPapaParse } = await import(
          "../headless/file_parsing"
        );

        data = await parseFileWithPapaParse(
          file as string,
          coredata.encoding,
          settings.delimiter
        );
      } else {
        data = await parseFileWithPapaParse(
          file as File,
          coredata.encoding,
          settings.delimiter
        );
      }

      benchmarkEnd("parseSheetWithPapaParse");
      return { success: true, data };
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      benchmarkEnd("parseSheetWithPapaParse");
      return {
        success: false,
        data: null,
      };
    }
  };
};

export const processJSONFile = (file: File | string): ProcessFileThunk => {
  return async (dispatch) => {
    const fileName =
      typeof file === "string" ? file.split("/").pop()! : file.name;

    // We set sheets here for consistency with other parsing methods
    // We use the filename without the extension to match SheetJS CSV behavior
    const sheetName = fileName.replace(/\.[^.]*$/, "");
    dispatch(setSheets([{ id: sheetName, label: sheetName }]));
    dispatch(setSelectedSheet(sheetName));

    return {
      success: true,
      requiresSheetSelection: false,
    };
  };
};

const readJSONFile: (file: File) => Promise<InitialData> = (file: File) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(JSON.parse(reader.result as string));
    };
    reader.readAsText(file);
  });

export const parseJSONSheet = (): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const { coredata } = getState();
    const file = coredata.data.file!;

    try {
      let json: InitialData;
      if (process.env.JS_PLATFORM === "headless") {
        const { jsonRead } = await import("../headless/file_parsing");
        json = await jsonRead(file as string);
      } else {
        json = await readJSONFile(file as File);
      }

      let data: string[][];
      if (Array.isArray(json[0])) {
        data = json as string[][];
      } else {
        const headers = Object.keys(json[0]);
        data = [headers];
        (json as Record<string, string>[]).forEach((row) =>
          data.push(headers.map((header) => row[header]))
        );
      }

      return { success: true, data };
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      return {
        success: false,
        data: null,
      };
    }
  };
};

export const parseSheetWithCustomParser = (): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const { coredata } = getState();
    const file = coredata.data.file!;
    let buffer: ArrayBuffer;
    let fileName: string;

    try {
      if (process.env.JS_PLATFORM === "headless") {
        const { fileRead } = await import("../headless/file_parsing");
        const fileBuffer = await fileRead(file as string);
        buffer = fileBuffer.buffer.slice(
          fileBuffer.byteOffset,
          fileBuffer.byteOffset + fileBuffer.byteLength
        );
        fileName = (file as string).split("/").pop()!;
      } else {
        buffer = await (file as File).arrayBuffer();
        fileName = (file as File).name;
      }

      return {
        data: await dispatch(runCustomParsers(buffer, fileName)),
        success: true,
      };
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );
    }
    return {
      success: false,
      data: null,
    };
  };
};

export const setHeaderRow = (headerIndex: number | null): AppThunk => {
  return (dispatch, getState) => {
    const { rawPreviewData } = getState().coredata.data;
    dispatch(setRawDataHeaderRow(headerIndex));

    const headerRow = headerIndex === null ? null : rawPreviewData[headerIndex];
    dispatch(setHeaders(headerRow));

    const previewData =
      headerIndex === null
        ? rawPreviewData
        : rawPreviewData.slice(headerIndex + 1);

    dispatch(setData({ previewData }));
  };
};

export const updateColumnValCounts = (fullData: FullDataWithMeta): AppThunk => {
  return (dispatch, getState) => {
    const {
      coredata: { rawDataHeaderRow },
      settings: {
        headerRowOverride,
        matchValuesStep: { maxMappableSelectValues },
      },
    } = getState();
    const headerRowIndex = headerRowOverride ?? rawDataHeaderRow ?? -1;

    const valCountsInColumn = getColumnValueCounts(
      fullData,
      maxMappableSelectValues,
      headerRowIndex,
      true
    );

    dispatch(setData({ valCountsInColumn }));
  };
};

export const guessRawDataHeaderRow = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const { rawPreviewData: data } = getState().coredata.data;

    if (data.length === 0) return;

    const maximumRowLength = data.reduce(
      (max, row) => Math.max(max, row.filter(Boolean).length),
      0
    );

    const headerRow = data.findIndex(
      (row) => row.filter(Boolean).length === maximumRowLength
    );

    dispatch(setRawDataHeaderRow(headerRow));
  };
};

export const initializeCoredataData = (parsedData: string[][]): AppThunk => {
  return (dispatch) => {
    dispatch(setPreviewData(parsedData));
    dispatch(setInitialHeaderRow());
    dispatch(setDerivedData(parsedData));
  };
};

export const setPreviewData = (parsedData: string[][]): AppThunk => {
  return (dispatch) => {
    const previewData = parsedData.slice(0, NUM_ROWS_FOR_PREVIEW);

    dispatch(
      setData({
        previewData,
        rawPreviewData: previewData,
      })
    );
  };
};

export const setInitialHeaderRow = (): AppThunk => {
  return (dispatch, getState) => {
    const {
      settings: { headerRowOverride },
      coredata: {
        data: { uploadType },
        rawDataHeaderRow,
      },
    } = getState();

    if (uploadType === "INITIAL_DATA_MAPPED") return;

    const headerRowIndex = rawDataHeaderRow ?? headerRowOverride;
    if (headerRowIndex !== null) {
      dispatch(setHeaderRow(headerRowIndex));
    } else {
      dispatch(guessRawDataHeaderRow());
    }
  };
};

export const setDerivedData = (
  parsedData: string[][],
  skipLast = false
): AppThunk => {
  return (dispatch, getState) => {
    const {
      settings: {
        matchValuesStep: { maxMappableSelectValues },
        backendCapabilities,
      },
      coredata: { rawDataHeaderRow },
    } = getState();

    if (rawDataHeaderRow === null) return;

    const valCountsInColumn = getColumnValueCounts(
      parsedData,
      maxMappableSelectValues,
      rawDataHeaderRow,
      skipLast
    );

    dispatch(setData({ valCountsInColumn }));

    if (backendCapabilities.auto_date_fix) {
      const dateFormats = estimateDateFormats(parsedData, skipLast);
      dispatch(setData({ dateFormats }));
    }
  };
};

export const setDerivedDataFromFullData = (
  fullData: FullDataWithMeta
): AppThunk => {
  return (dispatch, getState) => {
    const { rawDataHeaderRow } = getState().coredata;
    let data = fullData;
    if (rawDataHeaderRow !== null) {
      data = data.slice(rawDataHeaderRow, -1);
    }

    dispatch(setDerivedData(data as string[][], true));
  };
};

const checkDataEmpty = (data: string[][]): AppThunk<boolean> => {
  return (dispatch) => {
    if (data.length === 0) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_EMPTY",
          messageKey: "dataUploadModal.fileUpload.error.cannotBeEmpty",
        })
      );
      return false;
    }

    return true;
  };
};

const parser = (
  fileExtension: string,
  settings: ISettingsReduxState,
  customParserFileExtensions: Set<string>
): "CUSTOM" | "BACKEND" | "SHEETJS" | "PAPAPARSE" | "JSON" => {
  if (customParserFileExtensions.has(fileExtension)) {
    return "CUSTOM";
  } else if (fileExtension === "json") {
    return "JSON";
  } else if (
    serverFiletypes.has(fileExtension) &&
    settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
    !settings.backendCapabilities.write_only_storage &&
    !settings.browserExcelParsing
  ) {
    return "BACKEND";
  } else if (localFiletypes.has(fileExtension)) {
    return "PAPAPARSE";
  } else {
    return "SHEETJS";
  }
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { resetState } from "./coredata";

export enum ICommonComponentsOpenModal {
  NONE = "NONE",
  PROCESSING = "PROCESSING",
  ALERT = "ALERT",
}

interface ICommonComponentsReduxState {
  openModal: ICommonComponentsOpenModal;
  selectedHandsonCell: number[] | null;
}

const initialState: ICommonComponentsReduxState = {
  openModal: ICommonComponentsOpenModal.NONE,
  selectedHandsonCell: null,
};

const commonComponentsSlice = createSlice({
  name: "commonComponents",
  initialState,
  reducers: {
    showProcessingModal: (state) => {
      state.openModal = ICommonComponentsOpenModal.PROCESSING;
    },

    hideProcessingModal: () => initialState,

    setSelectedHandsonCell: (state, action: PayloadAction<number[]>) => {
      state.selectedHandsonCell = action.payload;
    },

    clearSelectedHandsonCell: (state) => {
      state.selectedHandsonCell = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
  },
});

export const {
  showProcessingModal,
  hideProcessingModal,
  setSelectedHandsonCell,
  clearSelectedHandsonCell,
} = commonComponentsSlice.actions;

export default commonComponentsSlice.reducer;

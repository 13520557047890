import { z } from "zod";

export const UserSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  companyId: z.string().optional(),
  companyName: z.string().optional(),
});

export type IUser = z.input<typeof UserSchema>;

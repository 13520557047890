import { useCallback } from "react";
import {
  goToStep,
  pushStep,
  goBackOneStep,
  startFromStep,
  shiftStepStack,
  popStepStack,
  setStepUninitialized,
} from "../store/reducers/steps";

import { AppThunk } from "../store/configureStore";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { hideProcessingModal } from "../store/reducers/commonComponents";

import {
  tryAutoMapHeaders,
  tryFullExactMatch,
} from "../thunks/column_matching";
import {
  resetPreviewData,
  resetStatePreservingInit,
  resetStatePreReview,
} from "../store/reducers/coredata";
import { restoreSnapshot } from "../store/reducers/fields";
import {
  selectMappedSelectSpecs,
  selectMappedDateSpecs,
} from "../store/selectors";
import { clearFullData, resetFullData } from "./data_actions";
import { RootState } from "../store/reducers";

export const useStepNavigation = () => {
  const dispatch = useAppDispatch();
  const canGoBack = useAppSelector((state) => state.steps.stepStack.length > 0);

  const goBack = useCallback(
    async () => await dispatch(goToPreviousStep()),
    [dispatch]
  );

  const goNext = useCallback<(skipCurrent?: boolean) => void>(
    async (skipCurrent = false) => {
      await dispatch(goToNextStep());
      if (skipCurrent) {
        // this means whatever step we were just on was skipped,
        // so it should not be added to the step stack.
        dispatch(popStepStack);
      }
    },
    [dispatch]
  );

  return {
    canGoBack,
    goBack,
    goToNextStep: goNext,
  };
};

export const goToNextStep = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const { steps } = getState();

    switch (steps.currentStep) {
      case "UPLOAD":
        await dispatch(nextStepFromUpload());
        return;
      case "HEADER_SELECT":
        await dispatch(nextStepFromHeaderSelect());
        return;
      case "COLUMN_MATCH":
        dispatch(nextStepFromColumnMatch());
        return;
      case "SELECT_MATCH":
        dispatch(goToStep("REVIEW"));
    }
  };
};

export const goToPreviousStep = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const { steps } = getState();

    const { currentStep } = steps;
    const destination = steps.stepStack.at(-1);
    if (!destination) return;

    if (destination === "UPLOAD") {
      dispatch(restoreSnapshot("INIT"));
      dispatch(resetStatePreservingInit());
      dispatch(clearFullData());
      dispatch(goBackOneStep());
      return;
    }

    dispatch(setStepUninitialized(currentStep));

    if (destination === "HEADER_SELECT") {
      dispatch(resetPreviewData());
    }

    if (currentStep === "REVIEW") {
      dispatch(resetStatePreReview());
      dispatch(restoreSnapshot("PREREVIEW"));
      await dispatch(resetFullData());
    }

    dispatch(goBackOneStep());
  };
};

export const goToInitialDataFirstStep = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const uploadType = getState().coredata.data.uploadType;
    if (uploadType === "INITIAL_DATA_MAPPED") {
      dispatch(startFromStep("REVIEW"));
    } else {
      await dispatch(nextStepFromUpload());
      // We shift the "UPLOAD" step off the front of the stack here so that
      // the user can't go to the upload step when there is initialData
      dispatch(shiftStepStack());
    }
  };
};

export const nextStepFromUpload = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { headerRowOverride } = state.settings;
    const { data } = state.coredata;

    if (headerRowOverride !== null || data.previewData.length === 1) {
      // if an explicit header row override was set, or if there is only one row,
      // we don't need the user to select the header row, so we can skip the
      // metadata step
      await dispatch(nextStepFromHeaderSelect());
    } else {
      dispatch(hideProcessingModal());
      dispatch(goToStep("HEADER_SELECT"));
    }
  };
};

export const nextStepFromHeaderSelect = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const autoMapSuccessful = await dispatch(tryAutoMapHeaders());
    const hasMatchableSelectFields = selectNeedsBulkEditStep(getState());

    if (autoMapSuccessful === "HEADERS_AND_SELECT_OPTIONS") {
      dispatch(goToStep("REVIEW"));
      dispatch(pushStep("COLUMN_MATCH"));
      if (hasMatchableSelectFields) dispatch(pushStep("SELECT_MATCH"));
      dispatch(hideProcessingModal());
      return;
    } else if (autoMapSuccessful === "HEADERS_ONLY") {
      dispatch(hideProcessingModal());

      if (hasMatchableSelectFields) {
        dispatch(goToStep("SELECT_MATCH"));
      } else {
        dispatch(goToStep("REVIEW"));
      }
      dispatch(pushStep("COLUMN_MATCH"));
      return;
    }

    const fullExactMatch = dispatch(tryFullExactMatch());
    if (fullExactMatch) {
      // fullExactMatch means we were able to match the select options,
      // but we might still need to fix dates
      const hasDateFixFields = selectHasFixableDateFields(getState());
      if (hasDateFixFields) {
        dispatch(goToStep("SELECT_MATCH"));
        dispatch(pushStep("COLUMN_MATCH"));
      } else {
        dispatch(goToStep("REVIEW"));
        dispatch(pushStep("COLUMN_MATCH"));
        if (selectNeedsBulkEditStep(getState()))
          dispatch(pushStep("SELECT_MATCH"));
      }
      dispatch(hideProcessingModal());
      return;
    }

    dispatch(hideProcessingModal());
    dispatch(goToStep("COLUMN_MATCH"));
  };
};

export const nextStepFromColumnMatch = (): AppThunk => {
  return (dispatch, getState) => {
    const needsBulkEdit = selectNeedsBulkEditStep(getState());

    if (needsBulkEdit) {
      dispatch(goToStep("SELECT_MATCH"));
    } else {
      dispatch(goToStep("REVIEW"));
    }
  };
};

export const selectHasMatchableSelectFields = (state: RootState): boolean => {
  const mappedSelectFields = selectMappedSelectSpecs(state);
  const { valCountsInColumn } = state.coredata.data;

  if (mappedSelectFields.size === 0) return false;

  // We can't make a determination if we haven't calculated valCounts yet
  if (valCountsInColumn === null) return false;

  // check to see if we have unique value sets for any mapped select fields
  return [...mappedSelectFields.keys()].some((colIdx) =>
    valCountsInColumn.has(colIdx)
  );
};

export const selectHasFixableDateFields = (state: RootState): boolean => {
  if (!state.settings.backendCapabilities.auto_date_fix) {
    return false;
  }

  const mappedDateFields = selectMappedDateSpecs(state);
  const { dateFormats } = state.coredata.data;

  if (dateFormats === null) return false;
  if (mappedDateFields.size === 0) return false;
  if (dateFormats.size === 0) return false;

  return [...mappedDateFields.keys()].some((colIdx) => dateFormats.has(colIdx));
};

export const selectNeedsBulkEditStep = (state: RootState): boolean => {
  return (
    selectHasMatchableSelectFields(state) || selectHasFixableDateFields(state)
  );
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { renderToString } from "react-dom/server";
import isEqual from "lodash/isEqual";

// Handsontable
import "handsontable/dist/handsontable.full.css";
import type { ColumnSettings } from "handsontable/settings";
import { HotTable, HotTableClass } from "@handsontable/react";
import {
  registerCellType,
  CheckboxCellType,
  TextCellType,
  AutocompleteCellType,
} from "handsontable/cellTypes";
import {
  registerPlugin,
  Autofill,
  AutoColumnSize,
  AutoRowSize,
  ContextMenu,
  CopyPaste,
  DragToScroll,
  HiddenColumns,
  HiddenRows,
  ManualColumnResize,
} from "handsontable/plugins";

import { buildHotColumn } from "../fields/handsontable";
import { HANDSONTABLE_LICENSE_KEY } from "../constants/constants";
import { RootState } from "../store/reducers";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { connect, ConnectedProps } from "react-redux";

import "tippy.js/dist/tippy.css"; // optional for styling'
import TippyManager from "../helpers/tippyHelpers";
import { HeaderErrorFilter } from "./HeaderErrorFilter";
import { selectColErrors, selectRawRowWidth } from "../store/reducers/coredata";
import {
  selectMappedFieldInstances,
  selectMappedSpecs,
} from "../store/selectors";
import { debounce } from "lodash";
import { IDeveloperField, ITableMessageInternal } from "../interfaces";
import { IField, fieldFromDeveloperField } from "../fields";
import { selectRequiredFieldKeys } from "../helpers/FieldHelpers";
import { selectMatchableFieldSpecs } from "../store/reducers/fields";
import {
  Settings as ContextMenuOptions,
  PredefinedMenuItemKey,
} from "handsontable/plugins/contextMenu";
import { WithTranslation, withTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import range from "../util/range";

const cellTypes = [CheckboxCellType, AutocompleteCellType, TextCellType];

for (const cellType of cellTypes) {
  registerCellType(cellType);
}

const plugins = [
  Autofill,
  AutoRowSize,
  AutoColumnSize,
  ContextMenu,
  CopyPaste,
  DragToScroll,
  HiddenColumns,
  HiddenRows,
  ManualColumnResize,
];

for (const plugin of plugins) {
  registerPlugin(plugin);
}

// Handsontable specific properties
interface Event {
  isImmediatePropagationEnabled?: boolean;
  cancelBubble?: boolean;
}

// Reimplements Handsontable helper function, because it isn't exported standalone
// https://github.com/handsontable/handsontable/blob/e8dd43fb598a5bdfe62998096902830ab6328129/handsontable/src/helpers/dom/event.js#L6
function stopImmediatePropagation(event: Event) {
  event.isImmediatePropagationEnabled = false;
  event.cancelBubble = true;
}

interface ITableWrapperProps extends WithTranslation {
  v1ManualInput?: boolean;
  tableData: any[][];
  onCellDataChange(
    data: any[][],
    changes: any[],
    selectedCell: number[] | null
  ): void;
  onRemoveRow?: (rowIndexes: number[]) => void;
  onCreateRow?: (rowIndexes: number[]) => void;
  autoAddRow?: boolean;
  hiddenRows?: Set<number>;
  tableHeight?: string;
  selectedCell?: number[] | null;
  onFilterColumn?: (columnIndex: number | null) => void;
  currentFilter?: number | null | "all";
  searchResults?: Map<string, string> | null;
}

const mapState = (state: RootState) => {
  return {
    fieldSpecs: state.fields.fieldSpecs,
    mappedSpecs: selectMappedSpecs(state),
    matchableFieldSpecs: selectMatchableFieldSpecs(state.fields), // only used for v1 manual input
    fieldInstances: selectMappedFieldInstances(state),
    tableMessages: state.coredata.tableMessages,
    styleOverrides: state.settings.styleOverrides,
    transformErrorCells: state.coredata.transformErrorCells,
    colErrors: selectColErrors(state),
    headerFontWeight: state.settings.styleOverrides.dataTable.headerFontWeight,
    requiredFieldKeys: selectRequiredFieldKeys(state),
    allowAddingRows: state.settings.reviewStep.allowAddingRows,
    allowRemovingRows: state.settings.reviewStep.allowRemovingRows,
  };
};

const reduxConnector = connect(mapState, {});
type ITableWrapperFullProps = ITableWrapperProps &
  ConnectedProps<typeof reduxConnector>;

interface ICellProperties {
  className?: string;
}

class TableWrapper extends React.Component<ITableWrapperFullProps> {
  id: string;
  hotTableComponentRef: React.RefObject<HotTableClass>;
  tippyManager: TippyManager;
  manyToOneCellMap: Map<string, number>;

  constructor(props: ITableWrapperFullProps) {
    super(props);

    // Set ref for Handsontable component
    this.id = Math.random().toString(36).substring(7);
    this.hotTableComponentRef = React.createRef();
    this.tippyManager = new TippyManager();
    this.manyToOneCellMap = new Map();
  }

  shouldComponentUpdate(nextProps: ITableWrapperFullProps) {
    return (
      !isEqual(nextProps.tableMessages, this.props.tableMessages) ||
      !isEqual(nextProps.tableData, this.props.tableData) ||
      !isEqual(nextProps.currentFilter, this.props.currentFilter) ||
      !isEqual(nextProps.hiddenRows, this.props.hiddenRows) ||
      !isEqual(nextProps.searchResults, this.props.searchResults)
    );
  }

  _getTableSelectedCell(): number[] | null {
    let selected = null;
    if (
      this.hotTableComponentRef !== null &&
      this.hotTableComponentRef.current?.hotInstance !== null
    ) {
      selected = this.hotTableComponentRef.current?.hotInstance.getSelected();
    }
    return selected ? selected[0] : null;
  }

  componentWillUnmount() {
    this.tippyManager.deleteAllTippys();
  }

  // paintErrorCells() {
  //   let errorCells = document.querySelectorAll(".handsontable td.error-cell");
  //   errorCells.forEach((errorCell) => {
  //     const cell = errorCell as HTMLElement;
  //     cell.style.backgroundColor =
  //       this.props.styleOverrides.global.warningColor;
  //   });
  // }

  componentDidUpdate(prevProps: ITableWrapperFullProps) {
    const isHiddenRowsEqual = isEqual(
      prevProps.hiddenRows,
      this.props.hiddenRows
    );

    const isSearchResultsEqual = isEqual(
      prevProps.searchResults,
      this.props.searchResults
    );

    const isTableMessagesEqual = isEqual(
      prevProps.tableMessages,
      this.props.tableMessages
    );

    if (isTableMessagesEqual && isHiddenRowsEqual && isSearchResultsEqual)
      return;

    this._refreshTippys();
    // this.paintErrorCells();
  }

  componentDidMount() {
    // this.paintErrorCells();

    if (
      this.props.selectedCell &&
      this.hotTableComponentRef !== null &&
      this.hotTableComponentRef.current?.hotInstance !== null
    ) {
      // eslint-disable-next-line no-unused-expressions
      this.hotTableComponentRef?.current?.hotInstance.selectCell(
        this.props.selectedCell[0],
        this.props.selectedCell[1]
      );
    }
  }

  _refreshTippys = () => {
    const visibleRows = this.getVisibleRows();
    if (visibleRows === null) return;

    this.tippyManager.addColumnHeaderTippys(this.props.mappedSpecs);
    this.tippyManager.updateTippys(this.props.tableMessages, visibleRows);
  };

  getVisibleRows = (): number[] | null => {
    const ref = this.hotTableComponentRef.current;
    if (!(ref && ref.hotInstance)) return null;
    const hot = ref.hotInstance;

    const firstRow = hot.getFirstPartiallyVisibleRow();
    const lastRow = hot.getLastPartiallyVisibleRow();
    if (firstRow === null || lastRow === null) return null;

    if (!this.props.hiddenRows) return range(firstRow, lastRow + 1);

    return range(firstRow, lastRow + 1).filter(
      (rowIdx) => !this.props.hiddenRows!.has(rowIdx)
    );
  };

  addFieldToColumns(
    fieldInstance: IField,
    developerField: IDeveloperField,
    columns: ColumnSettings[],
    labels: string[]
  ) {
    const column: ColumnSettings = {
      ...buildHotColumn(fieldInstance, this.hasTransformError),
      outputFieldKey: developerField.key,
      readOnly: developerField.readOnly,
      ...(developerField.readOnly
        ? { readOnlyCellClassName: "is-readOnly" }
        : {}),
    };

    if (developerField.manyToOne) {
      const colCount = this.manyToOneCellMap.get(developerField.key) ?? 0;
      const label =
        colCount === 0
          ? developerField.label
          : `${developerField.label} (${colCount + 1})`;
      this.manyToOneCellMap.set(developerField.key, colCount + 1);
      labels.push(label);
    } else {
      labels.push(developerField.label);
    }
    columns.push(column);

    return {
      columns,
      labels,
    };
  }

  hasTransformError = (cellKey: string): boolean => {
    return this.props.transformErrorCells.has(cellKey);
  };

  private contextMenuOptions(): ContextMenuOptions {
    let menuOptions: PredefinedMenuItemKey[] = [
      "row_above",
      "row_below",
      "---------",
      "remove_row",
      "---------",
      "copy",
      "cut",
    ];

    const onlyRemoveOptions: PredefinedMenuItemKey[] = [
      "remove_row",
      "---------",
      "copy",
      "cut",
    ];

    const onlyAddOptions: PredefinedMenuItemKey[] = [
      "row_above",
      "row_below",
      "---------",
      "copy",
    ];

    const noAddOrRemoveOptions: PredefinedMenuItemKey[] = ["copy"];

    if (!this.props.allowAddingRows && !this.props.allowRemovingRows) {
      menuOptions = noAddOrRemoveOptions;
    } else if (!this.props.allowAddingRows) {
      menuOptions = onlyRemoveOptions;
    } else if (!this.props.allowRemovingRows) {
      menuOptions = onlyAddOptions;
    }
    const contextMenuOptions: ContextMenuOptions = {
      items: menuOptions.reduce((itemsObj, menuItemKey, idx) => {
        let key: string;
        let label: string;
        if (menuItemKey === "---------") {
          // spacer has to start with 'sp' and be unique
          key = `sp${idx}`;
          label = menuItemKey;
        } else {
          key = menuItemKey;
          label = this.props.t(
            `dataReviewModal.tableMenuActions.${menuItemKey}`
          );
        }

        return {
          ...itemsObj,
          [key]: { name: label },
        };
      }, {}),
    };
    return contextMenuOptions;
  }

  render() {
    const hiddenColumns: Set<number> = new Set<number>();
    // Header labels for table
    let labels: string[] = [];
    // Column types for table
    let columns: ColumnSettings[] = [];

    this.manyToOneCellMap = new Map();

    if (this.props.v1ManualInput) {
      this.props.matchableFieldSpecs.forEach((fieldSpec) => {
        const returnObject = this.addFieldToColumns(
          fieldFromDeveloperField(fieldSpec, undefined),
          fieldSpec,
          columns,
          labels
        );
        columns = returnObject.columns;
        labels = returnObject.labels;
      });
    } else {
      const inputFileColumnArray = Array.from(
        Array(selectRawRowWidth(this.props.mappedSpecs)).keys()
      );

      inputFileColumnArray.forEach((colIndex: number) => {
        const fieldSpec = this.props.mappedSpecs.get(colIndex);

        if (!fieldSpec || fieldSpec.hidden) {
          hiddenColumns.add(colIndex);
          // Insert hidden column in both `labels` and `columns` arrays
          labels.splice(colIndex, 0, "HIDDEN_COLUMN");
          columns.splice(colIndex, 0, {
            type: "text",
            outputFieldKey: "HIDDEN_COLUMN",
          });
        } else {
          const returnObject = this.addFieldToColumns(
            this.props.fieldInstances.get(colIndex)!,
            fieldSpec,
            columns,
            labels
          );
          columns = returnObject.columns;
          labels = returnObject.labels;
        }
      });
    }

    const cells = (row: number, column: number) => {
      const cellProperties: ICellProperties = {};

      const key = `${row},${column}`;

      const levels = new Set<string>([]);

      if (this.props.tableMessages?.get(row)?.has(column)) {
        const cellMessages: ITableMessageInternal[] = this.props.tableMessages
          .get(row)!
          .get(column)!;
        cellMessages.forEach((cellMessage: ITableMessageInternal) => {
          levels.add(cellMessage.level);
        });

        const tippyClassName = `tippy-${row}-${column}`;
        if (levels.has("error")) {
          cellProperties.className = `error-cell ${tippyClassName}`;
        } else if (levels.has("warning")) {
          cellProperties.className = `warning-cell ${tippyClassName}`;
        } else {
          cellProperties.className = `info-cell ${tippyClassName}`;
        }
      } else {
        cellProperties.className = `normal-cell`;
      }

      if (this.props.searchResults?.has(key)) {
        cellProperties.className += " search-cell";
      }

      return cellProperties;
    };

    const colHeaders = (columnIndex: number, shouldExpandError: boolean) => {
      const fieldSpec = this.props.mappedSpecs.get(columnIndex);
      if (!fieldSpec) return labels[columnIndex];

      const errorsInColumn = this.props.colErrors.has(columnIndex)
        ? this.props.colErrors.get(columnIndex)!.length
        : 0;

      const tippyClassName = `tippy-column-header-${columnIndex}`;

      const infoIcon = fieldSpec.description ? (
        <span className={`ml-[2px] mr-[2px] ${tippyClassName}`}>
          <InfoIcon className="w-4 h-4 text-ice-300" />
        </span>
      ) : null;

      const fieldRequired = this.props.requiredFieldKeys.has(fieldSpec.key);

      const Header = () => (
        <>
          <span className="flex items-center gap-1 text-left">
            <span
              style={
                this.props.headerFontWeight
                  ? { fontWeight: this.props.headerFontWeight }
                  : undefined
              }
            >
              {labels[columnIndex]}
              {fieldRequired ? <sup>*</sup> : ""}
            </span>
            {infoIcon}
          </span>
          {errorsInColumn > 0 && (
            <span className="relative">
              <HeaderErrorFilter
                errorsInColumn={errorsInColumn}
                columnIndex={columnIndex}
                currentFilter={this.props.currentFilter}
                shouldExpandError={shouldExpandError}
              />
            </span>
          )}
        </>
      );

      return renderToString(<Header />);
    };

    const debouncedTippyRefresh = debounce(this._refreshTippys, 100);

    // Handsontable might expect a final row of nulls,
    // we can add this here to prevent to keep our state free
    // from unneeded blank rows
    let tableData = this.props.tableData;
    if (
      this.props.autoAddRow &&
      (this.props.tableData.at(-1) ?? []).every((e) => e !== null)
    ) {
      tableData = [
        ...tableData,
        Array.from(
          Array(selectRawRowWidth(this.props.mappedSpecs)),
          () => null
        ),
      ];
    }

    return (
      // https://jsfiddle.net/748e29zx/
      <HotTable
        ref={this.hotTableComponentRef}
        id={this.id}
        data={tableData}
        cells={cells}
        stretchH="all"
        columns={columns}
        manualColumnResize
        rowHeaders
        headerClassName={twMerge(`
          [&>span]:!flex
          [&>span]:!items-center
          [&>span]:!gap-4
          [&>span]:!justify-between
          [&>span]:!h-full
          [&>span]:!leading-[0]
          h-full
          !px-1
          !py-0
        `)}
        autoColumnSize={{
          samplingRatio: 23,
        }}
        hiddenColumns={{
          columns: Array.from(hiddenColumns),
          copyPasteEnabled: false,
        }}
        hiddenRows={{
          rows: this.props.hiddenRows ? Array.from(this.props.hiddenRows) : [],
          copyPasteEnabled: false,
        }}
        width="100%"
        height={this.props.tableHeight ? this.props.tableHeight : "auto"}
        licenseKey={HANDSONTABLE_LICENSE_KEY}
        minSpareRows={this.props.autoAddRow ? 1 : 0}
        afterSetDataAtCell={(changes: any[]) =>
          // Discussed with Marcin (CTO of Handsontable)
          // handsontable throws a weird error if this is not async
          Promise.resolve().then(() =>
            this.props.onCellDataChange(
              this.props.tableData,
              changes,
              this._getTableSelectedCell()
            )
          )
        }
        colHeaders={(columnIndex: number) => colHeaders(columnIndex, false)}
        afterInit={() => this._refreshTippys()}
        afterRender={() => debouncedTippyRefresh()}
        afterScrollVertically={() => debouncedTippyRefresh()}
        afterScrollHorizontally={() => debouncedTippyRefresh()}
        allowRemoveRow={this.props.allowRemovingRows}
        afterRemoveRow={(_index, _amount, rowIndexes) =>
          // handsontable throws a weird error if this is not async
          Promise.resolve().then(() => {
            this.props.onRemoveRow && this.props.onRemoveRow(rowIndexes);
          })
        }
        afterCreateRow={(index, amount, source) => {
          // afterCreateRow is called for many HOT actions. For our data handling
          // we only care about rows that are added to the middle of the data using the
          // context menu (for now). We ignore these other sources because they cause
          // us to add duplicate empty rows
          const ignoredSources = ["CopyPaste.paste", "auto"];
          if (source && ignoredSources.includes(source)) return;

          // handsontable throws a weird error if this is not async
          Promise.resolve().then(() => {
            // prepare an array of newly created row indexes to standardize the interface between the table and
            // the component that uses it
            const newIndexes = Array.from(Array(amount)).map(
              (_e, idx) => idx + index
            );
            this.props.onCreateRow && this.props.onCreateRow(newIndexes);
          });
        }}
        beforeChange={(changes) => {
          // If a user drags a cell's value down or across, HandsOnTable autofills the
          // value into the cells it has been dragged across.
          // If we are currently hiding rows e.g. 'show only rows with errors'
          // we don't want to autofill values into hidden rows
          // Ditto if the user selects an area and deletes the cell values - we don't want
          // to delete values from hidden rows, which handsontable will otherwise do
          if (this.props.hiddenRows === undefined) {
            return;
          }

          // we can prevent changes from being made by removing the 'change' rows from the array
          for (let i = changes.length - 1; i >= 0; i--) {
            const rowIdx = changes[i]![0];
            if (this.props.hiddenRows.has(rowIdx)) {
              changes.splice(i, 1);
            }
          }
        }}
        beforeRemoveRow={(_idx, _amt, rows) => {
          if (this.props.hiddenRows === undefined) {
            return;
          }

          for (let i = rows.length - 1; i >= 0; i--) {
            const rowIdx = rows[i];
            if (this.props.hiddenRows.has(rowIdx)) {
              rows.splice(i, 1);
            }
          }
        }}
        beforeOnCellMouseDown={(event, coords) => {
          // Check if target is the filter-error button
          const target = event.target as HTMLElement;
          if (target.dataset.errorFilter !== undefined) {
            stopImmediatePropagation(event as Event);
            if (typeof this.props.onFilterColumn !== "undefined") {
              this.props.onFilterColumn(
                this.props.currentFilter === coords.col ? null : coords.col
              );
            }
          }
        }}
        contextMenu={this.contextMenuOptions()}
        maxRows={
          this.props.allowAddingRows ? Infinity : this.props.tableData.length
        }
      />
    );
  }
}

export default reduxConnector(withTranslation()(TableWrapper));

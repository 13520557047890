import { z } from "zod";
import { ValidatorSchema } from "./validators";
import { DeveloperFieldDiscriminatedUnion } from "./DeveloperFieldDiscriminatedUnion";
import { phoneSupportedCountryCodes } from "../../util/countries";

export const BaseFieldSchema = z.object({
  label: z.string(),
  key: z.string(),
  description: z.string().optional(),
  alternateMatches: z.array(z.string()).optional(),
  validators: z.array(ValidatorSchema).default([]),
  invalidValueMessage: z.string().optional(),
  readOnly: z.boolean().default(false),
  hidden: z.boolean().default(false),
  requireMapping: z.boolean().optional(),
  manyToOne: z.boolean().optional(),
});

export const StringFieldSchema = BaseFieldSchema.extend({
  type: z
    .union([
      z.literal("string"),
      z.tuple([z.literal("string"), z.record(z.never())]),
    ])
    .optional(),
});
export type IStringField = z.input<typeof StringFieldSchema>;

export const CheckboxFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("checkbox"),
    z.tuple([z.literal("checkbox"), z.record(z.never())]),
  ]),
});
export type ICheckboxField = z.input<typeof CheckboxFieldSchema>;

export const EmailFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("email"),
    z.tuple([z.literal("email"), z.record(z.never())]),
  ]),
});
export type IEmailField = z.input<typeof EmailFieldSchema>;

export const SelectOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  alternateMatches: z.array(z.string()).optional(),
});
export type IDeveloperSelectOption = z.input<typeof SelectOptionSchema>;

const SelectOptsSchema = z
  .object({
    allowCustom: z.boolean(),
    exactMatchOnly: z.boolean(),
  })
  .partial();

export const SelectFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("select"),
    z.tuple([z.literal("select"), SelectOptsSchema]),
  ]),
  selectOptions: z.array(SelectOptionSchema),
});
export type ISelectField = z.input<typeof SelectFieldSchema>;

export const numberPresets = [
  "default",
  "percent",
  "percent_0",
  "percent_1",
  "percent_2",
  "percent_3",
  "percent_4",
  "decimal_0",
  "decimal_1",
  "decimal_2",
  "decimal_3",
  "decimal_4",
  "integer",
  "plain",
  "usd",
  "usd_accounting",
  "eur",
  "gbp",
] as const;

// converted from the numbro.Format type
const NumbroFormatSchema = z
  .object({
    output: z.enum([
      "currency",
      "percent",
      "byte",
      "time",
      "ordinal",
      "number",
    ]),
    base: z.enum(["decimal", "binary", "general"]),
    characteristic: z.number(),
    prefix: z.string(),
    postfix: z.string(),
    forceAverage: z.enum(["trillion", "billion", "million", "thousand"]),
    average: z.boolean(),
    currencyPosition: z.enum(["prefix", "infix", "postfix"]),
    currencySymbol: z.string(),
    totalLength: z.number(),
    mantissa: z.number(),
    optionalMantissa: z.boolean(),
    trimMantissa: z.boolean(),
    optionalCharacteristic: z.boolean(),
    thousandSeparated: z.boolean(),
    abbreviations: z.object({
      thousand: z.string(),
      million: z.string(),
      billion: z.string(),
      trillion: z.string(),
    }),
    negative: z.enum(["sign", "parenthesis"]),
    forceSign: z.boolean(),
    spaceSeparated: z.boolean(),
    spaceSeparatedCurrency: z.boolean(),
    spaceSeparatedAbbreviation: z.boolean(),
    exponential: z.boolean(),
    prefixSymbol: z.boolean(),
    lowPrecision: z.boolean(),
    roundingFunction: z.function().args(z.number()).returns(z.number()),
  })
  .deepPartial();

const NumberOptsSchema = z.object({
  preset: z.enum(numberPresets).optional(),
  round: z.number().int().optional(),
  displayFormat: NumbroFormatSchema.optional(),
  outputFormat: NumbroFormatSchema.optional(),
  min: z.number().optional(),
  max: z.number().optional(),
});

export const NumberFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("number"),
    z.tuple([
      z.literal("number"),
      z.union([z.enum(numberPresets), NumberOptsSchema]),
    ]),
  ]),
});
export type INumberField = z.input<typeof NumberFieldSchema>;

const DateTimeOptsSchema = z
  .object({
    displayFormat: z.string(),
    outputFormat: z.string(),
    locale: z.string(),
    withSeconds: z.boolean(),
  })
  .partial();

export const DateTimeFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.enum(["date", "time", "datetime"]),
    z.tuple([z.enum(["date", "time", "datetime"]), DateTimeOptsSchema]),
  ]),
});
export type IDateTimeField = z.input<typeof DateTimeFieldSchema>;

export const PhoneNumberFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("phone-number"),
    z.tuple([
      z.literal("phone-number"),
      z
        .object({
          country: phoneSupportedCountryCodes,
          format: z.enum(["international", "national", "both"]),
          outputFormatted: z.boolean(),
        })
        .partial()
        .refine(
          (opts) => {
            if (
              (opts.format === "national" || opts.format === "both") &&
              !opts.country
            ) {
              return false;
            }
            return true;
          },
          {
            message:
              "country must be specified if format is 'national' or 'both'",
          }
        ),
    ]),
  ]),
});

export type IPhoneNumberField = z.input<typeof PhoneNumberFieldSchema>;

export const UrlFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("url"),
    z.tuple([
      z.literal("url"),
      z
        .object({
          acceptedProtocols: z.array(z.string()),
          acceptedDomains: z.array(z.string()),
        })
        .partial(),
    ]),
  ]),
});

export type IUrlField = z.input<typeof UrlFieldSchema>;

export const USZipCodeFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("us-zip-code"),
    z.tuple([
      z.literal("us-zip-code"),
      z
        .object({
          format: z.enum(["5-digit", "9-digit"]),
          outputDash: z.boolean(),
        })
        .partial(),
    ]),
  ]),
});

export type IUSZipCodeField = z.input<typeof USZipCodeFieldSchema>;

export const SSNFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("ssn"),
    z.tuple([
      z.literal("ssn"),
      z.object({
        outputDash: z.boolean().optional(),
      }),
    ]),
  ]),
});

export type ISSNField = z.input<typeof SSNFieldSchema>;

export const CountryFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("country"),
    z.tuple([
      z.literal("country"),
      z
        .object({
          format: z.enum(["2-letter", "3-letter"]),
        })
        .partial(),
    ]),
  ]),
});

export type ICountryField = z.input<typeof CountryFieldSchema>;

export const DomainFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("domain"),
    z.tuple([
      z.literal("domain"),
      z.object({
        allowSubdomains: z.boolean().optional(),
      }),
    ]),
  ]),
});

export type IDomainField = z.input<typeof DomainFieldSchema>;

export const UUIDFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("uuid"),
    z.tuple([
      z.literal("uuid"),
      z.object({
        version: z.number().optional(),
      }),
    ]),
  ]),
});

export type IUUIDField = z.input<typeof UUIDFieldSchema>;

export const USStateTerritoryFieldSchema = BaseFieldSchema.extend({
  type: z.union([
    z.literal("us-state-territory"),
    z.tuple([z.literal("us-state-territory"), z.record(z.never())]),
  ]),
});

export type IUSStateTerritoryField = z.input<
  typeof USStateTerritoryFieldSchema
>;

export const DeveloperFieldSchema = DeveloperFieldDiscriminatedUnion.create({
  string: StringFieldSchema,
  checkbox: CheckboxFieldSchema,
  email: EmailFieldSchema,
  select: SelectFieldSchema,
  number: NumberFieldSchema,
  date: DateTimeFieldSchema,
  domain: DomainFieldSchema,
  datetime: DateTimeFieldSchema,
  "phone-number": PhoneNumberFieldSchema,
  url: UrlFieldSchema,
  ssn: SSNFieldSchema,
  uuid: UUIDFieldSchema,
  time: DateTimeFieldSchema,
  "us-zip-code": USZipCodeFieldSchema,
  "us-state-territory": USStateTerritoryFieldSchema,
  country: CountryFieldSchema,
}).refine((field) => !(field.hidden && field.validators.length > 0), {
  message: "Hidden fields cannot have validations",
});

export type IDeveloperField = z.input<typeof DeveloperFieldSchema>;

export const DeveloperFieldsSchema = z
  .array(DeveloperFieldSchema)
  .superRefine((fields, ctx) => {
    const seenKeys = new Set<string>();
    const duplicateKeys = new Set<string>();

    for (const { key } of fields) {
      if (seenKeys.has(key)) {
        duplicateKeys.add(key);
      } else {
        seenKeys.add(key);
      }
    }

    if (duplicateKeys.size === 0) return;

    const keyString = Array.from(duplicateKeys).join(", ");
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Fields with duplicate key: ${keyString}`,
      params: {
        duplicateKeys: [...duplicateKeys],
      },
    });
  });

import { BaseDateTimeField, DateTimeOpts, REF_TIME } from "./base";
import type { DateTime, ToISODateOptions } from "luxon";

export class TimeField extends BaseDateTimeField {
  static defaultDisplayFormat = "t";
  static defaultOutputFormat = "[ISO]";
  static defaultInvalidValueMessage = "Invalid time";
  static isoFormatFn = (value: DateTime, opts: ToISODateOptions) =>
    value.toISOTime(opts);

  type = "time" as const;

  constructor(opts: DateTimeOpts) {
    super(opts);

    if (opts.withSeconds && opts.displayFormat == null) {
      // use a default format with seconds if withSeconds is true
      this.displayFormat = "tt";
      this.invalidValueMessage =
        opts.invalidValueMessage ??
        `Invalid format. Expecting: "${this.getDisplayValue(
          REF_TIME.toISO(this.toIsoOpts)
        )}"`;
    }
  }
}

import { WrapAsync } from "../interfaces";

export function wrapAsync<T extends (...args: any) => any>(
  fn: T
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  return async (...args: any[]): Promise<any> => {
    try {
      const result = fn(...args);
      return result instanceof Promise ? result : Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

// Takes an object of functions and returns the functions as async
export function wrapAsyncObj<T extends Record<string, (...args: any) => any>>(
  methods: T
): WrapAsync<T> {
  return Object.fromEntries(
    Object.entries(methods).map(([key, val]) => {
      return [key, wrapAsync(val)];
    })
  ) as WrapAsync<T>;
}

import { NumberOpts } from ".";
import numbro from "numbro";
import { numberPresets } from "../../helpers/schemas/fields";

export type NumberPresetName = typeof numberPresets[number];

const currencyDefaults: numbro.Format = {
  mantissa: 2,
  thousandSeparated: true,
  output: "currency",
  average: false,
  spaceSeparatedCurrency: true,
};

const presets: Record<NumberPresetName, NumberOpts> = {
  default: {
    displayFormat: {
      thousandSeparated: true,
    },
  },
  percent: {
    displayFormat: {
      output: "percent",
    },
  },
  ...(Object.fromEntries(
    [0, 1, 2, 3, 4].map((mantissa): [string, NumberOpts] => [
      `percent_${mantissa}`,
      { round: mantissa + 2, displayFormat: { output: "percent", mantissa } },
    ])
  ) as Record<
    "percent_0" | "percent_1" | "percent_2" | "percent_3" | "percent_4",
    NumberOpts
  >),
  ...(Object.fromEntries(
    [0, 1, 2, 3, 4].map((mantissa): [string, NumberOpts] => [
      `decimal_${mantissa}`,
      { round: mantissa, displayFormat: { thousandSeparated: true, mantissa } },
    ])
  ) as Record<
    "decimal_0" | "decimal_1" | "decimal_2" | "decimal_3" | "decimal_4",
    NumberOpts
  >),
  plain: {},
  integer: {
    // same as decimal_0
    round: 0,
    displayFormat: { thousandSeparated: true, mantissa: 0 },
  },
  usd: {
    round: 2,
    displayFormat: {
      ...currencyDefaults,
      currencyPosition: "prefix",
      currencySymbol: "$",
    },
  },
  usd_accounting: {
    round: 2,
    displayFormat: {
      ...currencyDefaults,
      currencyPosition: "prefix",
      currencySymbol: "$",
      negative: "parenthesis",
    },
  },
  eur: {
    round: 2,
    displayFormat: {
      ...currencyDefaults,
      currencyPosition: "postfix",
      currencySymbol: "€",
    },
  },
  gbp: {
    round: 2,
    displayFormat: {
      ...currencyDefaults,
      currencyPosition: "prefix",
      currencySymbol: "£",
    },
  },
};

export default presets;

import * as React from "react";
import { DefaultTFuncReturn } from "i18next";
import { twMerge } from "tailwind-merge";

import "../App.css";
import { selectGlobalStyle } from "../store/reducers/settings";
import { withTranslation, WithTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch, useStyles } from "../store/hooks";
import { omit } from "lodash";
import { IDromoError } from "../store/reducers/errors";
import { Button } from "./commonComponents/Button";
import Text from "./commonComponents/Text";
import { ReactComponent as ErrorIcon } from "../assets/error.svg";
import { useParentConnectionContext } from "./ParentConnectionContext";
import { handleCancelModal } from "../thunks/parent_connection_handlers";
import Modal from "./commonComponents/Modal";

interface IAlertModalProps {
  show: boolean;
  children?: React.ReactNode;
  setShow?: (show: boolean) => void;
  message: DefaultTFuncReturn | { __html: string };
  caption?: DefaultTFuncReturn;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonDescriptionText: string;
  secondaryButtonDescriptionText: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  showSecondaryButton: boolean;
  errorModal?: boolean;
  "data-cy"?: string;
}

export const AlertModal = (props: IAlertModalProps): React.ReactElement => {
  const modalStyle = useAppSelector(selectGlobalStyle);
  const modalBodyStyle = useStyles((styles) => ({
    backgroundColor: styles.global.backgroundColor,
  }));
  const message = props.message;

  return (
    <Modal
      show={props.show}
      onClose={() => props.setShow?.(false)}
      centered
      closeButton={false}
      style={modalStyle}
      className="alertModal"
      data-cy={props["data-cy"]}
    >
      <div
        className="shadow rounded-lg p-6"
        data-cy="modal-content"
        style={modalBodyStyle}
      >
        <section>
          {props.errorModal && <ErrorIcon className="w-14 h-14 mb-2" />}

          {typeof message === "object" && message !== null ? (
            <div className="developer-html" dangerouslySetInnerHTML={message} />
          ) : (
            <Text type="display" className="">
              {props.message}
            </Text>
          )}

          {props.caption !== undefined && (
            <Text type="body">{props.caption}</Text>
          )}
        </section>
        <div className="grid grid-cols-2 gap-2 mt-6">
          {props.showSecondaryButton ? (
            <Button
              onClick={() => {
                if (props.onSecondaryButtonClick !== undefined) {
                  props.onSecondaryButtonClick();
                }
              }}
              theme="secondary"
              data-cy={`${props["data-cy"]}-secondary-button`}
            >
              {props.secondaryButtonText}
            </Button>
          ) : null}
          <Button
            className={twMerge(!props.showSecondaryButton && "col-span-2")}
            onClick={props.onPrimaryButtonClick}
            data-cy={`${props["data-cy"]}-primary-button`}
          >
            {props.primaryButtonText}
          </Button>
        </div>
        {props.children}
      </div>
    </Modal>
  );
};

type ITranslatedAlertModalProps = Omit<
  IAlertModalProps,
  | "message"
  | "caption"
  | "primaryButtonText"
  | "secondaryButtonText"
  | "primaryButtonDescriptionText"
  | "secondaryButtonDescriptionText"
> &
  WithTranslation & {
    i18nMessage: string;
    i18nMessageValues?: Record<string, string>;
    i18nCaption?: string;
    i18nPrimaryButtonText: string;
    i18nSecondaryButtonText?: string;
    i18nPrimaryButtonDescriptionText?: string;
    i18nSecondaryButtonDescriptionText?: string;
  };

export const TranslatedAlertModal = withTranslation()(
  (props: ITranslatedAlertModalProps) => {
    const alertProps: IAlertModalProps = {
      ...omit(props, [
        "i18nMessage",
        "i18nMessageValues",
        "i18nCaption",
        "i18nPrimaryButtonText",
        "i18nSecondaryButtonText",
        "i18nPrimaryButtonDescriptionText",
        "i18nSecondaryButtonDescriptionText",
      ]),
      message: props.t(props.i18nMessage, props.i18nMessageValues ?? {}) ?? "",
      caption: props.i18nCaption && props.t(props.i18nCaption),
      primaryButtonText: props.t(props.i18nPrimaryButtonText),
      secondaryButtonText: props.i18nSecondaryButtonText
        ? props.t(props.i18nSecondaryButtonText)
        : "",
      primaryButtonDescriptionText: props.i18nPrimaryButtonDescriptionText
        ? props.t(props.i18nPrimaryButtonDescriptionText)
        : "",
      secondaryButtonDescriptionText: props.i18nSecondaryButtonDescriptionText
        ? props.t(props.i18nSecondaryButtonDescriptionText)
        : "",
    };
    return <AlertModal {...alertProps}></AlertModal>;
  }
);

interface IErrorModalProps {
  show: boolean;
  onClose: () => void;
  "data-cy"?: string;
  children?: React.ReactNode;
}

export const ErrorModal = (props: IErrorModalProps) => {
  const error = useAppSelector((state) => state.errors.errors[0]) as
    | IDromoError
    | undefined;

  if (!error) return null;

  if ("message" in error) {
    return (
      <AlertModal
        show={props.show}
        message={error.message}
        primaryButtonText="Close"
        secondaryButtonText=""
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={props.onClose}
        showSecondaryButton={false}
        errorModal={true}
        data-cy={props["data-cy"]}
      >
        {props.children}
      </AlertModal>
    );
  } else {
    return (
      <TranslatedAlertModal
        show={props.show}
        i18nMessage={error.messageKey}
        i18nMessageValues={error.messageValues}
        i18nPrimaryButtonText="common.close"
        onPrimaryButtonClick={props.onClose}
        showSecondaryButton={false}
        errorModal={true}
        data-cy={props["data-cy"]}
      >
        {props.children}
      </TranslatedAlertModal>
    );
  }
};

interface FatalErrorModalProps {
  eventId?: string;
}

export const FatalErrorModal: React.FC<FatalErrorModalProps> = ({
  eventId,
}) => {
  const dispatch = useAppDispatch();
  const connection = useParentConnectionContext();

  const onClose = () => dispatch(handleCancelModal(connection));

  return (
    <ErrorModal show onClose={onClose} data-cy="fatal-error-alert">
      {eventId && (
        <>
          <br />
          <Text type="medium">Error ID: {eventId}</Text>
        </>
      )}
    </ErrorModal>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetState } from "./coredata";

export const STEPS = [
  "UPLOAD",
  "HEADER_SELECT",
  "COLUMN_MATCH",
  "SELECT_MATCH",
  "REVIEW",
] as const;

export type Step = typeof STEPS[number];

export interface IStepsReducerState {
  currentStep: Step;
  stepStack: Step[];
  initializedSteps: Set<Step>;
}

export const initialState: IStepsReducerState = {
  currentStep: STEPS[0],
  stepStack: [],
  initializedSteps: new Set(),
};

const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    startFromStep: (state, action: PayloadAction<Step>) => {
      state.stepStack = [];
      state.currentStep = action.payload;
    },

    pushStep: (state, action: PayloadAction<Step>) => {
      state.stepStack.push(action.payload);
    },

    goToStep: (state, action: PayloadAction<Step>) => {
      state.stepStack.push(state.currentStep);
      state.currentStep = action.payload;
    },

    goBackOneStep: (state) => {
      const previous = state.stepStack.pop();
      if (!previous) return;
      state.currentStep = previous;
    },

    shiftStepStack: (state) => {
      state.stepStack.shift();
    },

    popStepStack: (state) => {
      state.stepStack.pop();
    },

    setStepInitialized: (state, action: PayloadAction<Step>) => {
      state.initializedSteps.add(action.payload);
    },

    setStepUninitialized: (state, action: PayloadAction<Step>) => {
      state.initializedSteps.delete(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
  },
});

export const {
  startFromStep,
  pushStep,
  goToStep,
  goBackOneStep,
  shiftStepStack,
  popStepStack,
  setStepInitialized,
  setStepUninitialized,
} = stepsSlice.actions;

export default stepsSlice.reducer;

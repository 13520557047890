import React from "react";
import { twMerge } from "tailwind-merge";
import { OptionProps, components } from "react-select";
import Text from "../Text";
import { useSelectContext } from "./context";
import { getMatchPill } from "../Pill";
import { truncate } from ".";
import { MATCH_TYPES } from "../../../interfaces";

export const Option = (props: OptionProps<any>) => {
  const optionClasses = `
    !text-sm
    relative
    cursor-pointer
    select-none
    py-2
    px-3
    text-ice-500
    hover:bg-ice-50
    hover:text-ice-900
    ui-active:bg-ice-50
    ui-active:text-ice-900
    flex
    gap-2
    items-center
    w-full
  `;
  const { onCreate, onCloseMenu } = useSelectContext();
  const option = props.data;
  const clickHandler =
    props.data.custom === true
      ? () => {
          onCreate?.(option.key as string);
          onCloseMenu();
        }
      : props.innerProps.onClick;

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: clickHandler as any,
      }}
      className={twMerge(
        props.className,
        optionClasses,
        props.isSelected && "!bg-white hover:!bg-ice-50"
      )}
    >
      <Text
        type="body"
        as="span"
        className={twMerge(
          "flex gap-2 justify-between items-center w-full",
          props.isSelected && "font-semibold"
        )}
        data-cy={props.data.custom === true ? "add-custom-field" : undefined}
      >
        {truncate(option?.label, 50)}
        {option?.matchType?.length > 0 &&
          option.matchType !== MATCH_TYPES.IGNORED &&
          getMatchPill(option.matchType, true)}
      </Text>
    </components.Option>
  );
};

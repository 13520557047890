import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import Text from "./Text";

type IInputProps = {
  autoComplete?: string;
  className?: string;
  id: string;
  "data-cy"?: string;
  inputClassName?: string;
  label?: string;
  labelClassName?: string;
  name: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  value: string;
  step?: string | number;
  min?: string | number;
  description?: string;
  tooltip?: string;
  autoSelectOnClick?: boolean;
  error?: string;
  errorClassName?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const Input = forwardRef(
  (
    {
      autoComplete,
      className,
      id,
      inputClassName,
      label,
      labelClassName,
      name,
      onChange,
      placeholder,
      required = false,
      type = "text",
      disabled,
      value,
      step,
      min,
      description,
      autoSelectOnClick,
      error,
      errorClassName,
      "data-cy": dataCy,
      ...props
    }: IInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const inputClasses = getInputClasses(inputClassName, error);

    return (
      <div className={twMerge("grid", className)}>
        {(label || placeholder) && (
          <div className="flex gap-2 items-center">
            <Text
              type="body"
              as="label"
              htmlFor={id}
              className={twMerge(
                "mb-1",
                label ? "" : "sr-only",
                description && "mb-1",
                labelClassName
              )}
            >
              {label || placeholder}
            </Text>
          </div>
        )}
        {description && (
          <Text type="body" className="mb-2">
            {description}
          </Text>
        )}
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          className={inputClasses}
          placeholder={placeholder}
          onChange={(event) => onChange(event.currentTarget.value)}
          value={value}
          step={step}
          min={min}
          disabled={disabled}
          onClick={(e) => {
            if (autoSelectOnClick) {
              e.currentTarget.select();
            }
          }}
          ref={ref}
          data-cy={dataCy}
          {...props}
        />
        {error && (
          <Text
            type="body"
            className={twMerge("mt-2 text-red-600", errorClassName)}
          >
            {error}
          </Text>
        )}
      </div>
    );
  }
);

export default Input;

export function getInputClasses(inputClassName?: string, error?: string) {
  return twMerge(
    `
    appearance-none
    rounded-none
    relative
    block
    w-full
    !px-3
    !py-2
    !border
    border-ice-300
    placeholder-ice-500
    text-ice-900
    sm:!text-sm
    bg-white
    !rounded-md
  `,
    error &&
      "border-red-500 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500",
    inputClassName
  );
}

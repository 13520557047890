import * as Sentry from "@sentry/browser";
import { Transition } from "@headlessui/react";
import React, {
  FormEventHandler,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Spinner from "../commonComponents/Spinner";
import { flushSync } from "react-dom";

import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as RegenerateIcon } from "../../assets/regenerate.svg";
import { useAppDispatch, useStyles } from "../../store/hooks";
import { FullDataWithMeta } from "../../thunks/data_actions";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import Toggle from "../commonComponents/Toggle";
import ExamplePrompts from "./ExamplePrompts";
import PreviewTable from "./PreviewTable";
import { Alert } from "../commonComponents/Alert";
import { useTranslation } from "react-i18next";
import { getColorLuminance, shadeColor } from "../../util/colors";
import { StarsIcon } from "../StarsIcon";
import HelpText from "../HelpText";
import {
  getUserFunction,
  updateUserFunction,
  transformDataWithUserFunction,
  UserFunctionSuccess,
  TransformDataSuccess,
} from "../../thunks/user_functions";

interface UserFunctionModalProps {
  isOpen: boolean;
  onClose: () => void;
  fullData: FullDataWithMeta;
  onConfirmChanges: (result: TransformDataSuccess) => Promise<void>;
}

function UserFunctionModal({
  isOpen,
  onClose,
  fullData,
  onConfirmChanges,
}: UserFunctionModalProps) {
  const { t } = useTranslation();

  const [showPreview, setShowPreview] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userPrompt, setUserPrompt] = useState("");
  const [hideBefore, setHideBefore] = useState(false);
  const [userFunction, setUserFunction] = useState<
    UserFunctionSuccess | undefined
  >();
  const [transformResult, setTransformResult] =
    useState<TransformDataSuccess>();
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [isError, setIsError] = useState("");
  const inputRef = useRef<null | HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleClickOnInputArea = () => {
    inputRef.current?.focus();
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
  }, [isOpen]);

  const handleSubmitPrompt: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await dispatch(getUserFunction(userPrompt));

      if (response.success === false) {
        setIsError(response.message);
        setUserFunction(undefined);
        return;
      }

      setUserFunction(response);

      const result = await dispatch(
        transformDataWithUserFunction(
          fullData.map((val) => [...val]),
          response.response_object.code,
          response.id
        )
      );

      if (result.success === false) {
        setIsError(result.message);
        return;
      }

      setTransformResult(result);
      setShowPreview(true);
      setIsError("");
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra("data", {
          prompt: userPrompt,
        });
        Sentry.captureException(error);
      });

      setIsError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = async (rejectFunction: boolean) => {
    setIsError("");
    if (userFunction && rejectFunction) {
      dispatch(updateUserFunction(userFunction.id, "rejected"));
    }
    setIsLoading(false);
    setShowPreview(false);
    setUserPrompt("");
    setTransformResult(undefined);

    flushSync(() => {
      onClose();
    });
  };

  const hasDataChanges = () => {
    if (transformResult) {
      return (
        transformResult.changedColIndexes.size > 0 ||
        transformResult.changedRowIndexes.size > 0 ||
        transformResult.removedRowIndexes.size > 0
      );
    }
    return false;
  };

  const onlyRemovedRows = useMemo(
    () =>
      !!transformResult &&
      transformResult.changedColIndexes.size === 0 &&
      transformResult.changedRowIndexes.size === 0 &&
      transformResult.removedRowIndexes.size > 0,
    [transformResult]
  );

  const hasOnlyCellChanges = useMemo(
    () =>
      !!transformResult &&
      transformResult.changes.length > 0 &&
      transformResult.removedRowIndexes.size === 0,
    [transformResult]
  );

  useEffect(() => {
    setHideBefore(onlyRemovedRows);
  }, [onlyRemovedRows]);

  const handleConfirmChanges = async () => {
    if (userFunction) {
      dispatch(updateUserFunction(userFunction.id, "accepted"));
    }
    await onConfirmChanges(transformResult!);

    handleClose(false);
  };

  const style = useStyles((state) => ({
    backgroundColor: state.primaryButton.backgroundColor,
  }));

  const gradientPrimaryColor = (style && style.backgroundColor) || "#0062F5";
  const gradientSecondaryColor = shadeColor(
    gradientPrimaryColor,
    getColorLuminance(gradientPrimaryColor || "") > 0.2 ? -40 : 300
  );

  const getCellsChangedText = () => {
    if (!transformResult) return "";

    const numCellsChanged = transformResult.changes.length;
    const numRowsChanged = transformResult.changedRowIndexes.size;

    if (numCellsChanged === 1) {
      return t("userFunctionModal.oneCellChangedOneRow");
    } else if (numCellsChanged > 1 && numRowsChanged === 1) {
      return t("userFunctionModal.manyCellsChangedOneRow", {
        num_cells: numCellsChanged,
      });
    } else if (numCellsChanged > 1 && numRowsChanged > 1) {
      return t("userFunctionModal.manyCellsChangedManyRows", {
        num_cells: numCellsChanged,
        num_rows: numRowsChanged,
      });
    } else {
      return "";
    }
  };

  return (
    <Transition
      show={isOpen}
      className="fixed w-screen h-screen grid place-items-center !p-4 z-10"
      data-ui-state={
        isError ? "error" : showPreview ? "preview" : "not-preview"
      }
    >
      <Transition.Child
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="backdrop-blur-sm fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 z-10"
      />
      <Transition.Child
        enter="transition-all duration-300"
        enterFrom="opacity-0 -mt-4"
        enterTo="opacity-100 mt-0"
        leave="transition-all duration-300"
        leaveFrom="opacity-100 mt-0"
        leaveTo="opacity-0 mt-4"
        className="transition-all bg-white rounded-lg z-10 relative min-w-[700px] w-[max-content] max-w-[800px] ui-preview:max-w-[90%]"
        data-cy="transform-modal"
      >
        <button
          onClick={() => handleClose(true)}
          className="absolute top-8 right-4 -translate-y-1/2 !p-2 hover:bg-gray-100 rounded-lg ui-preview:scale-0 transition-all"
        >
          <CloseIcon className="w-5 h-5" />
        </button>

        <div className="overflow-hidden !pt-12 ui-preview:!pt-6 transition-all">
          <div className="grid transition-all duration-300 opacity-0 grid-rows-[0fr] ui-not-preview:opacity-100 ui-not-preview:grid-rows-[1fr]">
            <div className="overflow-hidden">
              <Text type="h1" className="text-center mb-4 ">
                {t("userFunctionModal.title")}
              </Text>
            </div>
          </div>

          <div>
            <div className="transition-all !px-12 ui-error:!px-6 ui-preview:!px-6">
              <div
                className="p-[2px] rounded-xl transition-all"
                style={{
                  background: isFocused
                    ? gradientPrimaryColor
                    : `linear-gradient(to right, ${gradientPrimaryColor} 0%, ${gradientSecondaryColor} 100%)`,
                }}
                onClick={handleClickOnInputArea}
              >
                <form
                  className="bg-white !p-1 rounded-[10px] flex gap-1 items-center"
                  onSubmit={handleSubmitPrompt}
                >
                  <StarsIcon color={gradientSecondaryColor || "currentColor"} />
                  <input
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={inputRef}
                    className="self-stretch grow focus:outline-none"
                    value={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.value)}
                    data-cy="transform-data-input"
                  />

                  {isLoading ? (
                    <div className="!px-4 !py-2">
                      <Spinner role="status" className="w-4 h-4" />
                    </div>
                  ) : showPreview ? (
                    <Button
                      type="submit"
                      theme="secondary"
                      className="text-base !px-4 !py-2 gap-1"
                    >
                      <RegenerateIcon className="w-4 h-4" />
                      {t("userFunctionModal.regenerate")}
                    </Button>
                  ) : (
                    <Button
                      theme="secondary"
                      className="text-base !px-4 !py-2"
                      type="submit"
                      data-cy="preview-button"
                    >
                      {t("userFunctionModal.preview")}
                    </Button>
                  )}
                </form>
              </div>
            </div>

            <div className="grid transition-all duration-300 opacity-0 grid-rows-[0fr] ui-preview:opacity-100 ui-preview:grid-rows-[1fr] ui-error:opacity-100 ui-error:grid-rows-[1fr]">
              <div className="overflow-hidden">
                <div className="flex flex-col">
                  {isError ? (
                    <div className="!px-6 py-8 transition-all">
                      <Alert
                        type="error"
                        className="text-center flex flex-col items-center w-full !py-12"
                      >
                        {isError}
                      </Alert>
                    </div>
                  ) : (
                    <>
                      <div className="!px-12 ui-preview:!px-6 transition-all flex items-center !mt-2 !mb-4">
                        {!onlyRemovedRows && (
                          <Text>{getCellsChangedText()}</Text>
                        )}
                        {transformResult &&
                          transformResult.removedRowIndexes.size !== 0 && (
                            <Text>
                              {!onlyRemovedRows && " - "}
                              {t("userFunctionModal.rowsRemoved", {
                                count: hasDataChanges()
                                  ? transformResult.removedRowIndexes.size
                                  : 0,
                              })}
                            </Text>
                          )}
                        <div className="flex !ml-auto gap-2 items-center">
                          {hasOnlyCellChanges && (
                            <Toggle
                              checked={showAllColumns}
                              onChange={() =>
                                setShowAllColumns(!showAllColumns)
                              }
                              label={t("userFunctionModal.showAllColumns")}
                              disabled={!hasDataChanges()}
                              data-cy="show-all-columns-toggle"
                            />
                          )}
                          {!onlyRemovedRows && (
                            <Toggle
                              checked={hideBefore}
                              onChange={() => setHideBefore(!hideBefore)}
                              label={t("userFunctionModal.hideBefore")}
                              disabled={!hasDataChanges()}
                              data-cy="hide-before-toggle"
                            />
                          )}
                        </div>
                      </div>

                      {hasDataChanges() && transformResult!.data.length !== 0 && (
                        <div className="!px-12 ui-preview:!px-6 !py-1 grow h-full">
                          <PreviewTable
                            beforeValues={fullData}
                            afterValues={transformResult!.data}
                            columnsChanged={transformResult!.changedColIndexes}
                            rowsChanged={transformResult!.changedRowIndexes}
                            rowsRemoved={transformResult!.removedRowIndexes}
                            hideBefore={hideBefore}
                            showAllColumns={showAllColumns}
                          />
                        </div>
                      )}

                      {showPreview && !hasDataChanges() && (
                        <div className="!px-6 !pb-8 transition-all">
                          <HelpText
                            content={t("userFunctionModal.noChanges")}
                            className="text-center flex flex-col items-center w-full !py-12"
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className="flex items-center !p-6 border-t border-gray-300 justify-end gap-2">
                    <Button
                      onClick={() => handleClose(true)}
                      theme="secondary"
                      data-cy="transform-cancel-button"
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      onClick={handleConfirmChanges}
                      disabled={!!isError || !hasDataChanges()}
                      data-cy="transform-confirm-button"
                    >
                      {t("common.confirm")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid transition-all duration-300 opacity-100 grid-rows-[1fr] ui-preview:opacity-0 ui-preview:grid-rows-[0fr] ui-error:opacity-0 ui-error:grid-rows-[0fr]">
              <div className="overflow-hidden">
                <ExamplePrompts
                  onClickPrompt={(value) => {
                    handleClickOnInputArea();
                    setUserPrompt(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
}

export default UserFunctionModal;

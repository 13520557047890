import { RootState } from "../store/reducers";
import {
  IFieldMetadata,
  IResultMetadata,
  IFieldMetadataManyToOne,
} from "../interfaces";
import { selectErrorsForExport } from "../store/selectors";
import { selectRowsWithErrors } from "../store/reducers/coredata";

export const selectHeaderMapping = (
  state: RootState
): Record<string, string> => {
  const { headers } = state.coredata;
  const { columnMapping } = state.fields;

  if (headers === null) return {};

  return [...columnMapping].reduce((headerMapping, [colIndex, { key }]) => {
    const header = headers[colIndex];
    if (!header) return headerMapping;
    headerMapping[header] = key;
    return headerMapping;
  }, {} as Record<string, string>);
};

export const selectFieldMetadata = (state: RootState): IFieldMetadata => {
  const metadata: IFieldMetadata = {};
  const { headers } = state.coredata;
  const isFileUpload = ["FILE", "INITIAL_FILE"].includes(
    state.coredata.data.uploadType
  );

  state.fields.columnMapping.forEach(({ key, matchType }, colIndex) => {
    const fileHeaderIndex =
      !isFileUpload || matchType === "VIRTUAL" ? null : colIndex;
    const fileHeader =
      headers && fileHeaderIndex !== null ? headers[colIndex] : null;

    const spec = state.fields.fieldSpecs.get(key)!;
    const isCustom = matchType === "CUSTOM";

    if (spec.manyToOne) {
      metadata[spec.key] ??= {
        fileHeader: null,
        fileHeaders: [],
        fileHeaderIndex: null,
        fileHeaderIndexes: [],
        isCustom,
        manyToOne: true,
      };
      const manyToOneMappingObj = metadata[spec.key] as IFieldMetadataManyToOne;
      manyToOneMappingObj.fileHeaders.push(fileHeader);
      manyToOneMappingObj.fileHeaderIndexes.push(fileHeaderIndex);
    } else {
      metadata[spec.key] = {
        fileHeaderIndex,
        fileHeader,
        isCustom,
        manyToOne: false,
      };
    }
  });

  return metadata;
};

export const selectResultMetadata = (
  state: RootState,
  cleanedDataUploadId: string | null
): IResultMetadata => {
  const { coredata, settings } = state;
  const errorRows = selectRowsWithErrors(coredata);
  const errorRowsArray = Array.from(errorRows).sort((a, b) => a - b);
  const errors = selectErrorsForExport(state);
  return {
    // we only have this id when backendSync is true
    id: cleanedDataUploadId,
    filename: coredata.originalFilename,
    importIdentifier: settings.importIdentifier,
    user: settings.user,
    rowsWithError: errorRowsArray,
    rawHeaders: coredata.headers,
    fields: selectFieldMetadata(state),
    errors,
  };
};

import { z } from "zod";

export const StyleOverridesSchema = z
  .object({
    global: z
      .object({
        textColor: z.string().default(""),
        primaryTextColor: z.string().default(""),
        secondaryTextColor: z.string().default(""),
        successColor: z.string().default(""),
        warningColor: z.string().default(""),
        customFontURL: z.string().nullable().default(null),
        customFontFamily: z.string().nullable().default(null),
        backgroundColor: z.string().default(""),
        borderRadius: z.string().default("8px"),
        borderWidth: z.string().default(""),
        borderColor: z.string().default(""),
        borderStyle: z.string().default(""),
      })
      .default({}),
    primaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    secondaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    tertiaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    dropzone: z
      .object({
        borderWidth: z.number().default(2),
        borderRadius: z.number().default(8),
        borderColor: z.string().default(""),
        borderStyle: z.string().default(""),
        backgroundColor: z.string().default(""),
        color: z.string().default(""),
        outline: z.string().default(""),
      })
      .default({}),
    helpText: z
      .object({
        textColor: z.string().default(""),
        backgroundColor: z.string().default(""),
      })
      .default({}),
    stepperBar: z
      .object({
        completeColor: z.string().default(""),
        incompleteColor: z.string().default(""),
        currentColor: z.string().default(""),
        fontSize: z.string().default(""),
        completeFontWeight: z.string().default(""),
        incompleteFontWeight: z.string().default(""),
        currentFontWeight: z.string().default(""),
        backgroundColor: z.string().default(""),
        borderBottom: z.string().default(""),
      })
      .default({}),
    dataTable: z
      .object({
        headerFontWeight: z.string().default(""),
      })
      .default({}),
  })
  .transform((styleOverrides) => {
    // If background color is specified and no border color is specified,
    // set border color to background color
    if (
      styleOverrides.primaryButton.backgroundColor &&
      !styleOverrides.primaryButton.border
    ) {
      styleOverrides.primaryButton.border =
        styleOverrides.primaryButton.backgroundColor;
    }
    if (
      styleOverrides.secondaryButton.backgroundColor &&
      !styleOverrides.secondaryButton.border
    ) {
      styleOverrides.secondaryButton.border =
        styleOverrides.secondaryButton.backgroundColor;
    }

    // If background color is specified and no hover background color is specified,
    // set hover background color to background color
    if (
      styleOverrides.primaryButton.backgroundColor &&
      !styleOverrides.primaryButton.hoverBackgroundColor
    ) {
      styleOverrides.primaryButton.hoverBackgroundColor =
        styleOverrides.primaryButton.backgroundColor;
    }
    if (
      styleOverrides.secondaryButton.backgroundColor &&
      !styleOverrides.secondaryButton.hoverBackgroundColor
    ) {
      styleOverrides.secondaryButton.hoverBackgroundColor =
        styleOverrides.secondaryButton.backgroundColor;
    }

    // If text color is specified and no hover text color is specified,
    // set hover text color to text color
    if (
      styleOverrides.primaryButton.textColor &&
      !styleOverrides.primaryButton.hoverTextColor
    ) {
      styleOverrides.primaryButton.hoverTextColor =
        styleOverrides.primaryButton.textColor;
    }
    if (
      styleOverrides.secondaryButton.textColor &&
      !styleOverrides.secondaryButton.hoverTextColor
    ) {
      styleOverrides.secondaryButton.hoverTextColor =
        styleOverrides.secondaryButton.textColor;
    }

    // If border color is specified and no hover border color is specified,
    // set hover border color to border color
    if (
      styleOverrides.primaryButton.border &&
      !styleOverrides.primaryButton.hoverBorder
    ) {
      styleOverrides.primaryButton.hoverBorder =
        styleOverrides.primaryButton.border;
    }
    if (
      styleOverrides.secondaryButton.border &&
      !styleOverrides.secondaryButton.hoverBorder
    ) {
      styleOverrides.secondaryButton.hoverBorder =
        styleOverrides.secondaryButton.border;
    }

    return styleOverrides;
  });

export type IDeveloperStyleOverrides = z.input<typeof StyleOverridesSchema>;
export type IDeveloperStyleOverridesStrict = z.output<
  typeof StyleOverridesSchema
>;

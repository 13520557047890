import { version as getVersion, validate } from "uuid";

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export interface UUIDFieldOpts extends BaseTypeOpts {
  version?: number;
}

export class UUIDField extends AbstractField<UUIDFieldOpts, string> {
  static defaultInvalidValueMessage = "Invalid UUID";
  type = "uuid" as const;

  readonly version?: number;

  constructor(opts: UUIDFieldOpts) {
    super(opts);
    this.version = opts.version;
  }

  transform(value: string): ITransformResult<string> {
    const isValid = validate(value);

    if (!isValid) {
      return this.transformFailure();
    }

    if (this.version !== undefined && getVersion(value) !== this.version) {
      return this.transformFailure();
    }

    return this.transformSuccess(value);
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}

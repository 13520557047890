import type { DeepPartial } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "./reducers";
import { AppDispatch } from "./configureStore";
import { IDeveloperStyleOverridesStrict } from "../interfaces";
import { removeEmptyKeys } from "../util/removeEmptyKeys";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type StyleSelector<T extends React.CSSProperties> = (
  styles: IDeveloperStyleOverridesStrict
) => T;

export const useStyles = <T extends React.CSSProperties>(
  selector: StyleSelector<T>
): DeepPartial<T> | undefined => {
  const { styleOverrides } = useAppSelector((state) => state.settings);

  const selectedStyles = selector(styleOverrides);
  const styles = removeEmptyKeys(selectedStyles);

  return styles;
};

export const useSettings = () => {
  const settings = useAppSelector((state) => state.settings);
  return settings;
};

export const useIsManualInput = () => {
  const { isManualInput } = useAppSelector((state) => ({
    isManualInput: state.coredata.data.uploadType === "MANUAL_INPUT",
  }));

  return isManualInput;
};

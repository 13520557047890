import isValidDomain from "is-valid-domain";

import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

export interface DomainFieldOpts extends BaseTypeOpts {
  allowSubdomains?: boolean;
}

export class DomainField extends AbstractField<DomainFieldOpts, string> {
  static defaultInvalidValueMessage = "Invalid domain";
  type = "domain" as const;

  readonly allowSubdomains: boolean;

  constructor(opts: DomainFieldOpts) {
    super(opts);
    this.allowSubdomains = opts.allowSubdomains ?? true;
  }

  transform(value: string): ITransformResult<string> {
    if (isValidDomain(value, { subdomain: this.allowSubdomains })) {
      return this.transformSuccess(value);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}
